import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  buildDataSourcePayload,
  client, DIRECT_LOYALTY,
  getApiUrl, getErrorType,
  NEBULA_REST
} from "../../api/client";
import {getAgentLogin, removeAgentLogin} from "../Util";
import {buildValidatePayload, processValidateRS} from "./appUtil";
import {selectSession, updatePayerProfile} from "../session/sessionSlice";
import {convertProfile} from "../session/reservationUtil";

const appSlice = createSlice({
  name: 'app',
  initialState: {
    busy: false,
    loginBusy: false,
    forgetPswBusy: false,
    loyaltyBusy: false,
    token: '',
    instance: '',
    rateCodes: {},
    singleProperty: false,
    connectionCode: "BOOKNOW",
  },
  reducers: {
    setFirebaseApp: (state, action) => {
      state.firebaseApp = action.payload.firebaseApp;
    },
    setCustomerBusy: (state, action) => {
      state.busy = true;
      state.customer = undefined;
      state.token = ''
    },
    setCustomer: (state, action) => {
      state.customer = action.payload.customer;
      state.token = action.payload.token;
      state.instance = action.payload.instance;
    },
    restoreAgentLogin: (state, action) => {
      state.agent = getAgentLogin();
    },
    logoutAgent: (state, action) => {
      state.agent = undefined;

      removeAgentLogin();
    },
    logoutLoyalty: (state, action) => {
      state.loyaltyProfile = undefined;
    },
    clearError: (state) => {
      state.errorType = undefined;
    },
    setRateCodes: (state, action) => {
      const docs = action.payload
      let rateCodes= {};

      docs.forEach(doc => {
        rateCodes[doc.id] = doc;
      })

      state.rateCodes = rateCodes;
    },
    setScriptOptions: (state, action) => {
      const {singleProperty, connectionCode} = action.payload;

      state.singleProperty = singleProperty;
      state.connectionCode = connectionCode;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSite.pending, (state, action) => {
      state.busy = true;
    }).addCase(getSite.fulfilled, (state, action) => {
      state.busy = false;

      const site = action.payload;

      state.site = {
        id: site.ID,
        name: site.Name,
        defaultNoOfAdults: site.DefaultNoOfAdults ?? 2,
        minDuration: site.MinDuration ?? 1,
        maxRooms: site.MaxRooms ?? 99,
        maxKidsAge: site.MaxKidsAge ?? 18,
        showPromo: site.ShowPromo ?? false,

        idNoMandatory: site.IdNoMandatory ?? false,
        cellNoMandatory: site.CellNoMandatory ?? false,
        addressMandatory: site.AddressMandatory ?? false,
        countryMandatory: site.CountryMandatory ?? false,
        originMandatory: site.OriginMandatory ?? false,
        defaultCountryId: site.DefaultCountryID,
        defaultTitleId: site.DefaultTitleID,

        propertyIds: (site.Properties||[]).map(property => (property.ID)),
        terms: site.TermsAndConditions,
        hostUrl: site.HostURL,
        showCountrySuggestion: site.ShowCountrySuggestion,

        hideIdNo: site.HideIDNo,
        hideOrigin: site.HideOrigin,
        showLoyaltyLogin: site.ShowLoyaltyLogin,
        showAgentLogin: site.ShowAgentLogin,
        agentLoginRequired: site.AgentRequired,
        showLoyaltyNo: site.ShowLoyaltyNo,
        contactUsUrl: site.ContactUsURL,
        homePageUrl: site.HomePageUrl,

        googleTagManagerId: site.GoogleTagManagerID,
        googleAnalyticsId: site.GoogleAnalyticsID,
        whipGoogleAnalyticsId: site.WhipGoogleAnalyticsID,
        whipGoogleAnalyticsLabel: site.WhipGoogleAnalyticsLabel,
      }
    }).addCase(getSite.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;

      // login
    }).addCase(login.pending, (state) => {
      state.loginBusy = true;
      state.errorType = undefined;
    })
    .addCase(login.fulfilled, (state, action) => {
      state.loginBusy = false;
      state.agent = convertAgent(action.payload);
    })
    .addCase(login.rejected, (state, action) => {
      state.loginBusy = false;
      state.errorType = getErrorType(action);
    })

    // change password
    .addCase(changePassword.pending, (state) => {
      state.loginBusy = true;
      state.errorType = undefined;
    })
    .addCase(changePassword.fulfilled, (state, action) => {
      state.loginBusy = false;
    })
    .addCase(changePassword.rejected, (state, action) => {
      state.loginBusy = false;
      state.errorType = getErrorType(action);
    })

    // forget password
    .addCase(queuePswForgetEmail.pending, (state) => {
      state.forgetPswBusy = true;
      state.errorType = undefined;
    })
    .addCase(queuePswForgetEmail.fulfilled, (state, action) => {
      state.forgetPswBusy = false;
    })
    .addCase(queuePswForgetEmail.rejected, (state, action) => {
      state.forgetPswBusy = false;
      state.errorType = getErrorType(action);
    })

    // loyalty login
    .addCase(loyaltyLogin.pending, (state) => {
      state.loyaltyBusy = true;
    })
    .addCase(loyaltyLogin.fulfilled, (state, action) => {
      state.loyaltyBusy = false;
      state.loyaltyProfile = action.payload;
    })
    .addCase(loyaltyLogin.rejected, (state, action) => {
      state.loyaltyBusy = false;
    })

    // default loyalty program
    .addCase(getDefaultLoyaltyProgram.fulfilled, (state, action) => {
      state.defaultLoyaltyProgram = action.payload[0];
    })
  }
});

export const selectApp = state => state.app;

export const getSite = createAsyncThunk('site/fetch',
    async ({siteId, customer, token}, thunkAPI) => {
      const response = await client.post(getApiUrl(customer, NEBULA_REST),
          buildDataSourcePayload({ID: siteId}, "Site", "fetch",
              "fetchWithSiteProperties"), token)

      return response[0];
    });

export const login = createAsyncThunk('contact/login',
    async ({username, password}, thunkAPI) => {
      const {getState} = thunkAPI;
      const {customer, token} = selectApp(getState())

      return await client.post(getApiUrl(customer, NEBULA_REST),
          buildDataSourcePayload({LoginName: username, ePassword: password},
              'Contact', 'custom',
              'login'), token);
    });

export const changePassword = createAsyncThunk('contact/changePassword',
    async ({username, oldPassword, password}, thunkAPI) => {
      const {getState} = thunkAPI;
      const {customer, token} = selectApp(getState())

      return await client.post(getApiUrl(customer, NEBULA_REST),
          buildDataSourcePayload(
              {LoginName: username, OldPassword: oldPassword, Password: password}, "Contact",
              "custom",
              "changePassword"), token)
    });

export const queuePswForgetEmail = createAsyncThunk('contact/queuePswForgetEmail',
    async ({email}, thunkAPI) => {
      const {getState} = thunkAPI;
      const {customer, token} = selectApp(getState())

      return await client.post(getApiUrl(customer, NEBULA_REST),
          buildDataSourcePayload(
              {Email: email}, "Contact",
              "custom",
              "queueForgetPasswordEmail"), token)
    });

export const convertAgent = payload => {
  const contact = payload;
  return {
    id: contact.ID,
    username: contact.LoginName,
    name: contact.Name,
    email: contact.Email,
    tel: contact.Tel,
    countryId: contact.CountryID,
    countryName: contact.CountryName,
    agentId: contact.AgentID,
    agentName: contact.AgentName,
    paymentTypeId: contact.PaymentTypeID,
  };
}

export const loyaltyLogin = createAsyncThunk('loyalty/login',
  async ({loyaltyNo, username}, thunkAPI) => {
    const {getState, dispatch} = thunkAPI;
    const {customer, token} = selectApp(getState())

    const response = await client.post(getApiUrl(customer, DIRECT_LOYALTY),
      buildValidatePayload(loyaltyNo, username), token);

    const data = processValidateRS(response);

    const loyaltyProfile = convertProfile(data);
    const payerProfile = (selectSession(getState()).reservation?.payerProfile
      || {});

    dispatch(updatePayerProfile(
      {payerProfile: Object.assign({}, payerProfile, loyaltyProfile)}))

    return loyaltyProfile;
  })

export const getDefaultLoyaltyProgram = createAsyncThunk('loyalty/defaultLoyaltyProgram',
  async ({customer, token}, thunkAPI) => {
    return await client.post(getApiUrl(customer, NEBULA_REST),
      buildDataSourcePayload(
        {}, "LoyaltyProgram",
        "fetch",
        "fetchDefault"), token)
  });

export const {
  setCustomerBusy,
  setCustomer,
  setFirebaseApp,
  restoreAgentLogin,
  logoutAgent,
  clearError,
  logoutLoyalty,
  setRateCodes,
  setScriptOptions
} = appSlice.actions;

export default appSlice.reducer;