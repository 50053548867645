import * as React from "react";
import Dialog from "../../../components/Dialog";
import useGuestLookupSearch from "../../../hooks/useGuestLookupSearch";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../../app/appSlice";
import ResponsiveTable from "../../../components/ResponsiveTable";
import TextInputItem from "../../../components/TextInputItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  parseDate
} from "../reservationUtil";
import {DATE_TIME_FORMAT} from "../../Util";

export default function GuestLookup({
  loyaltyNo = '',
  surname = '',
  agentId,
  useProfileButtonLabel = 'Use Profile',
  onProfileSelect = (profile) => {},
  open,
  onOpenChange}) {

  const {site, customer, token} = useSelector(state => ({
    site: selectApp(state).site,
    customer: selectApp(state).customer,
    token: selectApp(state).token,
  }));

  const {search, reset, profiles, busy, error} = useGuestLookupSearch();

  const [loyaltyNo_, setLoyaltyNo_] = useState('')
  const [surname_, setSurname_] = useState('')
  const [email, setEmail] = useState('')
  const [selectedProfile, setSelectedProfile] = useState(undefined)

  const search_ = useCallback((loyaltyNo, surname, email) => {
    search({
        ...(loyaltyNo && {LoyaltyNo: loyaltyNo.trim().toLowerCase()}),
        ...(surname && {Name: surname.trim().toLowerCase()}),
        ...(email && {Email: email.trim().toLowerCase()}),
      AgentID: agentId,
      Show: true,
      },
      customer,
      token)
  }, [agentId, customer, token])

  useEffect(() => {

    if (open && customer) {
      reset();
      setSurname_(surname)
      setLoyaltyNo_(loyaltyNo)
      setSelectedProfile(undefined)

      if(!(loyaltyNo.trim().length === 0 && surname.trim().length === 0)) {
        search_(loyaltyNo, surname)
      }
    }

  }, [open, loyaltyNo, surname]);

  const handleFind = useCallback( () => {
    search_(loyaltyNo_, surname_, email)
  }, [loyaltyNo_, surname_, email, customer, token])

  const columns = [
    {
      id: 'LoyaltyNo',
      label: 'Loyalty#',
    },

    {
      id: 'Name',
      label: 'Last Name',
    },

    {
      id: 'FirstName',
      label: 'First Name',
    },

    {
      id: 'Email',
      label: 'E-mail',
    },

    {
      id: 'TelNo',
      label: 'Tel No.',
    },

    {
      id: 'CellNo',
      label: 'Cell No.',
    },

    {
      id: 'DateCreated',
      label: 'Created',
      renderValue: (value) => {
        if(value) {
          return parseDate(value, DATE_TIME_FORMAT).toLocaleDateString()
        }
      }
    },

    {
      id: 'DateModified',
      label: 'Modified',
      renderValue: (value) => {
        if(value) {
          return parseDate(value, DATE_TIME_FORMAT).toLocaleDateString()
        }
      }
    }
  ];

  const onRowSelected = (profile) => {
    setSelectedProfile(profile)
  }

  const isRowSelected = (row) => {
    if(row && selectedProfile) {
      return (selectedProfile.ID === row.ID)
    }
    else {
      return false;
    }
  }

  const findDisabled =
    (loyaltyNo_.trim().length === 0 && surname_.trim().length === 0 && email.trim().length === 0);

  const handleUseProfile = (profile) => {
    onProfileSelect(profile);

    onOpenChange(false)
  }

  return (
    <Dialog portalId={'bn-guest-lookup'} open={open}
            onOpenChange={onOpenChange}>
      <>
        <div className={"bn-dialog-content"}>
          <h2>Guests</h2>
          <div className={"bn-guest-lookup-filters-container"}>
            <div className={"bn-guest-lookup-filter-items"}>
              {/*{site?.showLoyaltyNo && <TextInputItem className={"bn-group bn-input-item"}*/}
              {/*                id={"loyaltyNo"}*/}
              {/*                label={"Loyalty#"}*/}
              {/*                value={loyaltyNo_}*/}
              {/*                placeholder={"Loyalty#"}*/}
              {/*                maxLength={50}*/}
              {/*                onChange={(event) => setLoyaltyNo_(*/}
              {/*                  event.target.value)}*/}
              {/*/>}*/}

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"surname"}
                             label={"Surname"}
                             value={surname_}
                             placeholder={"Surname"}
                             maxLength={50}
                             onChange={(event) => setSurname_(
                               event.target.value)}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"email"}
                             label={"E-mail"}
                             value={email}
                             placeholder={"E-mail"}
                             maxLength={200}
                             onChange={(event) => setEmail(
                               event.target.value)}
              />
            </div>


            <button className={"bn-booknow-button"}
                    type={"button"}
                    name={"booknow-button"}
                    onClick={() => handleFind()}
                    disabled={busy || findDisabled}
            >
              <FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/>
              Find
            </button>
          </div>
          <div className={"bn-guest-lookup-table-container"}>
            <ResponsiveTable columns={columns}
                             data={profiles}
                             actions={[]}
                             emptyListMessage={"No profiles to display."}
                             busy={busy}
                             isRowSelected={isRowSelected}
                             onRowSelected={onRowSelected}
            />
          </div>
          <div className={"bn-guest-lookup-dialog-button-container"}>

            <button className={"bn-booknow-button"}
                    type={"button"}
                    name={"booknow-button"}
                    onClick={() => handleUseProfile(selectedProfile)}
                    disabled={!selectedProfile}
            >
              {useProfileButtonLabel}
            </button>

            <button className={"bn-booknow-button"}
                    type={"button"}
                    name={"booknow-button"}
                    onClick={() => onOpenChange(!open)}
            >
              Close
            </button>

          </div>
        </div>
      </>
    </Dialog>
  )
}