import * as React from "react";
import {useEffect, useRef} from "react";
import {getFirstRoomProfile} from "../reservationUtil";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {
  releaseTemphold,
  selectSession, updateRoomProfile,
  updateReservation
} from "../sessionSlice";
import {scrollToBookNow} from "../../Util";
import {useState} from "react";
import SelectLookup from "../../../components/SelectLookup";
import classNames from "classnames";
import TextInputItem from "../../../components/TextInputItem";
import Accordion from "../../../components/Accordion";
import ScrollableTabs from "../../../components/ScrollableTabs";
import {selectApp} from "../../app/appSlice";
import AdditionalNames from "./AdditionalNames";

function Profile({
  room,
  itineraryIndex,
  roomIndex,
  onAddtnlNameAddClicked,
  payerProfile
}) {

  const roomProfile =  room ? getFirstRoomProfile(room) : undefined;
  const profile = roomProfile?.profile;
  // const profile = room ? getFirstRoomProfile(room).profile : {};

  const {site} = useSelector((state) => ({
    site: selectApp(state).site
  }))

  const [titleId, setTitleId] = useState(0);
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [cellNo, setCellNo] = useState('');
  const [idNo, setIdNo] = useState('');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [countryId, setCountryId] = useState(site?.defaultCountryId);

  const dispatch = useDispatch();

  const updateProfile = React.useCallback(() => {
    const roomProfile = getFirstRoomProfile(room);
    const profileId = profile?.profileId === payerProfile.profileId ? undefined : profile?.profileId;

    const profile_ = {
      ...profile,
      firstname,
      surname,
      cellNo,
      email,
      idNo,
      address1,
      address2,
      postalCode,
      city,
      countryId,
      profileId,
    };

    dispatch(updateRoomProfile(
        {
          roomProfile: {...roomProfile, profile: profile_},
          roomIndex, itineraryIndex
        }))

  }, [
    firstname,
    surname,
    cellNo,
    email,
    idNo,

    address1,
    address2,
    postalCode,
    city,
    countryId,

    room, itineraryIndex, roomIndex, payerProfile, dispatch]);

  useEffect(() => {
    setTitleId(profile?.titleId || 1);
    setFirstname(profile?.firstname || '');
    setSurname(profile?.surname || '');
    setCellNo(profile?.cellNo || '');
    setEmail(profile?.email || '');
    setIdNo(profile?.idNo || '')

    setAddress1(profile?.address1 || '')
    setAddress2(profile?.address2 || '')
    setPostalCode(profile?.postalCode || '')
    setCity(profile?.city || '')
    setCountryId(profile?.countryId || site?.defaultCountryId);

  }, [profile]);

  // fade effect logic
  const [fadeProp, setFadeProp] = useState({
    fade: 'fade-in',
  });

  useEffect(() => {

    setFadeProp({
      fade: 'fade-out'
    })

    const timeout = setTimeout(() => {
        setFadeProp({
          fade: 'fade-in'
        })

      clearTimeout(timeout);
    }, 50);

  }, [roomIndex, itineraryIndex]);

  const handleOnBlur = () => {
    updateProfile();
  };

  return (
      <div className={classNames("bn-guest-room-profile", fadeProp.fade)}>
        <div className={classNames("bn-inputs-group")}>
          <div className={"bn-inputs-left"}>

            <SelectLookup className={"bn-group bn-select-item"}
                          id={"title-room"}
                          label={"Title"}
                          name={"Title"}
                          value={titleId}
                          placeholder={"Title"}
                          onChange={(value) => setTitleId(value.ID)}
                          defaultToFirstOption={true}
                          onBlur={handleOnBlur}
                          isClearable={false}
            />

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"name"}
                           label={"Firstname"}
                           placeholder={"Firstname"}
                           mandatory={true}
                           maxLength={50}
                           value={firstname}
                           onChange={(event) => setFirstname(
                           event.target.value)}
                           onBlur={handleOnBlur}
            />

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"surname"}
                           label={"Surname"}
                           value={surname}
                           placeholder={"Surname"}
                           mandatory={true}
                           maxLength={50}
                           onChange={(event) => setSurname(
                             event.target.value)}
                           onBlur={handleOnBlur}
            />

          </div>
          <div className={"bn-inputs-right"}>

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"mobile"}
                           label={"Mobile"}
                           placeholder={"Mobile"}
                           mandatory={site?.CellMandatory ?? false}
                           maxLength={50}
                           value={cellNo}
                           onChange={(event) => setCellNo(
                             event.target.value)}
                           onBlur={handleOnBlur}
            />

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"email"}
                           label={"E-mail"}
                           value={email}
                           placeholder={"E-mail"}
                           mandatory={true}
                           maxLength={100}
                           onChange={(event) => setEmail(
                           event.target.value)}
                           onBlur={handleOnBlur}
            />

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"idNo"}
                           label={"ID Number"}
                           placeholder={"ID Number"}
                           mandatory={site?.idNoMandatory ?? false}
                           maxLength={50}
                           value={idNo}
                           onChange={(event) => setIdNo(
                             event.target.value)}
                           onBlur={handleOnBlur}
            />

          </div>
        </div>
        <h2>Address Details</h2>
        <div className={"bn-inputs-group"}>
          <div className={"bn-inputs-left"}>

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"address"}
                           label={"Address"}
                           value={address1}
                           placeholder={"Address"}
                           mandatory={site?.addressMandatory ?? false}
                           maxLength={50}
                           onChange={(event) => setAddress1(
                           event.target.value)}
                           onBlur={handleOnBlur}
            />

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"address2"}
                           label={"Address"}
                           value={address2}
                           placeholder={"Address"}
                           mandatory={site?.addressMandatory ?? false}
                           maxLength={50}
                           onChange={(event) => setAddress2(
                             event.target.value)}
                           onBlur={handleOnBlur}
            />

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"city"}
                           label={"City"}
                           value={city}
                           placeholder={"City"}
                           mandatory={site?.addressMandatory ?? false}
                           maxLength={50}
                           onChange={(event) => setCity(
                           event.target.value)}
                           onBlur={handleOnBlur}
            />

          </div>
          <div className={"bn-inputs-right"}>

            <TextInputItem className={"bn-group bn-input-item"}
                           id={"postalCode"}
                           label={"Postal Code"}
                           value={postalCode}
                           placeholder={"Postal Code"}
                           mandatory={site?.addressMandatory ?? false}
                           maxLength={50}
                           onChange={(event) => setPostalCode(
                           event.target.value)}
                           onBlur={handleOnBlur}
            />

            <SelectLookup className={"bn-group bn-select-item"}
                          name={"Country"}
                          id={"country-room"}
                          label={"Country"}
                          value={countryId}
                          placeholder={"Country"}
                          onChange={(value)=> setCountryId(value.ID)}
                          defaultToFirstOption={true}
                          onBlur={handleOnBlur}
                          isClearable={false}
            />

          </div>
        </div>

        <div className={'bn-guest-additional-names-container'}>
          <AdditionalNames
            roomProfile={roomProfile}
            itineraryIndex={itineraryIndex}
            roomIndex={roomIndex}
            onAddClicked={onAddtnlNameAddClicked}
          />
        </div>
      </div>
  )
}

const RoomProfiles = React.memo(
  ({itineraryIndex, payerProfile, roomBreakdown, onAddtnlNameAddClicked}) => {

    const [roomIndex, setRoomIndex] = useState(0);

  const currentRoom = roomIndex >= roomBreakdown.length
      ? roomBreakdown[roomBreakdown.length - 1] : roomBreakdown[roomIndex];

  useEffect(() => {
    if (roomIndex >= roomBreakdown.length) {
      setRoomIndex(roomBreakdown.length - 1);
    }
  }, [currentRoom]);

  const tabs = roomBreakdown.map((room, idx) => ({
    id: idx,
    label: `Room# ${(idx+1)}`
  }))

  return (
      <>
        <ScrollableTabs selectedIndex={roomIndex} tabs={tabs} onTabSelected={setRoomIndex}  />
        <Profile room={currentRoom}
                 itineraryIndex={itineraryIndex}
                 roomIndex={roomIndex}
                 onAddtnlNameAddClicked={onAddtnlNameAddClicked}
                 payerProfile={payerProfile}
        />
      </>
  )
});

function ReservationProfiles({itinerary, setItineraryIndex, itemIndex}) {
  return (
      <div className={"bn-guest-itinery-tabs"}>
        {
          (itinerary||[]).map((item, itIndex) => (
              <div key={itIndex}
                   className={classNames(
                       'bn-tab',
                       {'bn-selected': itemIndex === itIndex})}
                   onClick={() => setItineraryIndex(
                       itIndex)}>{item.propertyName} </div>
          ))
        }
      </div>
  )
}

export default React.memo(function () {

  const accordionRef = useRef(undefined);

  const {reservation} = useSelector(
      state => ({
        reservation: selectSession(state).reservation,
      }));

  const [itemIndex, setItemIndex] = useState(0);

  const dispatch = useDispatch();
  const updateReservation_ = useCallback((reservation) =>
      dispatch(updateReservation({reservation})), [dispatch]);
  const releaseTemphold_ = useCallback(
      (params) => dispatch(releaseTemphold(params)), [dispatch]);

  useEffect(() => {
    scrollToBookNow();
  }, []);

  const itinerary = reservation?.itinerary;
  const roomBreakdown = itinerary ? itinerary[itemIndex]?.roomBreakdown : undefined;

  const handleAddtnlNameAddClicked = () => {
    if(accordionRef) {
      accordionRef.current.resize()
    }
  }

  return (
      <>
        <Accordion ref={accordionRef} title={'Room Profiles (optional)'}>
          <div className={'bn-room-profile-tab-container'}>
            <ReservationProfiles itinerary={itinerary}
                                 itemIndex={itemIndex}
                                 setItineraryIndex={setItemIndex}/>
            {roomBreakdown && <RoomProfiles
              payerProfile={reservation.payerProfile}
              itineraryIndex={itemIndex}
              roomBreakdown={roomBreakdown}
              onAddtnlNameAddClicked={handleAddtnlNameAddClicked}
            />}
          </div>
        </Accordion>
      </>
  )
});