import {useState} from "react";
import {
  client,
  DIRECT_LOYALTY,
  getApiUrl
} from "../api/client";
import {buildValidatePayload} from "../booknow/app/appUtil";

const useLoyaltyValidate = () => {
  const [state, setState] = useState({
    busy: false,
    error: undefined,
  })

  const validateLoyaltyNo = async (loyaltyNo, surname, customer, token) => {
    try {

      setState({
        busy: true,
        profile: undefined,
      })

      const response = await client.post(
        getApiUrl(customer, DIRECT_LOYALTY),
        buildValidatePayload(loyaltyNo, surname), token);

      setState({
        busy: false,
        profile: response.profile,
      })

      return response.profile;

    } catch (error) {
      setState({
        profile: undefined,
        busy: false,
        error
      })

      console.log('Loyalty validate error: ', error)
    }
  }

  return {validateLoyaltyNo, ...state};
}

export default useLoyaltyValidate;