import {useState} from "react";
import {
  buildDataSourcePayload,
  client,
  getApiUrl,
  NEBULA_REST
} from "../api/client";
import {
  buildGetPayNowRQ
} from "../booknow/session/reservationUtil";

const usePayNowUrl = () => {
  const [state, setState] = useState({
    busy: false,
    error: '',
  })

  const getPayNowUrl = async (reservation, amount, currencyId, redirectUrl, customer, token) => {

    setState({
      busy: true,
      error: ''
    })

    const request = buildDataSourcePayload(
      buildGetPayNowRQ(reservation, amount, currencyId, redirectUrl),
      'AvailableRates', 'custom', 'getPayNowUrl')

    const response =  await client.post(getApiUrl(customer, NEBULA_REST), request, token)

    setState({
      busy: false,
      error: ''
    })

    return response[0].PayNowUrl;
  }

  return {
    getPayNowUrl, ...state
  }

}

export default usePayNowUrl;