import React, {useState} from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal, useTransitionStyles
} from "@floating-ui/react";

export default function ToolTip({content, children, placement = "top", enabled = true, ...otherProps}) {

  const [open, setOpen] = useState(false);

  const {x, y, strategy, refs, context} = useFloating({
    placement,
    open: open,
    onOpenChange: setOpen,
    middleware: [offset(10), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const {isMounted, styles} = useTransitionStyles(context, {
    duration: {
      open: 200,
      close: 100,
    },
  });

  const hover = useHover(context, {move: false, enabled: enabled});
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, {role: 'tooltip'});

  const {getReferenceProps, getFloatingProps} = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);

  const ref = refs.setReference;

  return (
      <>
        {React.cloneElement(children, {
          ref,
          ...otherProps,
          ...children?.props,
          ...getReferenceProps()
        })}
        <FloatingPortal>
          {open && (
              <div
                  className={"tooltip"}
                  ref={refs.setFloating}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    width: 'max-content',
                    ...styles,
                  }}
                  {...getFloatingProps()}
              >
                {content}
              </div>
          )}
        </FloatingPortal>
      </>
  )
}