import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextInputItem from "../../../components/TextInputItem";
import SelectLookup from "../../../components/SelectLookup";
import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import ToolTip from "../../../components/ToolTip";
import {useDispatch} from "react-redux";
import {updateRoomProfile} from "../sessionSlice";

export default function AdditionalNames({roomProfile, itineraryIndex, roomIndex, onAddClicked}) {

  const additionalProfiles = roomProfile?.additionalProfiles;

  const [state, setState] = useState([])

  useEffect(() => {
    setState(additionalProfiles?.map(profile => ({...profile})) ?? [])
  }, [additionalProfiles]);

  const dispatch = useDispatch();
  const updateProfile = useCallback((additionalProfiles) =>
    dispatch(updateRoomProfile(
    {
      roomProfile: {...roomProfile, additionalProfiles: additionalProfiles},
      roomIndex, itineraryIndex
    }
    )), [roomProfile, itineraryIndex, roomIndex, dispatch]);


  const handleOnChange = (index, value, field) => {
    setState(state.map((profile, idx) => {
      if (idx === index) {
        return {...profile, [field]: value}
      } else {
        return profile;
      }
    }))
  }

  const handleOnBlur = () => {
    updateProfile(state)
  }

  const handleAdd = () => {
    onAddClicked()

    let copy = state.slice();
    copy.push({
      titleId: 1,
    })

    setState(copy)
  }

  const handleRemove = (state, index) => {
    let copy = state.slice();
    setState(state.toSpliced(index, 1))
  }

  return (
    <div className={'bn-guest-additional-names'}>
      <h3>Additional Names</h3>
      {state && state.map((additionalProfile, idx) => (
        <div key={idx} className={'bn-additional-name-group'}>
          <div className={'bn-additional-name-input'}>
            <SelectLookup className={"bn-group bn-select-item"}
                          id={"title-"+idx}
                          label={"Title"}
                          name={"Title"}
                          value={additionalProfile.titleId}
                          placeholder={"Title"}
              // onChange={(value) => setTitleId(value.ID)}
                          defaultToFirstOption={true}
                          onBlur={handleOnBlur}
                          isClearable={false}
            />
          </div>
          <div className={'bn-additional-name-input'}>
            <TextInputItem className={"bn-group bn-input-item"}
                           id={"name"}
                           label={"Firstname"}
                           placeholder={"Firstname"}
                           mandatory={true}
                           maxLength={50}
                           value={additionalProfile.firstname}
                           onChange={(event) => handleOnChange(idx,
                             event.target.value, 'firstname')}
                           onBlur={handleOnBlur}
            />
          </div>
          <div className={'bn-additional-name-input'}>
            <TextInputItem className={"bn-group bn-input-item"}
                           id={"surname"}
                           label={"Surname"}
                           value={additionalProfile.surname}
                           placeholder={"Surname"}
                           mandatory={true}
                           maxLength={50}
                           onChange={(event) => handleOnChange(idx,
                             event.target.value, 'surname')}
                           onBlur={handleOnBlur}
            />
          </div>
          <div className={'bn-additional-name-action'}>
            <ToolTip content={'Remove'}>
              <button
                className={'bn-booknow-button'}
                onClick={() => handleRemove(state, idx)}><FontAwesomeIcon
                icon="fa-solid fa-minus"/></button>
            </ToolTip>
          </div>
        </div>
      ))}
      <div className={'bn-additional-name-action'}>
        <ToolTip content={'Add'}>
          <button
            className={'bn-booknow-button'}
            disabled={state.length === 3} onClick={handleAdd}>
            <FontAwesomeIcon icon="fa-solid fa-plus"/></button>
        </ToolTip>
      </div>
    </div>
  )
}