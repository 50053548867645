import React from "react";
import Checkbox from "../../../components/Checkbox";
import ToolTip from "../../../components/ToolTip";

export default React.memo(({loyaltyProfile, checked, onChange, disabled}) => {

  const {loyaltyProgram} = loyaltyProfile;

  return (
    <ToolTip content={'Not enough pints to redeeem.'} enabled={disabled}>
      <Checkbox id={'redeem'}
              className={'bn-checkbox-item redeem'}
              value={checked}
              onChange={onChange}
              disabled={disabled}
              label={`Pay with your ${loyaltyProgram}`}/></ToolTip>
  )

})