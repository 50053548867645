import {
  calcGrandTotal,
  calcTotals,
  getItinerary
} from "../booknow/session/reservationUtil";
import axios from "axios";


function trackConversion_(site, reservation, grandTotal, taxesTotal, currencyCode, items) {

  const {whipGoogleAnalyticsLabel} = site;

  window.gtag('event', 'purchase', {
    'send_to': ['AW-'+whipGoogleAnalyticsLabel, 'HA-75'],
    'transaction_id': reservation.reservationNo,
    'value': grandTotal,
    'currency': currencyCode,
    'items': items
  });

}

function getItems(reservation, site) {
  return getItinerary(reservation).map((item, index) => ({
    id: item.property.wihpId,
    start_date: item.startDate,
    endDate: item.endDate,
  }));
}

const wihpTrackingScript = (reservation) => {
  const itinerary = getItinerary(reservation)
  try {

    for (const item of itinerary) {

      let url = `https://secure-hotel-tracker.com/tics/log.php?act=conversion&ref=${reservation.reservationNo}`
        + `&amount=${item.amountExclTax}`
        + `&currency=${item.currencyCode}`
        + `&idbe=3`
        + `&idwihp=${item.property.wihpId}`
        + `&checkin=${item.startDate}`
        + `&checkout=${item.endDate}`
        + `&date_format=YYYY-MM-DD`

      axios.get(url)
      .catch(reason => {
        console.log('Could not execute WIHP tracking: ', reason)
      })
    }

  } catch (error) {
    console.log('Could not execute WIHP tracking: ', error)
  }
}

export const trackWHIPConversion = (reservation, site) => {
  if(reservation && site && window.gtag) {
    try {

      if(site.whipGoogleAnalyticsId && site.whipGoogleAnalyticsLabel) {

        const totals = calcTotals(reservation);

        const item = getItinerary(reservation).find(
          item => item.currencyCode !== undefined);

        const grandTotal = calcGrandTotal(totals, item.currencyCode);

        const items = getItems(reservation, site);

        trackConversion_(site, reservation, grandTotal, item.currencyCode, items)

        wihpTrackingScript(reservation)
      }

    } catch (error) {
      console.log('Could not track conversion: ', error)
    }
  }
}