import React, {useCallback, useEffect, useState} from 'react';
import Dialog from "../../../components/Dialog";
import {
  addDays,
  addMonths,
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  parse
} from "date-fns";
import {DayPicker} from "react-day-picker";
import {getDensityQuery} from "../../../api/firebaseApi";
import {
  formatDate,
  formatDate as formatDate_,
  getInstanceName,
  isDev
} from "../../Util";
import useFirestoreGetDocs from "../../../hooks/useFirestoreGetDocs";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../../app/appSlice";
import _ from "lodash";
import {parseDate} from "../reservationUtil";
import {doBook, selectSession} from "../sessionSlice";
import {getRoomRateItemFromItem, trackEvent} from "../../../api/googleTag";
import ToolTip from "../../../components/ToolTip";

const startMonth = new Date();
const ARRIVAL_DATE = 0;
const DEPART_DATE = 1;

export function ItineraryDateRangePicker({item, isCriteriaItem}) {

  const {reservation, bookBusy, customer} = useSelector(state => ({
    reservation: selectSession(state).reservation,
    bookBusy: selectSession(state).bookBusy,
    customer: selectApp(state).customer,
  }));

  const dispatch = useDispatch();
  const book = useCallback((params) => dispatch(doBook(params)),
    [dispatch]);

  const {fetchData: fetchDensity, data, busy} = useFirestoreGetDocs();

  const [open, setOpen] = useState(false)
  const [range, setRange] = useState(undefined);
  const [arrivalDate, setArrivalDate] = useState(undefined)
  const [departDate, setDepartDate] = useState(undefined)
  const [nextClickType, setNextClickType] = useState(ARRIVAL_DATE)
  const [month, setMonth] = useState(startMonth);
  const [disabledDays, setDisabledDays] = useState([{before: new Date()}])
  const [notAvailableDays, setNotAvailableDays] = useState([])
  const [queryTimer, setQueryTimer] = useState(0)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    if (open) {
      const startDate = parseDate(item.startDate)
      const endDate = parseDate(item.endDate)

      setRange(
        {from: new Date(startDate), to: new Date(endDate)});

      setArrivalDate(startDate);
      setDepartDate(endDate)

      handleOnMonthChange(month, customer, item.propertyId, item.inventoryId)
    }
  }, [open, item]);

  const handleOnSelect = React.useCallback((range) => {

    if (nextClickType === ARRIVAL_DATE) {
      setNextClickType(DEPART_DATE)

      if (range?.from && isBefore(new Date(range.from.toDateString()),
        new Date(arrivalDate.toDateString()))) {

        setArrivalDate(new Date(range.from.toDateString()));

        setRange({
          from: range.from,
          to: undefined,
        })

      } else {
        if (range?.to) {
          setArrivalDate(new Date(range.to.toDateString()));

          setRange({
            from: range.to,
            to: undefined,
          })
        }
      }
    } else {
      setNextClickType(ARRIVAL_DATE)
      if (range?.to) {
        setDepartDate(new Date(range.to.toDateString()));
        setArrivalDate(new Date(range.from.toDateString()));

        setRange({
          from: range.from,
          to: range.to
        })
      }
    }

  }, [setRange,
    setArrivalDate,
    setDepartDate,
    arrivalDate,
    nextClickType,
    setNextClickType]);

  const handleOnDayClick = useCallback((dayClicked) => {

    if (notAvailableDays && notAvailableDays.length > 0) {
      let dayBefore = notAvailableDays.toReversed().find(
        notAvailableDay => isBefore(notAvailableDay, dayClicked));
      const dayAfter = notAvailableDays.find(
        notAvailableDay => isAfter(notAvailableDay, dayClicked));

      if (dayBefore) {
        dayBefore = addDays(dayBefore, 1);
      }

      setDisabledDays(
        [{before: dayBefore ?? new Date()}, {after: dayAfter}])

      if (nextClickType === DEPART_DATE) {
        setDisabledDays([{before: new Date()}, ...notAvailableDays]);
      }
    }
  }, [notAvailableDays, nextClickType])

  const Footer = ({arrivalDate, departDate}) => {

    if (arrivalDate) {

      const duration = Math.abs(
        differenceInCalendarDays(arrivalDate, departDate))

      return (
        <div className={"bn-date-picker-footer"}>
          <h1>{`${duration} ${duration > 1 ? 'Nights' : 'Night'}`}</h1>
          <p>
            {format(arrivalDate, 'PPP')} – {format(departDate, 'PPP')}
          </p>
        </div>
      )
    }
  }

  const handleOnMonthChange = (month, customer, propertyId, inventoryId) => {
    clearTimeout(queryTimer);

    const timeout = setTimeout(() => {

      fetchDensity(
        getDensityQuery(isDev() ? 'demo' : getInstanceName(customer.api_url),
          propertyId,
          inventoryId,
          formatDate_(month, 'yyyy-MM-dd'),
          formatDate_(addMonths(month, 2), 'yyyy-MM-dd')),
        (data) => {
          const notAvailableDays = calcNotAvailableDays(data);
          setNotAvailableDays(notAvailableDays);
          setDisabledDays([{before: new Date()}, ...notAvailableDays])
        })

    }, 500);

    setQueryTimer(timeout)

    setMonth(month)
  }

  const calcNotAvailableDays = (data) => {
    const groupBy = _.groupBy(data, 'date');
    const sum = Object.keys(groupBy).map(date => {
      return {
        [date]: _.sumBy(groupBy[date], 'roomsRemaining')
      }
    })
    const filter = sum.filter(s => (s[Object.keys(s)[0]] < 1));
    return filter.map(f => (parseDate(Object.keys(f)[0])));
  }

  const handleUpdate = (reservation, open) => {
    setError(undefined);

    trackEvent("edit_cart", "clicked", "edit_itinerary",
      {items: [getRoomRateItemFromItem(item, item.amountInclTax)]});

    book({
      reservation, reserve: {
        ...item,
        startDate: formatDate(arrivalDate, 'yyyy-MM-dd'),
        endDate: formatDate(departDate, 'yyyy-MM-dd')
      }
    }).unwrap().then((r) => {
      setOpen(!open)
    }).catch(error => {
      setError("Selected dates are not available.")
      console.log('update stay dates error', error)
    })
  }

  const canSave = isAfter(departDate, arrivalDate);

  return (
    <div>
      <div className={"bn-itinerary-dates-container"}>
        <div className={"bn-itinerary-checkin-checkout-container"}>
          <div className={"startdate-container"}>
            <h4>Check-In</h4>
            <span>{item?.startDate ? parse(item.startDate, 'yyyy-MM-dd',
              new Date()).toLocaleDateString() : ''}</span>
          </div>
          <div className={"enddate-container"}>
            <h4>Check-Out</h4>
            <span>{item?.endDate ? parse(item.endDate, 'yyyy-MM-dd',
              new Date()).toLocaleDateString() : ''}</span>
          </div>
        </div>

        {!isCriteriaItem &&
          <ToolTip content={'Edit your stay dates'}>
            <button onClick={() => setOpen(!open)}
                    className={"bn-booknow-button bn-secondary-button"}>
              Edit
            </button>
          </ToolTip>
        }
      </div>

      <Dialog portalId={"bn-itinerary-datepicker-dialog"} open={open}
              onOpenChange={setOpen}>
        <>
          <div className={"bn-dialog-content"}>
            <div className={"bn-dialog-text"}>
              <p>Changing your stay might affect your rate.</p>
            </div>

            <div className={'bn-date-picker-months-scroll'}>
              <DayPicker
                mode="range"
                numberOfMonths={2}
                selected={range}
                onSelect={handleOnSelect}
                onDayClick={handleOnDayClick}
                fromMonth={startMonth}
                disabled={disabledDays}
                onMonthChange={(month) => handleOnMonthChange(month, customer,
                  item.propertyId, item.inventoryId)}
              />
            </div>

            {/*<div className={'bn-date-picker-footer'}>{footer}</div>*/}
            <Footer arrivalDate={arrivalDate} departDate={departDate}/>

            {error && <div className={"bn-error-message"}>{error}</div>}

            <div className={"bn-dialog-button-container"}>
              <button className={"bn-booknow-button"}
                      type={"button"}
                      name={"booknow-update-button"}
                      onClick={() => handleUpdate(reservation, open)}
                      disabled={busy || bookBusy || !canSave}
              >
                Save Changes
              </button>
              <button className={"bn-booknow-button bn-secondary-button"}
                      type={"button"}
                      name={"booknow-close-button"}
                      onClick={() => setOpen(!open)}
                      disabled={bookBusy}
              >
                Close
              </button>
            </div>
          </div>
        </>
      </Dialog>
    </div>
  )
}

