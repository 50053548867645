import React, {useCallback, useEffect, useState} from "react";
import Dialog from "../../../components/Dialog";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextInputItem from "../../../components/TextInputItem";
import {useDispatch, useSelector} from "react-redux";
import {loyaltyLogin, selectApp} from "../appSlice";
import {getResults, selectResults} from "../../results/resultsSlice";
import {Spinner} from "../../../components/Spinner";
import {trackEvent} from "../../../api/googleTag";

export default React.memo(({open, onOpenChange}) => {

  const {loyaltyBusy, criteria} = useSelector(
    state => ({
      loyaltyBusy: selectApp(state).loyaltyBusy,
      error: selectApp(state).error,
      criteria: selectResults(state).criteria,
    }));

  const dispatch = useDispatch();
  const login = React.useCallback(
    (loyaltyNo, username) => dispatch(loyaltyLogin({loyaltyNo, username})),
    [dispatch]);
  const getResults_ = useCallback((criteria) => dispatch(getResults(criteria)),
    [dispatch]);

  const [loyaltyNo, setLoyaltyNo] = useState('');
  const [surname, setSurname] = useState('');
  const [error, setError] = useState(undefined);

  const handleLogin = () => {
    setError(undefined);

    trackEvent("engagement", "clicked", "loyalty_login");

    login(loyaltyNo, surname).unwrap().then(loyaltyProfile => {
      onOpenChange(false);

      const {loyaltyNo, loyaltyTierId} = (loyaltyProfile||{});

      if (loyaltyProfile) {
        getResults_(Object.assign({}, criteria,
          {loyaltyNo, loyaltyTierId: loyaltyTierId}));
      }

    }).catch(error => {
      setError(error.message)
      console.log('error', error)
    })
  }
  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  useEffect(() => {
    setLoyaltyNo('');
    setSurname('')

  }, [open]);

  const handleOnOpenChange = (open) => {
    setError(undefined);
    onOpenChange(open);
  }

  const disabled = !loyaltyNo || !surname;

  return (
    <Dialog open={open} onOpenChange={handleOnOpenChange}>
      <>
        <div className={"bn-dialog-content"}>
          <div className={"bn-login-container"}>
            <h1>Loyalty Login</h1>
            <div className={"login-input-container"}>
              <div className={classNames("bn-error-message",
                {
                  ['bn-show']: error,
                  ['bn-hidden']: !error,
                })}>
                <FontAwesomeIcon icon="fa-solid fa-circle-exclamation"/>
                {error}
              </div>
            </div>
            <div className={classNames("bn-inputs-group")}>
              <div
                className={"bn-inputs-left"}>
                <TextInputItem className={"bn-group bn-input-item"}
                               type={"text"}
                               id={"loyaltyNo"}
                               label={"Loyalty Number"}
                               placeholder={"Loyalty Number"}
                               mandatory={true}
                               maxLength={50}
                               value={loyaltyNo}
                               onChange={(event) => setLoyaltyNo(
                             event.target.value)}
                />
                <TextInputItem className={"bn-group bn-input-item"}
                               type={"text"}
                               id={"surname"}
                               label={'Surname'}
                               placeholder={'Surname'}
                               mandatory={true}
                               maxLength={50}
                               value={surname}
                               onChange={(event) => setSurname(
                             event.target.value)}
                               onKeyPress={event => onKeyPress(event)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"bn-dialog-button-container"}>
          {!loyaltyBusy && <button
            className={'bn-booknow-button'}
            onClick={() => handleLogin()}
            disabled={disabled}>Login</button>}
          {loyaltyBusy && <Spinner size={'45px'}/>}
        </div>
      </>
    </Dialog>)
})