import React, {useState} from "react";
import FindReservationDialog from "./FindReservationDialog";

export default React.memo(() => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <div className={"bn-login-button"}
           onClick={() => setOpen(!open)}>
        Find Reservation
      </div>

      <FindReservationDialog open={open} onOpenChange={setOpen} />
    </>
  )
})