import React, {useCallback, useState} from "react";
import Menu from "../../../components/Menu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {logoutLoyalty, selectApp} from "../appSlice";
import {getResults, selectResults} from "../../results/resultsSlice";
import LoyaltyLogin from "./LoyaltyLogin";
import {updatePayerProfile} from "../../session/sessionSlice";
import {copyObjectExcept} from "../../Util";

export default React.memo(({open, onOpenChange}) => {

  const {busy, loyaltyProfile, criteria} = useSelector(state => ({
    busy: selectApp(state).busy,
    loyaltyProfile: selectApp(state).loyaltyProfile,
    criteria: selectResults(state).criteria
  }));

  const dispatch = useDispatch();
  const logout = React.useCallback(() => dispatch(logoutLoyalty()), [dispatch])
  const updatePayerProfile_ = React.useCallback((payerProfile) => dispatch(updatePayerProfile({payerProfile})), [dispatch]);
  const getResults_ = useCallback((criteria) => dispatch(getResults(criteria)),
    [dispatch]);

  const [openLogin, setOpenLogin] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);

  const handleLogOut = () => {
    logout();
    updatePayerProfile_(undefined)
    getResults_(copyObjectExcept(criteria, ['loyaltyNo', 'loyaltyTierId']))
    setOpenLogout(false)
  }

  const {pointsAvailable, loyaltyProgram} = (loyaltyProfile||{});

  return (
    <>
      {!loyaltyProfile && <div className={"bn-login-button"}
                               onClick={() => setOpenLogin(!openLogin)}>Loyalty
        Login
      </div>}
      {loyaltyProfile &&
        <Menu open={openLogout}
              onOpenChange={setOpenLogout}
              anchorElement={
                <div className={"bn-login-name"}
                     onClick={() => setOpenLogout(!openLogout)}>
                  <FontAwesomeIcon icon="fa-solid fa-user"/>
                  {loyaltyProfile.name}
                  <div>
                    {`- ${loyaltyProgram}: ${pointsAvailable ?? '0'}`}
                  </div>
                </div>}>
          <div className={"bn-menu-item"} onClick={handleLogOut}>Log out</div>
        </Menu>
      }
      <LoyaltyLogin open={openLogin} onOpenChange={setOpenLogin}/>
    </>
  )
})