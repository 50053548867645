import React, {useCallback, useMemo, useState} from 'react';
import ImageSlider from "../../../components/ImageSlider";
import {useNavigate} from "react-router-dom";
import {
  formatAmountTax,
  getContactUsUrl,
  getPath
} from "../../Util";
import {getBestRate, hasContractedRates} from "../resultsUtil";
import {useDispatch, useSelector} from "react-redux";
import {selectResults, setImgUrls} from "../resultsSlice";
import {selectApp} from "../../app/appSlice";
import classNames from "classnames";
import {getPropertyDocId} from "../../../api/firebaseApi";
import {getHotelItem, trackEvent} from "../../../api/googleTag";

const getBestRateFormated = (property) => {
  const bestRate = getBestRate(property);

  if(bestRate) {
    return formatAmountTax(bestRate.bar, bestRate.currency.code,
      property.ratesIncludeTaxes);
  }
};

export function HotelResult({property, index}) {

  const navigate = useNavigate();

  const {site, cached, instance} = useSelector(state => ({
    site: selectApp(state).site,
    cached: selectResults(state).imgUrls[`${property.id}`],
    instance: selectApp(state).instance,
  }));

  const dispatch = useDispatch();
  const setImgUrls_ = useCallback((imgUrls) => dispatch(setImgUrls(imgUrls)),
    [dispatch]);

  const handleOnClick = (event) => {
    trackEvent("view_item", "clicked", "view_hotel",
      {'items' : [getHotelItem(property, index)]});

    navigate(`/hotel/${property.id}`)
  }

  const contracted = hasContractedRates(property)
  const bestRateFormated = getBestRateFormated(property);

  return (
    <div className={classNames( "bn-hotel", {"bn-hotel-contracted" : contracted})} onClick={event => handleOnClick(event)}>
      {contracted && <div className={"bn-contracted-container"}>Negotiated Rates</div>}
      <ImageSlider path={getPath(instance, property.id, undefined)}
                   cachedUrls={cached?.urls} setImgUrls={setImgUrls_} docId={getPropertyDocId(
        {instance, propertyId: property.id, languageId: 1})}/>
      <div className={"bn-hotel-summary"}>
        <h3 className={"bn-hotel-name"}>{property.name}</h3>
        <div className={"bn-hotel-area"}>
          <label>{property.area?.name}, {property.location?.name}</label>
        </div>
        {bestRateFormated &&
          <div className={"bn-hotel-rate"}>
            <div>
              Total stay from <strong>{bestRateFormated}</strong>
            </div>
            <button className={"bn-booknow-button"}>BOOK</button>
          </div>
        }
        {!bestRateFormated &&
          <div className={"bn-hotel-not-available"}>
            <strong>Not Available</strong> - please review dates or <a onClick={(e) => {
              e.stopPropagation()
            }} href={getContactUsUrl(site, property)}
               target={"_blank"}>Contact Us</a>
          </div>
        }
      </div>
    </div>)
}