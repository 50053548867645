import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {
  buildDataSourcePayload,
  client, getApiUrl,
  NEBULA_REST,
} from "../../api/client";

const propertySuggestionSlice = createSlice({
  name: 'propertySuggestions',
  initialState: {
    suggestions: [],
    busy: false,
    suggestionsDone: false,
  },
  reducers: {
    // setSiteId : (state, action) => {
    //   const {siteId} = action.payload;
    //   state.siteId = siteId;
    // }
  },
  extraReducers(builder) {
    builder.addCase(getPropertySuggestions.pending, (state, action) => {
      state.busy = true;
    })
    .addCase(getPropertySuggestions.fulfilled, (state, action) => {
      state.busy = false;
      state.suggestions = action.payload;
      state.suggestionsDone = true;
    })
    .addCase(getPropertySuggestions.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
      state.suggestionsDone = true;
    })
  }
});

export const getPropertySuggestions = createAsyncThunk('propertySuggestions/fetch', async (params, thunkAPI) => {

  const {dispatch} = thunkAPI;
  const {siteId, customer, token} = params;

  return await client.post(getApiUrl(customer, NEBULA_REST),
      buildDataSourcePayload({SiteID: siteId}, "SiteProperty", "fetch",
          "fetchPropertyFilters"), token)
});

export const selectPropertySuggestions = state => state.propertySuggestions;

// export const {setSiteId} = propertySuggestionSlice.actions;

export default propertySuggestionSlice.reducer