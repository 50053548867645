import React, {forwardRef} from "react";
import classNames from "classnames";

export default forwardRef( function CheckBox({id,value, label, onChange, onBlur, className, disabled, validate}, ref) {

  const error = (validate && validate(value))

  const handleChange = (event) => {
    if(!disabled) {
      onChange(event)
    }
    else {
      event.preventDefault()
    }
  }

  return (
    <div className={classNames(className, {'disabled': disabled})}>
      <label ref={ref} htmlFor={id}>
        <input id={id}
               type={"checkbox"}
               value={value}
               onChange={handleChange}
               onBlur={onBlur}
               checked={value}
        />
        {label}
        <span className={classNames('bn-checkmark', {
          'bn-error' : error,
          'disabled': disabled
        })}></span>
      </label>
    </div>
  )

})