import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import useImageUrls from "../hooks/useImageUrls";

export default function ImageSlider({path, cachedUrls, setImgUrls, docId}) {
  const [currentIndex, setCurrenctIndex] = useState(0);
  const [hover, setHover] = useState(false);

  const {data: imgUrls, busy, error} = useImageUrls(path, cachedUrls,
    setImgUrls, docId);

  const goToPrevious = (event) => {
    event.stopPropagation();
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? 0 : currentIndex - 1;
    setCurrenctIndex(newIndex);
  };

  const goToNext = (event) => {
    event.stopPropagation()
    const isLastSlide = currentIndex === imgUrls.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrenctIndex(newIndex);
  };

  const goToSlide = (index, event) => {
    event.stopPropagation()
    setCurrenctIndex(index)
  };

  const url = React.useMemo(() => {
    if (imgUrls.length) {
      // return URL.createObjectURL(imgUrls[currentIndex])
      return imgUrls[currentIndex];
    }
    return '';
  }, [currentIndex, imgUrls])

  return (
    <div className={'bn-image-slider'}
         onMouseEnter={() => setHover(true)}
         onMouseLeave={() => setHover(false)}
    >
      {currentIndex !== 0 && <div
        className={classNames('bn-arrow', 'bn-left-arrow', {
          'hover': hover
        })}
      >
        <FontAwesomeIcon onClick={event => goToPrevious(event)}
                         icon="fa-solid fa-angle-left"/></div>}
      <div className={classNames('bn-arrow', 'bn-right-arrow', {
        'hover': hover
      })}
      >
        <FontAwesomeIcon onClick={event => goToNext(event)}
                         icon="fa-solid fa-angle-right"/></div>
      {!busy && imgUrls.length > 0 && <div className={classNames('bn-image-slide')}
                                           style={{backgroundImage: `url(${url})`}}/>}
      {(busy || imgUrls.length === 0) && <div className={'bn-image-slide'}
                                              style={{backgroundColor: '#c0c0c052'}}></div>}

      <div className={'bn-dot-container'}>
        {imgUrls.map((slide, idx) => {
          return (<div key={idx} className={classNames('bn-dot', {
            'bn-selected': idx === currentIndex
          })} onClick={(event) => goToSlide(idx, event)}>●</div>)
        })}
      </div>
    </div>
  )
}