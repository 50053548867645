import * as React from "react";
import Itinerary, {ItineraryActions} from "./Itinerary";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import {
  calcAmountPayable,
  calcDepositSummary, calcNextAmountPayable, calcTotals,
  convertReservation, convertRestProfile, firstProperty,
  hasDepositDue,
  isOnAccount,
  isPayableToday,
  isPayNow, LOYALTY_ACTION_REDEEM,
  removeReservation
} from "../reservationUtil";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {
  doCommit,
  releaseTemphold,
  selectSession, updatePayerProfile, updateRedeem,
  updateReservation
} from "../sessionSlice";
import {
  formatAmount,
  getHostUrl, getHtiHostedImageURL, getInnerHTML,
  scrollToBookNow,
  validEmail
} from "../../Util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SelectLookup from "../../../components/SelectLookup";
import classNames from "classnames";
import TextInputItem from "../../../components/TextInputItem";
import RoomProfiles from "./RoomProfiles";
import {selectApp} from "../../app/appSlice";
import Checkbox from "../../../components/Checkbox";
import {Spinner} from "../../../components/Spinner";
import RedeemLoyalty from "./RedeemLoyalty";
import usePayNowUrl from "../../../hooks/usePayNowUrl";
import ToolTip from "../../../components/ToolTip";
import GuestLookup from "./GuestLookup";
import TextInputItemButton from "../../../components/TextInputItemButton";
import useFirestoreFetch from "../../../hooks/useFirestoreFetch";
import {getSiteDocId} from "../../../api/firebaseApi";
import LoyaltyValidate2 from "./LoyaltyValidate2";
import {
  trackConversion,
  trackEvent,
  trackPageView
} from "../../../api/googleTag";
import {trackWHIPConversion} from "../../../api/wihp";
import {getSinglePropertyId} from "../../app/appUtil";

const PayerProfile = forwardRef(({reservation}, ref) => {

  const payerProfile = reservation?.payerProfile ?? {};

  const {site, agent} = useSelector((state) => ({
    site: selectApp(state).site,
    agent: selectApp(state).agent,
  }))

  const [loyaltyNo, setLoyaltyNo] = useState('')
  const [titleId, setTitleId] = useState(0);
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [cellNo, setCellNo] = useState('');
  const [idNo, setIdNo] = useState('');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [countryId, setCountryId] = useState(0);
  const [originId, setOriginId] = useState(0);
  const [validateFields, setValidateFields] = useState({})
  const [openGuestLookup, setOpenGuestLookup] = useState(false)
  const [saveProfile, setSaveProfile] = useState(false)
  const [canSaveProfile, setCanSaveProfile] = useState(true)

  const dispatch = useDispatch();

  const updateProfile = React.useCallback(() => {

    const payerProfile_ = {
      ...payerProfile,
      loyaltyNo,
      titleId,
      firstname,
      surname,
      cellNo,
      email,
      idNo,

      address1,
      address2,
      postalCode,
      city,
      countryId,
      ...(saveProfile && {agentId: agent?.agentId}),
      ...((!saveProfile || !canSaveProfile) && {agentId: undefined})
    };

    dispatch(updatePayerProfile({payerProfile: payerProfile_}))

  }, [
    loyaltyNo,
    titleId,
    firstname,
    surname,
    cellNo,
    email,
    idNo,

    address1,
    address2,
    postalCode,
    city,
    countryId,
    saveProfile,

    dispatch]);

  useEffect(() => {

    setLoyaltyNo(payerProfile?.loyaltyNo)
    setTitleId(payerProfile?.titleId)
    setFirstname(payerProfile?.firstname || '');
    setSurname(payerProfile?.surname || '');
    setCellNo(payerProfile?.cellNo || '');
    setEmail(payerProfile?.email || '');
    setIdNo(payerProfile?.idNo || '')

    setAddress1(payerProfile?.address1 || '')
    setAddress2(payerProfile?.address2 || '')
    setPostalCode(payerProfile?.postalCode || '')
    setCity(payerProfile?.city || '')
    setCountryId(payerProfile?.countryId)

    setCanSaveProfile(payerProfile?.canSaveProfile ?? true)

  }, [payerProfile]);

  const handleOnBlur = () => {
    updateProfile();
  };

  useImperativeHandle(ref, () => ({
    validate() {
      const validateFields = {
        titleId: (titleId) => {
          return !titleId ? 'Required' : undefined;
        },
        firstname: (firstname) => {
          return !firstname ? 'Required' : undefined
        },
        surname: (surname) => {
          return !surname ? 'Required' : undefined
        },
        cellNo: (cellNo) => {
          return (!cellNo && site.cellNoMandatory) ? 'Required' : undefined
        },
        email: (email) => {
          return !validEmail(email) ? 'Valid e-mail required.' : undefined;
        },
        idNo: (idNo) => {
          return (!idNo && site.idNoMandatory) ? 'Required' : undefined;
        },
        address1: (address1) => {
          return (!address1 && site.addressMandatory) ? 'Required' : undefined;
        },
        address2: (address2) => {
          return (!address2 && site.addressMandatory) ? 'Required' : undefined;
        },
        city: (city) => {
          return (!city && site.addressMandatory) ? 'Required' : undefined;
        },
        postalCode: (postalCode) => {
          return (!postalCode && site.addressMandatory) ? 'Required'
            : undefined;
        },
        originId: (originId) => {
          return (!originId && site.originMandatory) ? 'Required' : undefined;
        },
        countryId: (countryId) => {
          return (!countryId && site.countryMandatory) ? 'Required' : undefined;
        },
      }

      setValidateFields(validateFields);

      const titleValid = validateFields['titleId']
      const firstNameValid = validateFields['firstname'];
      const surnameValid = validateFields['surname'];
      const cellNoValid = validateFields['cellNo'];
      const emailValid = validateFields['email'];
      const idNoValid = validateFields['idNo'];
      const address1Valid = validateFields['address1'];
      const address2Valid = validateFields['address2'];
      const cityValid = validateFields['city'];
      const postalCodeValid = validateFields['postalCode'];
      const countryIdValid = validateFields['countryId'];
      const originIdValid = validateFields['originId'];

      return !titleValid(titleId)
        && !firstNameValid(firstname)
        && !surnameValid(surname)
        && !cellNoValid(cellNo)
        && !emailValid(email)
        && !idNoValid(idNo)
        && !address1Valid(address1)
        && !address2Valid(address2)
        && !cityValid(city)
        && !postalCodeValid(postalCode)
        && !countryIdValid(countryId)
        && !originIdValid(originId)
    }
  }))

  const handleGuestProfileSelect = useCallback((profile) => {
    dispatch(updatePayerProfile({
      payerProfile: Object.assign({}, payerProfile,
        convertRestProfile(profile), {canSaveProfile: false})
    }))
  }, [payerProfile, dispatch])

  const handleTitleOnChange = useCallback((value) => {
    setTitleId(value?.ID)

    dispatch(updatePayerProfile({payerProfile: {...payerProfile, titleId: value?.ID}}))
  }, [payerProfile]);

  const handleCountryOnChange = useCallback((value) => {
    setCountryId(value?.ID)

    dispatch(updatePayerProfile({payerProfile: {...payerProfile, countryId: value?.ID}}))
  }, [payerProfile]);

  const handleOriginOnChange = useCallback((value) => {
    setOriginId(value?.ID)

    dispatch(updatePayerProfile({payerProfile: {...payerProfile, originId: value?.ID}}))
  }, [payerProfile]);

  return (
    <>
      {(site?.showLoyaltyNo && agent) &&
        <LoyaltyValidate2 loyaltyNo={loyaltyNo}
                          surname={surname}
                          onLoyaltyNoChange={setLoyaltyNo}
                          onSurnameChange={setSurname}
                          handleOnBlur={handleOnBlur}
                          payerProfile={payerProfile}
        />}

      <div ref={ref} className={classNames("bn-inputs-group")}>
        <div className={"bn-inputs-left"}>

          <SelectLookup className={"bn-group bn-select-item"}
                        id={"title"}
                        label={"Title"}
                        name={"Title"}
                        value={titleId}
                        mandatory={true}
                        placeholder={"Title"}
                        // onChange={(value) => setTitleId(value.ID)}
                        onChange={(value) => handleTitleOnChange(value)}
                        defaultToFirstOption={true}
                        // onBlur={(value) => handleOnBlur()}
                        isClearable={false}
                        validate={validateFields['titleId']}
          />

          <TextInputItem className={"bn-group bn-input-item"}
                         id={"firstname"}
                         label={"Firstname"}
                         placeholder={"Firstname"}
                         mandatory={true}
                         maxLength={50}
                         value={firstname}
                         onChange={(event) => setFirstname(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['firstname']}
          />

          {!agent && <TextInputItem className={"bn-group bn-input-item"}
                          id={"surname"}
                          label={"Surname"}
                          value={surname}
                          placeholder={"Surname"}
                          mandatory={true}
                          maxLength={50}
                          onChange={(event) => setSurname(
                            event.target.value)}
                          onBlur={handleOnBlur}
                          validate={validateFields['surname']}
          />}

          {
            agent && <TextInputItemButton className={"bn-group bn-input-item"}
                                          id={"surname"}
                                          label={"Surname"}
                                          placeholder={"Surname"}
                                          mandatory={true}
                                          maxLength={50}
                                          value={surname}
                                          onChange={(event) => setSurname(
                                            event.target.value)}
                                          onBlur={handleOnBlur}
                                          validate={validateFields['surname']}
                                          onButtonClick={() => setOpenGuestLookup(true)}
                                          busy={false}
            />
          }

        </div>
        <div className={"bn-inputs-right"}>

          <TextInputItem className={"bn-group bn-input-item"}
                         id={"mobile"}
                         label={"Mobile"}
                         placeholder={"Mobile"}
                         mandatory={site?.cellNoMandatory ?? false}
                         maxLength={20}
                         value={cellNo}
                         onChange={(event) => setCellNo(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['cellNo']}
          />

          <TextInputItem className={"bn-group bn-input-item"}
                         id={"email"}
                         label={"E-mail"}
                         value={email}
                         placeholder={"E-mail"}
                         mandatory={true}
                         maxLength={100}
                         onChange={(event) => setEmail(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['email']}
          />

          {(!site?.hideIdNo) && <TextInputItem className={"bn-group bn-input-item"}
                          id={"idNo"}
                          label={"ID Number"}
                          placeholder={"ID Number or Passport"}
                          mandatory={site?.idNoMandatory ?? false}
                          maxLength={25}
                          value={idNo}
                          onChange={(event) => setIdNo(
                            event.target.value)}
                          onBlur={handleOnBlur}
                          validate={validateFields['idNo']}
          />}

        </div>
      </div>
      <h2>Address Details</h2>
      <div className={"bn-inputs-group"}>
        <div className={"bn-inputs-left"}>
          <TextInputItem className={"bn-group bn-input-item"}
                         id={"address"}
                         label={"Address"}
                         value={address1}
                         placeholder={"Address"}
                         mandatory={site?.addressMandatory ?? false}
                         maxLength={50}
                         onChange={(event) => setAddress1(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['address1']}
          />

          <TextInputItem className={"bn-group bn-input-item"}
                         id={"address2"}
                         label={"Address"}
                         value={address2}
                         placeholder={"Address"}
                         mandatory={site?.addressMandatory ?? false}
                         maxLength={50}
                         onChange={(event) => setAddress2(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['address2']}
          />

          <TextInputItem className={"bn-group bn-input-item"}
                         id={"city"}
                         label={"City"}
                         value={city}
                         placeholder={"City"}
                         mandatory={site?.addressMandatory ?? false}
                         maxLength={50}
                         onChange={(event) => setCity(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['city']}
          />

        </div>
        <div className={"bn-inputs-right"}>

          <TextInputItem className={"bn-group bn-input-item"}
                         id={"postalCode"}
                         label={"Postal Code"}
                         value={postalCode}
                         placeholder={"Postal Code"}
                         mandatory={site?.addressMandatory ?? false}
                         maxLength={10}
                         onChange={(event) => setPostalCode(
                           event.target.value)}
                         onBlur={handleOnBlur}
                         validate={validateFields['postalCode']}
          />

          <SelectLookup className={"bn-group bn-select-item"}
                        id={"country"}
                        label={"Country"}
                        name={"Country"}
                        value={countryId}
                        mandatory={site?.countryMandatory}
                        placeholder={"Country"}
                        onChange={(value) => handleCountryOnChange(value)}
                        // onChange={(value) => setCountryId(value?.ID)}
                        defaultToFirstOption={true}
                        // onBlur={handleOnBlur}
                        isClearable={!site?.countryMandatory}
                        validate={validateFields['countryId']}
          />

          {(!site?.hideOrigin) && <SelectLookup className={"bn-group bn-select-item bn-select-no-title"}
                         name={"Origin"}
                         value={originId}
                         mandatory={site?.originMandatory}
                         placeholder={`Where did you hear about us? ${site?.originMandatory
                           ? '*' : ''}`}
                         onChange={(value) => handleOriginOnChange(value)}
                         // onChange={(value) => setOriginId(value?.ID)}
                         defaultToFirstOption={true}
                         // onBlur={handleOnBlur}
                         isClearable={!site?.originMandatory}
                         validate={validateFields['originId']}
          />}

          {(agent && canSaveProfile) && <Checkbox id={'saveProfile'}
                              value={saveProfile}
                              onChange={(event) => setSaveProfile(
                                event.target.checked)}
                              onBlur={handleOnBlur}
                              className={'bn-checkbox-item'}
                              label={"Save profile"}
          />}

          {agent && <GuestLookup surname={surname}
                                 open={openGuestLookup}
                                 onProfileSelect={handleGuestProfileSelect}
                                 onOpenChange={setOpenGuestLookup}
                                 payerProfile={payerProfile}
                                 agentId={agent.agentId}
          />}

        </div>
      </div>
    </>
  )
});

export default React.memo(function () {

  const navigate = useNavigate();

  const {
    reservation,
    loyaltyProfile,
    error,
    appBusy,
    commitBusy,
    customer,
    token,
    agent,
    instance,
    site,
    singleProperty,
  } = useSelector(
    state => ({
      appBusy: selectApp(state).busy,
      customer: selectApp(state).customer,
      site: selectApp(state).site,
      token: selectApp(state).token,
      agent: selectApp(state).agent,
      instance: selectApp(state).instance,
      reservation: selectSession(state).reservation,
      commitBusy: selectSession(state).commitBusy,
      loyaltyProfile: selectApp(state).loyaltyProfile,
      singleProperty: selectApp(state).singleProperty,
      error: selectSession(state).error,
    }));

  const [comments, setComments] = useState('');
  const [acceptPopi, setAcceptPopi] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [redeemPoints, setRedeemPoints] = useState(false);
  const [amountPayable, setAmountPayable] = useState(undefined);
  const [nextAmountPayable, setNextAmountPayable] = useState(undefined);
  const [validateFns, setValidateFns] = useState({});
  const [totals, setTotals] = useState({});
  const [showTerms, setShowTerms] = useState(false)
  const [terms, setTerms] = useState('')

  const dispatch = useDispatch();
  const updateReservation_ = useCallback((reservation) =>
    dispatch(updateReservation({reservation})), [dispatch]);
  const releaseTemphold_ = useCallback(
    (params) => dispatch(releaseTemphold(params)), [dispatch]);
  const commit = useCallback((params) => dispatch(doCommit(params)),
    [dispatch]);
  const updateRedeem_ = useCallback((redeem) => dispatch(updateRedeem({redeem})),
    [dispatch]);

  const {getPayNowUrl, busy: payNowUrlBusy, error: urlError} = usePayNowUrl();
  const {fetchData: fetchSiteContent, busy: contentBusy} = useFirestoreFetch();

  useEffect(() => {
    scrollToBookNow();

    if(!reservation) {
      navigate(!singleProperty ? '/' : `/hotel/${getSinglePropertyId(site)}`)
    }

  }, []);

  useEffect(() => {
    if(reservation) {
      trackPageView('/guestdetails', site, firstProperty(reservation))

      trackEvent("add_shipping_info", "clicked", "add_guest_detail");
    }
  }, [site]);

  useEffect(() => {
    if (reservation) {
      setAmountPayable(calcAmountPayable(reservation));
      setNextAmountPayable(calcNextAmountPayable(reservation));
      setTotals(calcTotals(reservation));
    }
  }, [reservation]);

  const payerProfileRef = useRef(undefined);

  const handleNewReservation = () => {
    trackEvent("engagement", "clicked", "start_over");

    removeReservation().then(() => {
      releaseTemphold_({sessionId: reservation.sessionId});
      updateReservation_(undefined);
      navigate(!singleProperty ? '/' : `/hotel/${getSinglePropertyId(site)}`)
    });

  };

  const handleBack = () => {
    trackEvent("engagement", "clicked", "back_to_hotel");

    navigate(-1)
  };

  const validate = () => {

    // validate terms
    setValidateFns({
      acceptPopi: (value) => {
        return !value;
      },
      acceptTerms: (value) => {
        return !value;
      }
    })

    // validate payerProfile
    if (!payerProfileRef?.current.validate()) {
      scrollToBookNow();
    }

    return (payerProfileRef?.current.validate() && acceptPopi && acceptTerms)
  }

  const handlePay = (doPayNow) => {

    if (validate()) {

      // trackEvent("ecommerce", "clicked", "continue_to_payment");

      const updatedReservation = {...reservation,
        memo: comments,
        contactName: agent?.name,
        contactEmail: agent?.email,
        ...(reservation.payerProfile && {
          payerProfile: {
            ...reservation.payerProfile,
            telNo: reservation.payerProfile.cellNo
          }
        }),
      };

      commit({reservation: updatedReservation}).then((result) => {
        const {reservationNo, payerProfile: {email}} = result.payload;
        const reservation = convertReservation({}, result.payload);
        const redirectUrl = `${getHostUrl(site.hostUrl)}/#/confirmation/${reservationNo}/${email}`

        trackEvent(
          "ecommerce", "reservation_confirmation", "provisional_reservation");

        trackConversion(reservation, site, 'begin_checkout')
        trackWHIPConversion(reservation, site)

        removeReservation().then(() => {

          if (doPayNow && !redeemPoints) {
            getPayNowUrl(reservation, amountPayable.amount,
              amountPayable.currencyId, redirectUrl, customer, token).then(
              payNowUrl => {

                trackEvent(
                  "ecommerce", "payment", "payment_redirect");

                window.location.href = payNowUrl;
                // navigate(`/confirmation/${reservationNo}/${email}`)

              }).catch(error => {
              console.log('error', error)
            });
          } else {
            navigate(`/confirmation/${reservationNo}/${email}`)
          }

        })

      }).catch(error => {
        console.log('commit error', error)
        scrollToBookNow()
      })
    }
  };

  const handleRedeem = (isRedeem) => {
    setRedeemPoints(isRedeem)

    const currencyCode = Object.keys(totals.taxInclusive)[0];// only  1 currency per booking
    const grandTotal = totals.taxInclusive[currencyCode].grandTotal;

    if(isRedeem) {
      updateRedeem_({
        pointsToRedeem: grandTotal,
        redemptionTypeId: 1, // REDEMPTIONTYPE_NEW
        loyaltyActionId: LOYALTY_ACTION_REDEEM,
      })
    }
    else {
      updateRedeem_({
        pointsToRedeem: 0,
      })
    }
  }

  const handleShowTerms = (show, event) => {
    event.preventDefault()

    fetchSiteContent(
      getSiteDocId({instance, siteId: site?.id, languageId: 1}),
      (doc => {
        if (doc?.termsAndConditions) {
          setTerms(doc.termsAndConditions)
        } else {
          setTerms('')
        }
      }))

    setShowTerms(show);
  }

  return (
    <div className={"bn-guest-details"}>
      {!appBusy && <div className={"bn-guest-details-container"}>
        <div className={"bn-guest-input-container"}>
          {error && <div className={'bn-error-message'}>{error}</div>}
          <h1><FontAwesomeIcon
            className={"bn-svg-button"}
            onClick={handleBack}
            icon="fa-solid fa-arrow-left"/> Guest Details</h1>

          <PayerProfile ref={payerProfileRef} reservation={reservation}/>

          <RoomProfiles/>

          <h1>Comments</h1>
          <div className={"bn-comments-container"}>
            <textarea id={"comments-textarea"} value={comments}
                      onChange={(event) => setComments(event.target.value)}
                      rows={10}/>
          </div>

          {(reservation && hasDepositDue(calcDepositSummary(reservation))) &&
            <h1>Payment Details</h1>}

          <div className={"bn-payment-container"}>
            {(amountPayable
                && isPayableToday(amountPayable)
                && loyaltyProfile)
              && <RedeemLoyalty checked={redeemPoints}
                                onChange={event => handleRedeem(
                                  event.target.checked)}
                                disabled={
                loyaltyProfile.pointsAvailable < amountPayable.amount}
                                loyaltyProfile={loyaltyProfile}/>
            }

            {(amountPayable && isPayableToday(amountPayable))
              && <p className={'payment-amount-payable'}>
                {`${formatAmount(amountPayable.amount,
                  amountPayable.currencyCode)} required to secure the booking.`}
              </p>
            }

            {(amountPayable && !isPayableToday(amountPayable))
              && <p className={'payment-amount-payable'}>
                {`${formatAmount(amountPayable.amount,
                  amountPayable.currencyCode)} due on or before ${amountPayable.dueDate.toLocaleDateString()}.`}
              </p>
            }

            {(!amountPayable && nextAmountPayable)
              && <p className={'payment-amount-payable'}>
                {`${formatAmount(nextAmountPayable.amount,
                  nextAmountPayable.currencyCode)} due on or before ${nextAmountPayable.dueDate.toLocaleDateString()}.`}
              </p>
            }

            {isOnAccount(agent) &&
              <p>
                Payment on Account
              </p>
            }

            {isPayNow(amountPayable, agent) &&
              <>
                <p>
                  You will be redirected to your Bank's website for payment.
                  Please do not close the browser at any time during the payment
                  process.
                </p>
                <div className={'bn-payment-img'}>
                  <img src={getHtiHostedImageURL(customer, 'credit-card-logo.png')}/>
                </div>
              </>
            }

            <Checkbox id={'popi'}
                      value={acceptPopi}
                      onChange={(event) => setAcceptPopi(event.target.checked)}
                      className={'popi bn-checkbox-item'}
                      label={"I hereby understand that any personal information "
                        + "entered by me will be used to facilitate this "
                        + "reservation and where required will be provided to "
                        + "3rd parties such as the hotel as required for normal "
                        + "operational processes."}
                      validate={validateFns['acceptPopi']}
            />
            <Checkbox id={'terms'}
                      value={acceptTerms}
                      onChange={(event) => setAcceptTerms(event.target.checked)}
                      className={'bn-terms bn-checkbox-item'}
                      label={<div>I have read and accept the <a onClick={(event) => handleShowTerms(!showTerms, event)}>Terms and Conditions</a></div>}
                      validate={validateFns['acceptTerms']}
            />
            {showTerms && <div className={"bn-terms-expand-container"}
                  dangerouslySetInnerHTML={getInnerHTML(terms,
                    'Terms and conditions not available.')}>
            </div>}
          </div>

          <div className={"bn-button-container"}>
            {(!(commitBusy || payNowUrlBusy) && isPayNow(amountPayable, agent)) && <button
              className={'bn-booknow-button'}
              onClick={() => handlePay(true)} disabled={!acceptTerms
              || !acceptPopi}>Continue to Payment</button>}
            {(!(commitBusy || payNowUrlBusy) && !isPayNow(amountPayable, agent)) && <button
              className={'bn-booknow-button'}
              onClick={() => handlePay(false)} disabled={!acceptTerms
              || !acceptPopi}>Save</button>}
            {(commitBusy || payNowUrlBusy) && <div style={{width: 221}}><Spinner size={'45px'}/>
            </div>}
            <p>or</p>
            <ToolTip content={"Start your reservation over from scratch."}>
              <button className={'bn-booknow-button'}
                      onClick={handleNewReservation} disabled={commitBusy}>Start Over
              </button>
            </ToolTip>
          </div>
        </div>
        <Itinerary action={ItineraryActions.PAY} validateFields={validate}/>
      </div>}
    </div>
  )
});