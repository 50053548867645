import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {
  doCommit,
  doFetchReservation,
  releaseTemphold,
  selectSession,
  updateReservation
} from "../sessionSlice";
import {
  DATE_TIME_FORMAT_TIME_ZONE,
  formatAmount,
  getAttachmentsPath, getHostUrl,
  getInnerHTML,
  getPath, numericOnly,
  scrollToBookNow,
} from "../../Util";
import {
  appendToMemo, calcGrandTotal,
  calcNextAmountPayable, calcTotalDue,
  calcTotalReceived,
  calcTotals,
  canEdit, firstProperty,
  getArrivalDate, getCurrenciesPayable,
  getDepartDate,
  getDepositsReceivedSorted, getItinerary,
  getNoOfAdults,
  getNoOfChildren, getReservationCurrencies,
  getStatusName,
  memoContains,
  parseDate,
  removeReservation
} from "../reservationUtil";
import {loyaltyLogin, selectApp} from "../../app/appSlice";
import {
  format, isAfter,
} from 'date-fns'
import GoogleMapReact from 'google-map-react';
import HotelMarker, {distanceToMouse} from "../../../components/HotelMarker";
import {useParams} from "react-router-dom";
import {Spinner} from "../../../components/Spinner";
import _ from "lodash";
import {selectResults, setImgUrls} from "../../results/resultsSlice";
import useImageUrls from "../../../hooks/useImageUrls";
import useFirestoreFetch from "../../../hooks/useFirestoreFetch";
import {getPropertyDocId, getSiteDocId} from "../../../api/firebaseApi";
import Attachments from "../../../components/Attachments";
import TextInputItem from "../../../components/TextInputItem";
import usePayNowUrl from "../../../hooks/usePayNowUrl";
import usePaymentReceivedFetch from "../../../hooks/usePaymentReceivedFetch";
import {
  trackConversion,
  trackEvent,
  trackPageView
} from "../../../api/googleTag";
import {getSinglePropertyId} from "../../app/appUtil";
import {CancelReservation} from "./CancelReservation";

const PayNowButton = ({reservation, site, customer, token}) => {

  const [value, setValue] = useState('0.00')
  const [amountToPay, setAmountToPay] = useState(undefined)
  const [amountPayable, setAmountPayable] = useState(undefined)
  const [maxAmountPayable, setMaxAmountPayable] = useState(undefined)
  const [isPayInAdvance, setIsPayInAdvance] = useState(false)
  const [show, setShow] = useState(false)
  const totals = calcTotals(reservation)

  const {getPayNowUrl, busy: payNowUrlBusy, error: urlError} = usePayNowUrl();

  useEffect(() => {
    const nextAmountPayable = calcNextAmountPayable(reservation);

    if (nextAmountPayable) {
      setAmountPayable(nextAmountPayable)
      setIsPayInAdvance(isAfter(nextAmountPayable.dueDate, new Date()))
      setValue(formatAmount(nextAmountPayable.amount))
      setAmountToPay(nextAmountPayable)

      const grandTotal = totals.taxInclusive[nextAmountPayable.currencyCode].grandTotal;
      if (grandTotal < nextAmountPayable.amount) {
        setMaxAmountPayable(nextAmountPayable);
      } else {
        const totalDue = grandTotal
          - calcTotalReceived(reservation.depositsReceived,
            nextAmountPayable.currencyCode);

        setMaxAmountPayable({
          amount: totalDue,
          currencyCode: nextAmountPayable.currencyCode,
          currencyId: nextAmountPayable.currencyId
        });
      }
    } else { // deposit due paid up or no deposit code
      const currency = getReservationCurrencies(reservation)[0];
      const grandTotal = calcGrandTotal(totals, currency.code) //totals.taxInclusive[currency.code].grandTotal;
      const totalDue = grandTotal
        - calcTotalReceived(reservation.depositsReceived, currency.code);

      if(totalDue > 0) {
        setIsPayInAdvance(true)
        setAmountPayable({
          amount: totalDue,
          dueDate: parseDate(getArrivalDate(getItinerary(reservation))),
          currencyCode: currency.code,
          currencyId: currency.id
        })

        setMaxAmountPayable({
          amount: totalDue,
          currencyCode: currency.code,
          currencyId: currency.id
        });
      }
    }

  }, [reservation]);

  const parseValue = (value) => {
    let numberStr = value.trim().replace(',', '');

    if (!value || value === '') {
      numberStr = '0.00';
    }

    return Number.parseFloat(numberStr);
  }

  const handleOnChange = (value) => {
    if (numericOnly(value)) {
      setValue(value)
      setAmountToPay(
        {...amountPayable, amount: parseValue(value)});
    }
  }

  const handleOnBlur = () => {
    setValue(formatAmount(parseValue(value)))
  }

  const handlePay = (amountToPay) => {
    const {reservationNo, payerProfile: {email}} = reservation;

    const redirectUrl = `${getHostUrl(site.hostUrl)}/#/confirmation/${reservationNo}/${email}`;

    getPayNowUrl(reservation, amountToPay.amount,
      amountToPay.currencyId, redirectUrl, customer, token).then(
      payNowUrl => {

        trackEvent(
          "ecommerce", "clicked", "pay_outstanding");

        window.location.href = payNowUrl;
        // navigate(`/confirmation/${reservationNo}/${email}`)

      }).catch(error => {
      console.log('error', error)
    });
  }

  return (
    <>
      {(!show && amountPayable) && <button className={'bn-booknow-button'}
                        onClick={() => setShow(!show)}>
        {isPayInAdvance ? 'Pay in Advance' : 'Pay Outstanding Amount'}</button>}
      {show && <div className={'bn-pay-now-container'}>
        <div className={'bn-pay-now-description-container'}>
          <div className={'bn-pay-now-description-row'}>
            <h4 className={'bn-pay-now-description'}>
              Maximum amount payable :
            </h4>
            <div className={'pay-now-amount'}>{formatAmount(
              maxAmountPayable.amount, maxAmountPayable.currencyCode)}</div>
          </div>
          {isPayInAdvance && <div className={'bn-pay-now-description-row'}>
            <h4
              className={'bn-pay-now-description'}>{`Due on ${amountPayable.dueDate.toLocaleDateString()} :`}</h4>
            <div className={'pay-now-amount'}>{formatAmount(
              amountPayable.amount, amountPayable.currencyCode)}</div>
          </div>}
        </div>
        <div className={'pay-now-input-container'}>
          <TextInputItem id={'pay-now'}
                         className={'bn-group bn-input-item'}
                         value={value}
                         label={'Amount'}
                         placeholder={'0.00'}
                         onChange={(event) => handleOnChange(
                           event.target.value)}
                         onBlur={() => handleOnBlur()}
          />
        </div>
        <div className={'bn-button-container'}>
          {!payNowUrlBusy && <button className={'bn-booknow-button'}
                                     onClick={() => handlePay(amountToPay)}
                                     disabled={parseValue(value) <= 0
                                       || parseValue(value)
                                       > maxAmountPayable.amount}>Pay
          </button>}
          {payNowUrlBusy && <Spinner size={"5em"}/>}
          <button className={'bn-booknow-button'}
                  onClick={() => setShow(!show)}>Not Now
          </button>
        </div>
      </div>}
    </>
  )
}

const PaymentError = ({queryParams}) => {

  const description = queryParams.get('description');
  const paymentReference = queryParams.get('paymentReference');

  useEffect(() => {
    trackEvent(
      "ecommerce", "payment", "payment_error");
  }, [queryParams]);

  return (
    <div className={'bn-payment-error-container'}>
      <h3>Payment Error</h3>
      <div><strong>Description: </strong>{description}</div>
      <div><strong>Reference: </strong>{paymentReference}</div>
    </div>
  )
}

const HotelImage = React.memo(function ({propertyId}) {

  const {cached, instance} = useSelector(state => ({
    cached: selectResults(state).imgUrls[`${propertyId}`],
    instance: selectApp(state).instance,
  }));

  const dispatch = useDispatch();
  const setImgUrls_ = useCallback((imgUrls) => dispatch(setImgUrls(imgUrls)),
    [dispatch]);

  const {data: imgUrls, busy, error} = useImageUrls(
    getPath(instance, propertyId, undefined), cached?.urls,
    setImgUrls_, getPropertyDocId({instance, propertyId, languageId: 1}));

  return (
    <>
      {(imgUrls && imgUrls.length > 0) && <div className={"bn-image"}
                                               style={{backgroundImage: `url(${imgUrls[0]})`}}/>}
      {(imgUrls?.length === 0) && <div className={'bn-image-slide'}
                                       style={{backgroundColor: '#c0c0c052'}}></div>}
    </>
  )
});

export default React.memo(function () {
  const navigate = useNavigate();
  const {reservationNo, email} = useParams();

  const queryParams = useMemo(() => {
    return new URLSearchParams(window.location.search)
  }, []);

  const {
    reservation,
    site,
    customer,
    token,
    instance,
    agent,
    loyaltyProfile,
    busy,
    error,
    singleProperty,
  } = useSelector(
    state => ({
      reservation: selectSession(state).reservation,
      busy: selectSession(state).fetchBusy,
      site: selectApp(state).site,
      customer: selectApp(state).customer,
      token: selectApp(state).token,
      instance: selectApp(state).instance,
      agent: selectApp(state).agent,
      loyaltyProfile: selectApp(state).loyaltyProfile,
      error: selectSession(state).error,
      singleProperty: selectApp(state).singleProperty,
    }));

  const dispatch = useDispatch();
  const fetchReservation_ = useCallback((reservationNo, email) =>
    dispatch(doFetchReservation({reservationNo, email})), [dispatch]);
  const updateReservation_ = useCallback((reservation) =>
    dispatch(updateReservation({reservation})), [dispatch]);
  const releaseTemphold_ = useCallback(
    (params) => dispatch(releaseTemphold(params)), [dispatch]);
  const commit = useCallback((params) => dispatch(doCommit(params)),
    [dispatch]);
  const loyaltyLogin_ = React.useCallback(
    (loyaltyNo, username) => dispatch(loyaltyLogin({loyaltyNo, username})),
    [dispatch]);

  const [totals, setTotals] = useState({});
  const [grandTotal, setGrandTotal] = useState('');
  const [amountOverdue, setAmountOverdue] = useState(undefined)
  const [properties, setProperties] = useState([])
  const [terms, setTerms] = useState('');
  const [pollPaymentReceived, setPollPaymentReceived] = useState(false)
  const [pollCount, setPollCount] = useState(0)

  const paymentError = queryParams.get('status') === 'error';
  const paymentSuccess = queryParams.get('status') === 'success';

  const {fetchData: fetchSiteContent, busy: contentBusy} = useFirestoreFetch();
  const {fetchData: fetchPaymentReceived, paymentsReceived, busy: paymentReceivedBusy} = usePaymentReceivedFetch();

  useEffect(() => {
    scrollToBookNow();

    removeReservation().then(() => {
      if (customer && !busy) {

        fetchReservation_(reservationNo, email).unwrap().then(() => {

          if(paymentSuccess) {
            trackEvent(
              "ecommerce", "payment", "payment_success");
          }

          fetchPaymentReceived(reservationNo, customer, token).then((r    ) => {
            setPollCount(0)
            setPollPaymentReceived(r.length === 0 || paymentSuccess);
          })
        })
        .catch(error => {
          console.log('fetchReservation error: ', error)
        })
      }

      if(loyaltyProfile) {
        loyaltyLogin_(loyaltyProfile.loyaltyNo, loyaltyProfile.surname)
      }
    })

  }, [customer, reservationNo, email]);

  useEffect(() => {
    if(reservation) {
      trackPageView('/confirmation', site, firstProperty(reservation))

      if(paymentSuccess) {
        trackConversion(reservation, site, 'purchase')
      }
    }
  }, [site, reservation]);

  useEffect(() => {
    let timeout;

    if (pollPaymentReceived) {

      timeout = setTimeout(() => {
        fetchPaymentReceived(reservationNo, customer, token).then(
          () => {
            setPollCount(pollCount + 1)
          })
      }, 5000)

      if (paymentsReceived.length > 0 || pollCount === 5) {
        setPollPaymentReceived(false)
      }
    }

    return () => {
      clearTimeout(timeout) // after each effect
    }

  }, [pollPaymentReceived, pollCount])


  useEffect(() => {
    if (site) {
      fetchSiteContent(
        getSiteDocId({instance, siteId: site?.id, languageId: 1}),
        (doc => {
          if (doc?.termsAndConditions) {
            setTerms(doc.termsAndConditions)
          } else {
            setTerms('')
          }
        }))
    }
  }, [site, instance]);

  useEffect(() => {
    if (reservation) {
      const totals_ = calcTotals(reservation);
      setTotals(totals_);

      for (let i = 0; i < Object.keys(totals_.taxInclusive).length; i++) {
        const currencyCode = Object.keys(totals_.taxInclusive)[i];
        if (totals_.taxInclusive[currencyCode] || totals_.taxExclusive[currencyCode]) {
          const grandTotal = getGrandTotal(totals_, currencyCode);
          setGrandTotal(formatAmount(grandTotal, currencyCode));
        }
      }

      setAmountOverdue(calcAmountOverdue(reservation));

      setProperties(
        _.uniqBy(reservation.itinerary, item => (item.property.id)));
    }

  }, [reservation]);

  const handleNew = () => {
    removeReservation().then(() => {
      releaseTemphold_({sessionId: reservation.sessionId});
      updateReservation_(undefined);

      navigate(!singleProperty ? '/' : `/hotel/${getSinglePropertyId(site)}`)
    });

  };

  const handleAmend = () => {
    trackEvent("engagement", "clicked", "booking_amend");

    navigate('/guestDetails')
  }

  const handleApiLoaded = (map, maps, property) => {
    // console.log('Google AIP loaded', map)

    // const position =
    //     {lat: Number.parseFloat(property.gpsLat), lng: Number.parseFloat(property.gpsLong)};

    // let marker = new maps.Marker({
    //   position,
    //   map,
    //   title: property.name
    // });
  }

  const handleHotelOnClick = (event, propertyId) => {
    trackEvent("engagement", "clicked", "hotel_detail");

    navigate(`/hotel/${propertyId}`);
  }

  const handleAttachSuccess = () => {
    if (!reservation.confidentialMemo || !memoContains(
      reservation.confidentialMemo,
      `/#/confirmation/${reservationNo}/${email}`)) {

      const confirmationUrl = `${getHostUrl(site.hostUrl)}/#/confirmation/${reservationNo}/${email}`

      const updatedReservation = {
        ...reservation,
        confidentialMemo: appendToMemo(reservation.confidentialMemo,
          `To view attachments: ${confirmationUrl}`)
      };

      commit({reservation: updatedReservation}).then((result) => {
        console.log('commit success')
      })
      .catch(error => {
        console.log('commit error', error)
      })
    }
  }

  const getGrandTotal = useCallback((totals, currencyCode) => {
    return totals.taxInclusive[currencyCode].grandTotal
      + totals.taxExclusive[currencyCode].grandTotal //totalNettTaxes
  }, [totals])

  const calcAmountOverdue = (reservation) => {
    const {depositsPayable, depositsReceived} = reservation;

    const reservationCurrencies = getReservationCurrencies(reservation);
    const firstCurrency = reservationCurrencies[0]; // we only support single currency bookings

    if(firstCurrency) {
      let amountOverdue = {
        [firstCurrency.code]: {
          amount: 0.00,
          currencyId: firstCurrency.id,
          currencyCode: firstCurrency.code,
        }
      };

      if (depositsPayable && depositsPayable.length) {

        const currencies = getCurrenciesPayable(depositsPayable);
        const firstDepositCurrency = currencies[0]; // we only support single currency bookings

        currencies.forEach(currency => {
          const totalReceived = calcTotalReceived(depositsReceived,
            currency.code);
          const totalDue = calcTotalDue(depositsPayable, currency.code)

          amountOverdue[currency.code] = {
            amount: (totalDue - totalReceived),
            currencyId: currency.id,
            currencyCode: currency.code,
          }
        })

        return amountOverdue[firstDepositCurrency.code];
      }

      return amountOverdue[firstCurrency.code];
    }
  }

  return (
    <>
      {(!busy && paymentError) && <PaymentError queryParams={queryParams}/>}
      {busy && <Spinner size={"10em"}/>}
      {(!busy && reservation && reservation.reservationNo) && <div className={"bn-confirmation-container"}>
        <h1>Reservation Details</h1>
        <div className={"bn-reservation-detail-container"}>
          <div className={"bn-reservation-detail"}>
            <div className={"bn-reservation-detail-row"}>
              <h4>Your Confirmation Number is :</h4>
              <p className={"reservationNo"}>
                {reservation.reservationNo}
              </p>
            </div>
            <div className={"bn-reservation-detail-row"}>
              <h4>Status :</h4>
              <p>{getStatusName(reservation.status)}</p>
            </div>
            <div className={"bn-reservation-detail-row"}>
              <h4>Check-In :</h4>
              <p>{parseDate(getArrivalDate(
                reservation.itinerary)).toLocaleDateString()}</p>
            </div>
            <div className={"bn-reservation-detail-row"}>
              <h4>Check-Out :</h4>
              <p>{getDepartDate(reservation).toLocaleDateString()}</p>
            </div>
            <div className={"bn-reservation-detail-row"}>
              <h4>Amount</h4>
              <p>{grandTotal}</p>
            </div>
            <div className={"bn-reservation-detail-row"}>
              <h4>Amount overdue</h4>
              <p>{amountOverdue ? formatAmount(amountOverdue.amount,
                amountOverdue.currencyCode) : '0.00'}</p>
            </div>
          </div>
          <div className={"bn-reservation-actions"}>
            {(reservation && reservation.status !== 'C')
              && <PayNowButton reservation={reservation}
                               site={site}
                               customer={customer}
                               token={token}/>}
            {(reservation && reservation.status !== 'C') &&
              <button className={'bn-booknow-button'}
                                    disabled={!canEdit(reservation, agent)}
                                    onClick={handleAmend}>Modify Booking
            </button>}
            <button className={'bn-booknow-button'}
                    onClick={handleNew}>Make another Booking
            </button>

            {(reservation && reservation.status !== 'C') &&
              <CancelReservation reservation={reservation} onCancelSuccess={fetchReservation_} />}

          </div>
        </div>
        <h2>Hotel details</h2>
        <div className={"bn-confirmation-hotel-container"}>
          {properties.map((item, index) => (
            <div key={index} className={"bn-confirmation-hotel-detail"}>
              <div className={"bn-confirmation-hotel-image"}
                   onClick={event => handleHotelOnClick(event,
                     item.property.id)}
              >
                <HotelImage propertyId={item.property.id}/>
              </div>
              <div className={"bn-confirmation-hotel-description"}>
                <h4 onClick={event => handleHotelOnClick(event,
                  item.property.id)}>{item.property.name}</h4>
                <p>{item.property.physicalAddress1}</p>
                <p>{item.property.physicalAddress2}</p>
                <p>{item.property.physicalCode}</p>
                <p>{item.property.city}</p>
                <p>{item.property.tel}</p>
              </div>
              {(item.property.gpsLat && item.property.gpsLong) &&
                <div className={"bn-confirmation-hotel-map-container"}>
                  <GoogleMapReact
                    bootstrapURLKeys={{key: 'AIzaSyDm1RoOG3sf20jj1A6fXy-ivtSv2LagFR4'}}
                    defaultCenter={{
                      lat: Number.parseFloat(item.property.gpsLat),
                      lng: Number.parseFloat(item.property.gpsLong)
                    }}
                    defaultZoom={11}
                    // options={{streetViewControl: true}}
                    resetBoundsOnResize
                    yesIWantToUseGoogleMapApiInternals
                    hoverDistance={50
                      / 2} // hovers 25px on x-axis and 25px on y-axis form centre of marker
                    distanceToMouse={distanceToMouse}
                    onGoogleApiLoaded={({map, maps}) => handleApiLoaded(
                      map, maps, item.property)}
                  >
                    <HotelMarker
                      lat={item.property.gpsLat}
                      lng={item.property.gpsLong}
                      name={item.property.name}
                      size={40}
                    />
                  </GoogleMapReact>
                </div>}
            </div>
          ))}
        </div>
        <h2>Personal details</h2>
        <div className={"bn-confirmation-guest-details"}>
          <div className={"bn-confirmation-guest-detail-column"}>
            <div className={"bn-guest-detail-item"}>
              <h4>Title :</h4>
              <p>{reservation.payerProfile.title}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>Firstname :</h4>
              <p>{reservation.payerProfile.firstname}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>Surname :</h4>
              <p>{reservation.payerProfile.surname}</p>
            </div>
          </div>
          <div className={"bn-confirmation-guest-detail-column"}>
            <div className={"bn-guest-detail-item"}>
              <h4>Email :</h4>
              <p>{reservation.payerProfile.email}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>Mobile :</h4>
              <p>{reservation.payerProfile.cellNo}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>ID Number :</h4>
              <p>{reservation.payerProfile.idNo}</p>
            </div>
          </div>
          <div className={"bn-confirmation-guest-detail-column"}>
            <div className={"bn-guest-detail-item"}>
              <h4>Address :</h4>
              <p>{reservation.payerProfile.address1}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>Address 2 :</h4>
              <p>{reservation.payerProfile.address2}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>City :</h4>
              <p>{reservation.payerProfile.city}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>Postal Code :</h4>
              <p>{reservation.payerProfile.postalCode}</p>
            </div>
            <div className={"bn-guest-detail-item"}>
              <h4>Country :</h4>
              <p>{reservation.payerProfile.country}</p>
            </div>
          </div>
        </div>
        <h2>Stay details</h2>
        <div className={"confirmation-stay-details"}>
          <table className={'bn-responsive-table'}>
            <thead>
            <tr>
              <th scope="col">Room</th>
              <th scope="col">Dates</th>
              <th scope="col">Adults</th>
              <th scope="col">Children</th>
              <th scope="col">Amount</th>
            </tr>
            </thead>
            <tbody>
            {(reservation.itinerary || []).map((item, idx) =>
              (
                <tr key={idx}>
                  <td data-label={"Room"}>
                    {item.inventoryName}
                  </td>
                  <td data-label={"Dates"}>
                    {format(parseDate(item.startDate),
                      'LLL dd')} - {format(
                    parseDate(item.endDate), 'LLL dd')}
                  </td>
                  <td data-label={"Adults"}>
                    {getNoOfAdults(item.roomBreakdown)}
                  </td>
                  <td data-label={"Children"}>
                    {getNoOfChildren(item.roomBreakdown)}
                  </td>
                  <td data-label={"Amount"}>
                    {formatAmount(item.amountInclTax, item.currencyCode)}
                  </td>
                </tr>
              ))
            }
            {totals.taxInclusive && (Object.keys(totals.taxInclusive)).map(
              (currencyCode, i) => (
                Object.keys(totals.taxInclusive[currencyCode].taxes).map(
                  (taxType, idx) => (
                    <tr key={'3-' + i + '-' + idx}>
                      <td className={"table-colspan"}
                          colSpan={3}></td>
                      <td className={"bn-table-heading"}>Tax
                        - {taxType}</td>
                      <td data-label={"Tax - " + taxType}>
                        {formatAmount(
                          totals.taxInclusive[currencyCode].taxes[taxType],
                          currencyCode)}
                      </td>
                    </tr>
                  ))
              ))
            }
            {totals.taxInclusive && (Object.keys(totals.taxInclusive)).map(
              (currencyCode, idx) => (
                <tr key={'grandTotal-' + idx}>
                  <td className={"table-colspan"} colSpan={3}></td>
                  <td className={"bn-table-heading"}>Grand Total</td>
                  <td data-label={"Grand Total"}>
                    {formatAmount(getGrandTotal(totals, currencyCode), currencyCode)}
                  </td>
                </tr>
              )
            )}
            </tbody>
          </table>
        </div>
        <h2>Payment details</h2>
        {<div
          className={"bn-confirmation-payment-details"}>
          <p>We have received the following payments:</p>
          <table className={'bn-responsive-table'}>
            <thead>
            <tr>
              <th scope="col">Capture Date</th>
              <th scope="col">Type</th>
              <th scope="col">Reference</th>
              <th scope="col">Amount</th>
            </tr>
            </thead>
            <tbody>
            {getDepositsReceivedSorted(paymentsReceived).map(
              (p, index) => (
                <tr key={index}>
                  <td data-label={"Capture Date"}>{parseDate(p.captureDate, DATE_TIME_FORMAT_TIME_ZONE).toLocaleString()}</td>
                  <td data-label={"Type"}>{p.depositPaymentType.name}</td>
                  <td data-label={"Reference"}>{p.reference}</td>
                  <td data-label={"Amount"}>{formatAmount(p.amount,
                    p.currency.code)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>}
        {(paymentsReceived.length === 0 && pollPaymentReceived) &&
          <div className={"bn-confirmation-payment-details"}>
            <Spinner size={'24px'} />
          </div>
        }
        {(paymentsReceived.length === 0 && !pollPaymentReceived) &&
          <div className={"bn-confirmation-payment-details"}>
            <p>No payments have been received. </p>
          </div>
        }
        {reservation.agentId && <><h2>Attachments</h2>
          <div className={"confirmation-attachments"}>
            <Attachments
              viewOnly={!agent}
              path={getAttachmentsPath(instance, reservation?.reservationNo)}
              onSuccess={handleAttachSuccess}
            />
          </div>
        </>}
        <h2>Comments</h2>
        <div className={"bn-confirmation-comments"}>
          <p>{reservation.memo ? reservation.memo : 'None'}</p>
        </div>
        <h2>Terms and Conditions</h2>
        <div className={"bn-confirmation-ts-and-cs"}
             dangerouslySetInnerHTML={getInnerHTML(terms,
               'Terms and conditions not available.')}>
        </div>
      </div>}

      {(!busy && error) && <div className={'bn-error-message'}>{error}</div>}

    </>
  )
});