import classNames from "classnames";
import TextInputItemButton from "../../../components/TextInputItemButton";
import * as React from "react";
import useLoyaltyValidate from "../../../hooks/useLoyaltyValidate";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../../app/appSlice";
import {useCallback, useEffect, useState} from "react";
import {updatePayerProfile} from "../sessionSlice";
import {convertProfile} from "../reservationUtil";
import Dialog from "../../../components/Dialog";
import TextInputItem from "../../../components/TextInputItem";
import {Spinner} from "../../../components/Spinner";

export default function LoyaltyValidate2({
  loyaltyNo,
  surname,
  onLoyaltyNoChange,
  onSurnameChange,
  handleOnBlur,
  payerProfile,
}) {

  const {defaultLoyaltyProgram = {}, customer, token} = useSelector((state) => ({
    customer: selectApp(state).customer,
    token: selectApp(state).token,
    defaultLoyaltyProgram: selectApp(state).defaultLoyaltyProgram
  }))

  const [loyaltyNo_, setLoyaltyNo_] = useState('')
  const [surname_, setSurname_] = useState('')
  const [validateFields, setValidateFields] = useState({})
  const [open, setOpen] = useState(false)
  const [loyaltyValidated, setLoyaltyValidated] = useState(false)

  const {
    validateLoyaltyNo,
    busy: validateBusy,
    error: validateError
  } = useLoyaltyValidate();

  const dispatch = useDispatch();

  useEffect(() => {

    if(open) {
      setLoyaltyNo_(payerProfile?.loyaltyNo ?? '');
      setSurname_(payerProfile?.surname ?? '');
    }

  }, [open]);

  useEffect(() => {

    setLoyaltyValidated(!!payerProfile?.loyaltyNo);

  }, [payerProfile]);

  const validate = useCallback( () => {
    const validateFields = {
      loyaltyNo_: (loyaltyNo) => {
        return !loyaltyNo ? 'Required' : undefined;
      },
      surname_: (surname) => {
        return !surname ? 'Required' : undefined
      },
    }

    setValidateFields(validateFields);

    const loyaltyValid = validateFields['loyaltyNo_'];
    const surnameValid = validateFields['surname_'];

    return !loyaltyValid(loyaltyNo_)
      && !surnameValid(surname_)
  }, [loyaltyNo_, surname_])

  const handleOnClickValidate = (loyaltyNo, surname) => {
    if (validate()) {
      validateLoyaltyNo(loyaltyNo, surname, customer, token).then(profile => {
        if (profile) {
          dispatch(updatePayerProfile({
            payerProfile: Object.assign({}, payerProfile,
              {canSaveProfile: false},
              convertProfile(profile))
          }))

          setLoyaltyValidated(true)
          setOpen(false)
        }
      })
    }
  }

  const handleLoyaltyNoClear = () => {
    dispatch(updatePayerProfile({payerProfile: Object.assign({}, payerProfile, {loyaltyNo: undefined})}))

    setLoyaltyValidated(false)
  }

  const validateDisabled = !loyaltyNo_ || !surname_;
  const loyaltyProgramName = defaultLoyaltyProgram.Name ?? 'Loyaly'

  return (
    <>
      {!loyaltyValidated && <div className={"bn-loyalty-validate-text"}>
        Validate
        guest's <strong>{loyaltyProgramName}</strong> membership
        details? <a onClick={() => setOpen(!open)}>Click Here</a>
      </div>}
      {
        loyaltyValidated &&
        <div className={"bn-loyalty-validated-container"}>
          <TextInputItemButton className={"bn-group bn-input-item"}
                               id={"loyaltyNo"}
                               label={`${loyaltyProgramName} Number`}
                               placeholder={`${loyaltyProgramName} Number`}
                               maxLength={50}
                               value={loyaltyNo}
                               buttonComponent={
                                 <div className={"bn-button-container"}>
                                   <button
                                     className={"bn-booknow-button"}
                                     onClick={() => setOpen(!open)}>Change
                                   </button>
                                   <button
                                     className={"bn-booknow-button bn-secondary-button"} onClick={() => handleLoyaltyNoClear()}>Clear
                                   </button>
                                 </div>
                               }
          />
        </div>
      }
      <Dialog open={open} onOpenChange={setOpen}>
        <>
          <div className={"bn-dialog-content"}>
            <div className={classNames("bn-loyalty-validate-container")}>
              <h1>Validate {loyaltyProgramName} membership</h1>
              <p>Please enter the guest's {loyaltyProgramName} number
                and Surname</p>
              <div className={"bn-inputs-group"}>
                <div className={"bn-inputs-left"}>

                  <TextInputItem className={"bn-group bn-input-item"}
                                 id={"loyaltyNo"}
                                 label={`${loyaltyProgramName} Number`}
                                 placeholder={`${loyaltyProgramName} Number`}
                                 mandatory={true}
                                 maxLength={50}
                                 value={loyaltyNo_}
                                 onChange={(event) => setLoyaltyNo_(
                                   event.target.value)}
                                 validate={validateFields['loyaltyNo_']}
                  />

                </div>

                <div className={"bn-inputs-right"}>
                  <TextInputItem className={"bn-group bn-input-item"}
                                 id={"loyalty-surname"}
                                 label={"Surname"}
                                 placeholder={"Surname"}
                                 mandatory={true}
                                 maxLength={50}
                                 value={surname_}
                                 onChange={(event) => setSurname_(
                                   event.target.value)}
                                 onBlur={handleOnBlur}
                                 validate={validateFields['surname']}
                  />
                </div>
              </div>
              {validateError && <div
                className={"bn-error-message"}>
                {validateError}</div>}
            </div>
            <div className={"bn-dialog-button-container"}>
              {!validateBusy && <button
                className={'bn-booknow-button'}
                onClick={() => handleOnClickValidate(loyaltyNo_, surname_)}
                disabled={validateDisabled}
              >Validate</button>}
              {validateBusy && <Spinner size={'45px'}/>}
            </div>
          </div>
        </>
      </Dialog>
    </>
  )
}