import React, {useEffect, useState} from "react";
import {AsyncPaginate} from "react-select-async-paginate";
import {useSelector} from "react-redux";
import {client, getApiUrl, NEBULA_REST} from "../api/client";
import {selectApp} from "../booknow/app/appSlice";

export default function SelectLookup({
  id,
  name,
  value,
  mandatory,
  label,
  onChange,
  onBlur,
  isClearable = true,
  validate,
  className,
  defaultCriteria,
  ...otherProps
}) {

  const {
    operationId,
    filterField,
    defaultToFirstOption,
    pageSize = 50
  } = otherProps;

  const error = (validate && validate(value));

  const [lookup, setLookup] = useState(undefined);

  const {customer, token} = useSelector(state => ({
    customer: selectApp(state).customer,
    token: selectApp(state).token,
  }))

  useEffect(() => {
    if (value && customer) {
      loadSelectedOption().then(response => {
        if (value || defaultToFirstOption) {
          if (value) {
            setLookup(response.options[0]);
          } else {
            setLookup(response.options.find(option => (option.ID === value)))
          }
        }
      }).catch(error => {
        console.log('lookup error', error)
      })
    } else if (!value) {
      setLookup(null)
    }
  }, [value, defaultToFirstOption, customer]);

  const onChange_ = (value) => {
    setLookup(value);
    onChange(value);
  };

  const loadSelectedOption = async () => {
    if (value || defaultToFirstOption) {
      let criteria = buildCriteria(0, 1, false);

      const response = await client.post(getApiUrl(customer, NEBULA_REST),
          criteria, token, undefined, false);

      return parseResponse(response);
    } else {
      return {
        options: [],
        hasMore: false
      };
    }
  };

  const loadOptions = async (search, prevOptions) => {
    let criteria = buildCriteria((prevOptions.length),
        (prevOptions.length + pageSize),
        true);

    if (search) {
      criteria = {
        ...criteria,
        data: {
          ...criteria.data,
          Name: String(search).toLowerCase(),
          ...(!filterField && {Name: String(search).toLowerCase()}),
          ...(filterField && {[filterField]: String(search).toLowerCase()})
        }
      }
    }

    const response = await client.post(getApiUrl(customer, NEBULA_REST),
        criteria, token, undefined, false);

    return parseResponse(response, defaultToFirstOption);
  };

  const parseResponse = (response, defaultToFirstOption) => {
    const {endRow, totalRows} = response;

    if (response.status !== -1) { //success
      return {
        options: response.data,
        hasMore: (endRow < totalRows)
      };
    } else {
      return {
        options: [],
        hasMore: false
      }
    }
  };

  const buildCriteria = (startRow, endRow, loadAllOptions) => {
    return {
      dataSource: name,
      operationId: operationId,
      operationType: "fetch",
      data: {
        Show: true,
        ...(!loadAllOptions && {ID: value}),
        ...defaultCriteria,
      },
      startRow: startRow,
      endRow: endRow,
      textMatchStyle: 'substring',
      sortBy: [
        "Name"
      ]
    }
  };

  const customStyles = {
    menu: (base, state) => ({
      ...base,
      zIndex: 999,
      // overflow: 'hidden'
    }),
    control: (base, state) => ({
      ...base,
      borderColor: error ? '#d63301 !important' : '#d9d9d9',
    }),
  }

  return (
    <div className={className}>
      <label htmlFor={id}>
        <div>{label} {mandatory && <span>*</span>}</div>
      </label>
      <AsyncPaginate
        id={id}
        value={lookup}
        loadOptions={loadOptions}
        onChange={onChange_}
        getOptionValue={(option) => (option.ID)}
        getOptionLabel={(option) => (option.Name)}
        {...otherProps}
        onBlur={onBlur}
        isClearable={isClearable}
        styles={customStyles}
        className={"bn-select-input"}
        classNamePrefix={'bn-select'}
      />
    </div>
  )
}