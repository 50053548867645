import {useState} from "react";
import {getData} from "../api/firebaseApi";

const useFirestoreGetDocs = () => {

  const [state, setState] = useState({
    data: [],
    busy: true,
    error: '',
  })

  const fetchData = (query, callback) => {

    setState({
      data: [],
      busy: true,
      error: '',
    })

    getData(query).then(docs => {
      setState({
        data: docs,
        busy: false,
        error: ''
      })

      if(callback) {
        callback(docs)
      }

    }).catch(error => {
      console.log('useFirestoreFetch error', error)
    })
  }

  return {fetchData, ...state};
}

export default useFirestoreGetDocs;