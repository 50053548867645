import {getPasswordRestrictions} from "./signupSlice";

export const getRestrictionName = (id, value) => {
  switch (id) {

    case 3: return "Length between "+value+" and 50 characters.";
    case 5: return "Minimum of one Number (0-9)."
    case 6: return "Minimum of one uppercase and lowercase character. (Aa-Zz)"
    case 7: return "Minimum of one Special character: - , / ' . ; & @ # * ) ( _ + : \" ~"

    default: return "Unknown";
  }
}

export const getValidationFn = (restriction) => {
  switch (restriction.id) {
    case 3 :
      return (restriction, password) => { // password length
        return password.length >= Number.parseInt(restriction.value)
            && password.length <= 50;
      }

    case 5 :
      return (restriction, password) => { // alphanumeric
        const regex = /[0-9]/
        return regex.test(password);
      }

    case 6 :
      return (restriction, password) => { // uppercase and lowercase
        const regex = /([a-z].*[A-Z]|[A-Z].*[a-z])/
        return regex.test(password);
      }

    case 7 :
      return (restriction, password) => { // special characters
        const regex = /[-,/'.;&@#*)(_+:"~]/
        return regex.test(password);
      }

    default :
      return () => {
        return false;
      }
  }
}

export const passwordValid = (passwordRestrictions, password) => {
  const validations = passwordRestrictions.map(r => {
    const validate = getValidationFn(r, password);
    return validate(r, password)
  });

  return validations.indexOf(false) === -1;
}