import {useState} from "react";
import {loadLookupItemsPage} from "../booknow/Util";

const useReservationSearch = () => {
  const [state, setState] = useState({
    busy: false,
    reservations: [],
    error: undefined,
  })

  const search = async (criteria, customer, token, callback) => {
    try {

      setState({
        busy: true,
        reservations: [],
      })

      let page = 0;
      let pageSize = 50;

      let criteriaWithPaging = {
        dataSource: "Reservation",
        operationType: "fetch",
        operationId: "fetchSearchResult",
        data: {
          ...criteria,
          // _constructor: "AdvancedCriteria",
          // operator: "and",
          // criteria: [
          // {
          //   operator: "or",
          //   criteria: [
          //     {
          //       fieldName: "Code",
          //       operator: "iContains",
          //       value: search.toLowerCase()
          //     },
          //     {
          //       fieldName: "Name",
          //       operator: "iContains",
          //       value: search.toLowerCase()
          //     }
          //   ]
          // },
          // {
          //   fieldName: "Show",
          //   operator: "equals",
          //   value: true
          // },
          // ],
        },
        startRow: 0,
        endRow: pageSize,
        textMatchStyle: 'substring',
        sortBy: [
          "ArrivalDate"
        ]
      };

      let hasMoreRows = true;
      let data = [];

      while (hasMoreRows) {
        page++;
        const response = await loadLookupItemsPage(criteriaWithPaging, page,
          pageSize,
          customer, token);

        const moreData = response.data;
        data = data.concat(moreData)

        hasMoreRows = (page * pageSize) < response.totalRows;

        setState({
          ...state,
          reservations: data,
        })
      }

      setState({
        busy: false,
        reservations: data,
        error: undefined,
      })

      if (callback) {
        callback(data)
      }

    } catch (error) {
      setState({
        reservations: [],
        busy: false,
        error
      })

      console.log('Reservation search error: ', error)
    }
  }

  return {search, ...state};
}

export default useReservationSearch;