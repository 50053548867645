import * as React from "react";
import classNames from "classnames";
import {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default React.memo(function ({selectedIndex = 0, tabs = [], onTabSelected = () => {}}) {

  const [selectedTabIndex, setSelectedTabIndex] = useState(selectedIndex??0);

  const leftArrowContainer = useRef(undefined);
  const rightArrowContainer = useRef(undefined);
  const tabsList = useRef(undefined);

  useEffect(() => {
    setSelectedTabIndex(selectedIndex)
    manageIcons()
  }, [selectedIndex]);

  const manageIcons = () => {
    if (tabsList.current.scrollLeft >= 20) {
      leftArrowContainer.current.classList.add("active");
    } else {
      leftArrowContainer.current.classList.remove("active");
    }

    let maxScrollValue = tabsList.current.scrollWidth
        - tabsList.current.clientWidth - 20;

    if (tabsList.current.scrollLeft >= maxScrollValue) {
      rightArrowContainer.current.classList.remove("active");
    } else {
      rightArrowContainer.current.classList.add("active");
    }
  }

  const handleOnTabSelected = (tabIndex) => {
    setSelectedTabIndex(tabIndex)
    onTabSelected(tabIndex)
  }

  const handleRightArrowOnClick = () => {
    tabsList.current.scrollLeft += 100;
    manageIcons();
  }
  const handleLeftArrowOnClick = () => {
    tabsList.current.scrollLeft -= 100;

    manageIcons();
  }

  return (
      <div className={"bn-scrollable-tabs-container"}>
        <div className="bn-left-arrow"
             ref={leftArrowContainer}
             onClick={() => handleLeftArrowOnClick()}>
          <FontAwesomeIcon icon="fa-solid fa-angle-left"/>
        </div>

        <div className={"bn-scrollable-tabs-list"} onScroll={manageIcons}
             ref={tabsList}>
          {
            tabs.map((tabItem, tabIndex) => (
                <div key={tabIndex}
                     className={classNames('bn-tab',
                         {'bn-selected': selectedTabIndex === tabIndex})}
                     onClick={() => handleOnTabSelected(tabIndex)}>
                  {tabItem.label}
                </div>
            ))
          }
        </div>

        <div className="bn-right-arrow active"
             ref={rightArrowContainer}
             onClick={() => handleRightArrowOnClick()}>
          <FontAwesomeIcon icon="fa-solid fa-angle-right"/>
        </div>
      </div>
  )
});