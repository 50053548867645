import {
  format,
  parse,
  differenceInCalendarDays,
  addDays,
  isBefore, isAfter, isToday, isPast, isEqual
} from 'date-fns'
import {copyObjectExcept, DATE_TIME_FORMAT, isEmpty} from "../Util";
import {buildRestEnvelope, CONNECTION_CODE} from "../../api/client";
import ReservationStatus from "./reservationStatuses";
import _ from "lodash";

const PENDING = "Pending";
const COMMIT = "Commit";

export const LOYALTY_ACTION_REDEEM = 2;

const PAYMENT_POLICY_STANDARD = 1;
const PAYMENT_POLICY_PRE_PAY = 2;
const PAYMENT_POLICY_PAY_NOW = 3;

const POLICY_RULE_CALC_PERCENT = 0;
const POLICY_RULE_CALC_1STNIGHT = 1;

const POLICY_TYPE_FROM_ARRIVAL = 1;

export const CANCEL_PENDING = 'CancelPending';
export const CANCEL_COMMIT = 'CancelCommit';

export const DEFAULT_MEALPLAN = {
  name: 'Room Only',
  code: 'RO'
};

export const RES_TYPE_SINGLE = 1;

export const createReservation = (payerProfile, agent, site) => {

  const {titleId = site.defaultTitleId, countryId = site.defaultCountryId} = {} // defaults;

  return {
    sessionId: generateSessionId(10),
    hasNeverBeenSaved: true,
    paymentTypeId: 3, // cash
    channelId: 1,
    agentId: agent?.agentId,
    contactId: agent?.id,
    payerProfile: payerProfile ?? {titleId, countryId},
    itinerary: [],
    resType: RES_TYPE_SINGLE
  }
};

export const getItinerary = (reservation) => {
  return reservation !== undefined
      ? reservation.itinerary.slice()
      : [];
};

export const buildBookRQ = (reservation, itinerary, agent, connectionCode) => {
  return {
    messageType: PENDING,
    sessionId: reservation.sessionId,
    connectionCode: connectionCode,
    agentId: agent?.agentId,
    id: reservation.id,
    ...(reservation._remove && {
          _remove: reservation._remove.map(remove => {
            if (remove.itinerary) {
              return {
                itineraries: remove.itinerary.slice()
              }
            } else {
              return remove;
            }
          })
        }
    ),
    itineraries: itinerary.map(item => {
      return {
        id: item.id,
        type: item.type,
        propertyId: item.propertyId,
        inventoryId: item.inventoryId,
        arrivalDate: item.startDate,
        duration: dateDiff(item.startDate, item.endDate),
        noOfRooms: item.roomBreakdown.length,
        roomBreakdown: item.roomBreakdown.map(room => {
          return {
            noOfAdults: room.noOfAdults,
            kids: room.kids,
            noOfRooms: 1,
            id: room.id,
            _remove: room._remove
          }
        }),
        rateCodeId: item.rateCodeId,
        ...((item.rateOverridden === true || item.rateCodeOverridden === true)
          && {currencyId: item.currencyId}),
        ...((item.rateOverridden === true && {
          rateBreakdown: item.rateBreakdown?.map(rateBreakDownItem => {
            return {
              effectiveDate: rateBreakDownItem.effectiveDate,
              rateCodeId: rateBreakDownItem.rateCode.id,
              mealPlanId: rateBreakDownItem.mealPlan.id,
              roomRate: rateBreakDownItem.roomRate,
              upToPax: rateBreakDownItem.upToPax,
              singlePP: rateBreakDownItem.singlePP,
              sharingPP: rateBreakDownItem.sharingPP,
              extraAdult: rateBreakDownItem.extraAdult,
              kids1: rateBreakDownItem.kids1,
              kids2: rateBreakDownItem.kids2,
              kids3: rateBreakDownItem.kids3,
            }
          }),
        })),
        rateOverridden: item.rateOverridden,
        rateCodeOverridden: item.rateCodeOverridden,
        pointsToRedeem : item.pointsToRedeem,
        loyaltyActionId: item.loyaltyActionId,
      }
    }),
    ...(reservation && hasRequiredFields(reservation?.payerProfile)
      && {payerProfile: reservation.payerProfile}),
  }
};

export const buildCommitRQ = (reservation, connectionCode) => {
  return {
    messageType: COMMIT,
    sessionId: reservation.sessionId,
    connectionCode: connectionCode,
    id: reservation.id,
    reservationNo: reservation.reservationNo,
    status: reservation.status,
    statusOverridden: reservation.statusOverridden,
    resType: reservation.resType,
    channelId: reservation.channelId,
    agentId: reservation.agentId,
    companyId: reservation.companyId,
    wholesalerId: reservation.wholesalerId,
    allocationId: reservation.allocationId,
    contactId: reservation.contactId,
    sourceId: reservation.sourceId,
    originId: reservation.originId,
    marketId: reservation.marketId,
    ...(reservation.channelConfNo !== ''
        && {channelConfirmationNo: reservation.channelConfNo}),
    promotionCode: reservation.promotionCode,
    paymentTypeId: reservation.paymentTypeId,
    credCardNo: reservation.credCardNo,
    credCardExp: reservation.credCardExp,
    accountNo: reservation.accountNo,
    voucherNo: reservation.voucherNo,
    depositPolicyId: reservation.depositPolicyId,
    depositPolicyOverridden: reservation.depositPolicyOverridden,
    depositDateOverridden: reservation.depositDateOverridden,
    depositOverridden: reservation.depositOverridden,
    _remove: reservation._remove,
    memo: reservation.memo,
    confidentialMemo: reservation.confidentialMemo,
    itineraries: reservation.itinerary.map(item => {
      return {
        id: item.id,
        type: item.type,
        propertyId: item.propertyId,
        rateCodeId: item.rateCodeId,
        inventoryId: item.inventoryId,
        arrivalDate: item.startDate,
        duration: dateDiff(item.startDate, item.endDate),
        noOfRooms: item.roomBreakdown.length,
        roomBreakdown: item.roomBreakdown.map(room => {
          return {
            id: room.id,
            noOfAdults: room.noOfAdults,
            kids: room.kids,
            noOfRooms: 1,
            ...(room.roomProfiles && {
              roomProfiles: room.roomProfiles.map(
                  roomProfile => buildRoomProfile(roomProfile, reservation)),
            }),
            ...(!room.roomProfiles &&
                {roomProfiles: [{profile: reservation.payerProfile}]}),
            services: (room.services || []).map(
                service => buildService(service)),
            _remove: room._remove
          }
        }),
        ...(item.rateOverridden === true
          && {rateBreakdown: item.rateBreakdown.map(rateBreakDownItem => {
                return {
                  effectiveDate: rateBreakDownItem.effectiveDate,
                  rateCodeId: rateBreakDownItem.rateCode.id,
                  mealPlanId: rateBreakDownItem.mealPlan.id,
                  roomRate: rateBreakDownItem.roomRate,
                  upToPax: rateBreakDownItem.upToPax,
                  singlePP: rateBreakDownItem.singlePP,
                  sharingPP: rateBreakDownItem.sharingPP,
                  extraAdult: rateBreakDownItem.extraAdult,
                  kids1: rateBreakDownItem.kids1,
                  kids2: rateBreakDownItem.kids2,
                  kids3: rateBreakDownItem.kids3,
                }
              })
            }),
        ...(item.rateCodeOverridden === true && {rateCodeId: item.rateCode.id}),
        rateOverridden: item.rateOverridden,
        rateCodeOverridden: item.rateCodeOverridden,
        note: item.note,
        pointsToRedeem : item.pointsToRedeem,
        loyaltyActionId: item.loyaltyActionId,
      }
    }),
    payerProfile: reservation.payerProfile,
    contactName: reservation.contactName,
    ...(!reservation.contactName && {
      contactName: `${reservation.payerProfile?.firstname} ${reservation.payerProfile?.surname}`.trim().substring(
        0, 49),
    }),
    contactEmail: reservation.contactEmail,
    ...(!reservation.contactEmail
      && {contactEmail: reservation.payerProfile?.email,}),
    ...(reservation.specialRequests && {
      specialRequests: (reservation.specialRequests || []).map(sr => (sr.id))
    })
  };
};

const buildRoomProfile = (roomProfile, reservation) => {

  const {payerProfile} = reservation;

  let profileToCopy = roomProfile?.profile ?? payerProfile

  if(profileToCopy.profileId === payerProfile.profileId) {
    profileToCopy = {...payerProfile}
  }

  const profile = {
    ...profileToCopy,
    ...(!profileToCopy.titleId
      && {
        titleId: payerProfile.titleId,
      }
    ),
    ...(!profileToCopy.surname
      && {
        surname: payerProfile.surname,
      }
    ),
    ...(profileToCopy.profileId === 0
      && {profileId: undefined}
    ),
  }

  return {
    ...roomProfile,
    profile,
    // ...((!roomProfile.profile || roomProfile.profile.profileId === reservation.payerProfile.profileId)
    //     && {profile: reservation.payerProfile}),
    // ...((roomProfile.profile && !roomProfile.profile.titleId)
    //     && {
    //       profile: {
    //         ...roomProfile.profile,
    //         titleId: reservation.payerProfile.titleId,
    //       }
    //     }),
    // ...((roomProfile.profile && !roomProfile.profile.surname)
    //     && {
    //       profile: {
    //         ...roomProfile.profile,
    //         surname: reservation.payerProfile.surname,
    //       }
    //     }),
    ...(roomProfile.specialRequests && {
      specialRequests: roomProfile.specialRequests.map(
          sr => (sr.id))
    }),
    ...(roomProfile.additionalProfiles && {
      additionalProfiles: (roomProfile.additionalProfiles || []).filter(
          ap => (!isEmpty(ap))).map(ap => {
        return {
          ...ap,
          ...(!ap.surname && {surname: reservation.payerProfile.surname}),
        }
      })
    })
  }
};

const buildService = (service) => {
  return {
    id: service.id,
    serviceId: service.serviceId,
    serviceDate: service.serviceDate,
    qty: service.qty,
    noOfAdults: service.noOfAdults,
    noOfKids1: service.noOfKids1,
    noOfKids2: service.noOfKids2,
    noOfKids3: service.noOfKids3,
    fromTime: service.fromTime,
    toTime: service.toTime,
    fromLocation: service.fromLocation,
    toLocation: service.toLocation,
    note: service.note,
    ...(service.rateCodeOverridden === true && {rateCodeId: service.rateCodeId}),

    ...((service.rateOverridden === true || service.rateBreakdown?.find(
            breakdown => (breakdown.rateCode.id !== service.rateCodeId)))
        && {
          rateBreakdown: service.rateBreakdown.map(rateBreakDownItem => {
            return {
              effectiveDate: rateBreakDownItem.effectiveDate,
              rateCodeId: rateBreakDownItem.rateCode.id,
              mealPlanId: rateBreakDownItem.mealPlan.id,
              roomRate: rateBreakDownItem.roomRate,
              upToPax: rateBreakDownItem.upToPax,
              singlePP: rateBreakDownItem.singlePP,
              sharingPP: rateBreakDownItem.sharingPP,
              extraAdult: rateBreakDownItem.extraAdult,
              kids1: rateBreakDownItem.kids1,
              kids2: rateBreakDownItem.kids2,
              kids3: rateBreakDownItem.kids3,
            }
          })
        }),
    ...((service.rateOverridden === true || service.rateCodeOverridden === true)
        && {currencyId: service.currencyId}),
    rateOverridden: service.rateOverridden,
    rateCodeOverridden: service.rateCodeOverridden,
  }
};

export const convertProfile = (responseProfile) => {

  return {
    ...copyObjectExcept(responseProfile, ['title', 'province', 'country', 'loyalty', 'loyaltyTier', 'loyaltyProgram']),
    ...(responseProfile.title && {
      titleId: responseProfile.title.id,
      title: responseProfile.title.code
    }),
    ...(responseProfile.province &&
        {
          provinceId: responseProfile.province.id,
          province: responseProfile.province.name,
        }
    ),
    ...(responseProfile.country &&
        {
          countryId: responseProfile.country.id,
          country: responseProfile.country.name,
        }
    ),
    ...(responseProfile.loyalty && _.first(responseProfile.loyalty) && {
      loyaltyNo: responseProfile.loyalty[0].loyaltyNo,
      loyaltyTierId: responseProfile.loyalty[0].loyaltyTier?.id,
      loyaltyTier: responseProfile.loyalty[0].loyaltyTier?.name,
      loyaltyProgramId: responseProfile.loyalty[0].loyaltyProgram?.id,
      loyaltyProgram: responseProfile.loyalty[0].loyaltyProgram?.name,
      pointsAvailable: responseProfile.loyalty[0].pointsAvailable,
      pointsToExpire: responseProfile.loyalty[0].pointsToExpire,
      loyaltySystemGuestId: responseProfile.loyalty[0].loyaltySystemGuestId,
      loyaltySystemMemberId: responseProfile.loyalty[0].loyaltySystemMemberId,
    }),
  }
};

export const convertRestProfile = (restProfile) => {
  return {
    profileId: restProfile.ID,
    ...(restProfile.TitleID && {
      titleId: restProfile.TitleID,
      title: restProfile.TitleName
    }),
    loyaltyNo: restProfile.LoyaltyNo,
    surname: restProfile.Name,
    firstname: restProfile.FirstName,
    telNo: restProfile.TelNo,
    cellNo: restProfile.CellNo,
    idNo: restProfile.IDNo,
    email: restProfile.Email,
    address1: restProfile.Address1,
    address2: restProfile.Address2,
    city: restProfile.City,
    postalCode: restProfile.PostalCode,
    ...(restProfile.ProvinceID &&
      {
        provinceId: restProfile.ProvinceID,
        province: restProfile.ProvinceName,
      }
    ),
    ...(restProfile.CountryID &&
      {
        countryId: restProfile.CountryID,
        country: restProfile.CountryName,
      }
    ),
    loyaltyProgramId: restProfile.LoyaltyProgramID,
    ...(restProfile.LoyaltyTierID && {
      loyaltyTierId: restProfile.LoyaltyTierID,
      loyaltyTier: restProfile.LoyaltyTierName
    }),
    agentId: restProfile.AgentID,
    show: restProfile.Show,
  }
};

export const convertToRestProfile = (profile) => {
  return {
    TitleID: profile.titleId,
    LoyaltyNo: profile.loyaltyNo,
    Name: profile.surname,
    FirstName: profile.firstname,
    TelNo: profile.telNo,
    CellNo: profile.cellNo,
    IDNo: profile.idNo,
    Email: profile.email,
    Address1: profile.address1,
    Address2: profile.address2,
    City: profile.city,
    PostalCode: profile.postalCode,
    ProvinceID: profile.provinceId,
    CountryID: profile.countryId,
    LoyaltyProgramID: profile.loyaltyProgramId,
    LoyaltyTierID: profile.loyaltyTierId,
    AgentID: profile.agentId,
    Show: profile.show,
  }
}

const getReservationRoomProfiles = (reservation, itemIndex, roomIndex) => {
  if (getItinerary(reservation)[itemIndex]) {
    if (getItinerary(reservation)[itemIndex].roomBreakdown
        && getItinerary(reservation)[itemIndex].roomBreakdown[roomIndex]) {
      return getItinerary(
          reservation)[itemIndex].roomBreakdown[roomIndex].roomProfiles
    }
  }
};

export const convertReservation = (reservation, response, modified) => {
  return {
    ...reservation,
    ...(modified !== undefined && {modified}),
    id: response.id,
    resType: response.resType,
    sessionId: response.sessionId,
    reservationNo: response.reservationNo,
    ...(response.status && {status: response.status}),
    createDate: response.createDate,
    amendDate: response.amendDate,
    agentId: (response.agent || {}).id,
    agent: (response.agent || {}).name,
    companyId: (response.company || {}).id,
    company: (response.company || {}).name,
    wholesalerId: (response.wholesaler || {}).id,
    wholesaler: (response.wholesaler || {}).name,
    allocationId: response.allocationId,
    contactId: reservation.contactId,
    channelId: response.channel.id,
    channelConfNo: response.channelConfirmationNo,
    promotionCode: response.promotionCode,
    ...(response.source && {sourceId: (response.source || {}).id}),
    ...(response.source && {source: (response.source || {}).name}),
    ...(response.origin && {originId: response.origin.id}),
    ...(response.origin && {origin: (response.origin || {}).name}),
    ...(response.market && {marketId: (response.market || {}).id}),
    ...(response.market && {market: (response.market || {}).name}),
    hasNeverBeenSaved: response.reservationNo === undefined,
    ...(response.paymentType && {paymentTypeId: response.paymentType.id}),
    ...(response.paymentType && {paymentTypeName: response.paymentType.name}),
    accountNo: response.accountNo,
    voucherNo: response.voucherNo,
    depositPolicyId: (response.depositPolicy || {}).id,
    depositPolicyOverridden: response.depositPolicyOverridden,
    depositOverridden: response.depositOverridden,
    depositDateOverridden: response.depositDateOverridden,
    depositPolicy: response.depositPolicy,
    memo: response.memo,
    confidentialMemo: response.confidentialMemo,
    itinerary: response.itineraries.map((responseItem, index) => {
      return {
        id: responseItem.id,
        type: responseItem.type,
        startDate: responseItem.arrivalDate,
        endDate: format(addDays(
            parse(responseItem.arrivalDate, 'yyyy-MM-dd', new Date()),
            responseItem.duration), 'yyyy-MM-dd'),
        duration: responseItem.duration,
        propertyName: responseItem.property.name,
        propertyId: responseItem.property.id,
        ratesIncludeTaxes: responseItem.property.ratesIncludeTaxes,
        property: responseItem.property,
        inventoryId: responseItem.inventory.id,
        inventoryName: responseItem.inventory.name,
        inventory: responseItem.inventory,
        amountInclTax: responseItem.amountInclTax,
        amountExclTax: responseItem.amountExclTax,
        rateBreakdown: (responseItem.rateBreakdown || []).map(
            rateBreakdownItem => {
              return {
                effectiveDate: rateBreakdownItem.effectiveDate,
                amountInclTax: rateBreakdownItem.amountInclTax,
                amountExclTax: rateBreakdownItem.amountExclTax,
                rateCode: rateBreakdownItem.rateCode,
                mealPlan: rateBreakdownItem.mealPlan,
                roomRate: rateBreakdownItem.roomRate,
                upToPax: rateBreakdownItem.upToPax,
                singlePP: rateBreakdownItem.singlePP,
                sharingPP: rateBreakdownItem.sharingPP,
                extraAdult: rateBreakdownItem.extraAdult,
                kids1: rateBreakdownItem.kids1,
                kids2: rateBreakdownItem.kids2,
                kids3: rateBreakdownItem.kids3,
                currency: rateBreakdownItem.currency,
                taxes: {
                  amount: rateBreakdownItem.taxes?.amount,
                  breakdown: (rateBreakdownItem.taxes?.breakdown || []).map(
                      taxBreakdown => {
                        return {
                          taxType: taxBreakdown.taxType,
                          amount: taxBreakdown.amount,
                        }
                      })
                },
              }
            }),
        rateCodeId: responseItem.rateCode.id,
        rateCode: responseItem.rateCode.code,
        rateCodeName: responseItem.rateCode.name,
        mealPlanId: responseItem.mealPlan.id,
        mealPlanCode: responseItem.mealPlan.code,
        mealPlanName: responseItem.mealPlan.name,
        currencyId: responseItem.currency.id,
        currencyCode: responseItem.currency.code,
        taxes: responseItem.taxes,
        rateOverridden: responseItem.rateOverridden,
        rateCodeOverridden: responseItem.rateCodeOverridden,
        overrideAvailability: responseItem.overrideAvailability,
        noOfRooms: responseItem.noOfRooms,
        roomBreakdown: responseItem.roomBreakdown.map((room, roomIndex) => {
          return {
            id: room.id,
            noOfAdults: room.noOfAdults,
            kids: (room.kids || []).map(child => {
              return child.age
            }),
            ...(room.roomProfiles && {
              roomProfiles: room.roomProfiles.map(
                  (roomProfile, profileIndex) => {
                    return {
                      profile: convertProfile(roomProfile.profile),
                      ...(roomProfile.specialRequests
                          && {
                            specialRequests: roomProfile.specialRequests.map(
                                sr => ({
                                  id: sr.specialRequest.id,
                                  name: sr.specialRequest.name
                                }))
                          }),
                      ...(roomProfile.additionalProfiles
                          && {
                            additionalProfiles: roomProfile.additionalProfiles.map(
                                ap =>
                                    convertProfile(ap))
                          })
                    }
                  })
            }),
            ...(!room.roomProfiles && {
              roomProfiles: getReservationRoomProfiles(reservation, index,
                  roomIndex)
            }),
            services: (room.services || []).map(service => {
              return {
                id: service.id,
                serviceId: service.serviceDetail.id,
                serviceName: service.serviceDetail.name,
                serviceDate: service.serviceDate,
                qty: service.qty,
                noOfAdults: (service.noOfAdults || 0),
                noOfKids1: (service.noOfKids1 || 0),
                noOfKids2: (service.noOfKids2 || 0),
                noOfKids3: (service.noOfKids3 || 0),
                ...(service.rateCode && {
                  rateCodeId: service.rateCode.id,
                  rateCode: service.rateCode.code,
                  rateCodeName: service.rateCode.name,
                }),
                ...(service.currency && {
                  currencyId: service.currency.id,
                  currencyCode: service.currency.code,
                }),
                fromTime: service.fromTime,
                toTime: service.toTime,
                fromLocation: service.fromLocation,
                toLocation: service.toLocation,
                note: service.note,
                amountInclTax: service.amountInclTax,
                amountExclTax: service.amountExclTax,
                serviceDetail: {
                  chargePerDay: service.serviceDetail.chargePerDay,
                  ratesIncludeTaxes: service.serviceDetail.ratesIncludeTaxes,
                  mandatory: service.serviceDetail.mandatory,
                },
                rateBreakdown: (service.rateBreakdown || []).map(
                    rateBreakdownItem => {
                      return {
                        effectiveDate:
                        rateBreakdownItem.effectiveDate,
                        amountInclTax: rateBreakdownItem.amountInclTax,
                        amountExclTax: rateBreakdownItem.amountExclTax,
                        rateCode: rateBreakdownItem.rateCode,
                        mealPlan: DEFAULT_MEALPLAN,//rateBreakdownItem.mealPlan, Service rates don;t have a mealplan.
                        roomRate: rateBreakdownItem.roomRate,
                        upToPax: rateBreakdownItem.upToPax,
                        singlePP: rateBreakdownItem.singlePP,
                        sharingPP: rateBreakdownItem.sharingPP,
                        extraAdult: rateBreakdownItem.extraAdult,
                        kids1: rateBreakdownItem.kids1,
                        kids2: rateBreakdownItem.kids2,
                        kids3: rateBreakdownItem.kids3,
                        currency: rateBreakdownItem.currency,
                      }
                    }),
                taxes: service.taxes,
                rateCodeOverridden: service.rateCodeOverridden,
                rateOverridden: service.rateOverridden,
                depositPayable: service.depositPayable,
                depositReceived: service.depositReceived,
              }
            }),
          }
        }),
        depositPayable: responseItem.depositPayable,
        depositReceived: responseItem.depositReceived,
        note: responseItem.note,
        ...(responseItem.schedulePeriod
            && {
              schedulePeriod: {
                startLocationName: responseItem.schedulePeriod.startLocationName,
                color: responseItem.schedulePeriod.color
              }
            }),
        pmsConfNumbers: responseItem.pmsConfNumbers,
      }
    }),
    ...(response.payerProfile && {
      payerProfile: {
        ...convertProfile(response.payerProfile),
        validated: reservation.payerProfile?.validated,
        pointsAvailable: reservation.payerProfile?.pointsAvailable,
      }
    }),
    contactName: response.contactName,
    contactEmail: response.contactEmail,
    ...(response.cancelFeesPayable && {
      cancelFeesPayable: response.cancelFeesPayable.map(cancelFee => {
        return {
          calculationType: cancelFee.calculationType,
          calculationTypeName: cancelFee.calculationTypeName,
          cancelDueAmount: cancelFee.cancelDueAmount,
          itineraryId: cancelFee.itineraryId,
          currency: cancelFee.currency,
          ruleDuration: cancelFee.ruleDuration,
          cancellationPolicy: cancelFee.cancellationPolicy,
          calculationPercentage: cancelFee.calculationPercentage,
        }
      })
    }),
    ...(response.cancellationPolicy && {
      cancellationPolicy: {
        id: response.cancellationPolicy.id,
        rules: response.cancellationPolicy.rules.map(rule => {
          return {
            calculationType: rule.calculationType,
            calculationTypeName: rule.calculationTypeName,
            ruleDuration: rule.ruleDuration,
            calculationPercentage: rule.calculationPercentage,
            ruleDueAmounts: rule.ruleDueAmounts.map(ruleDueAmount => {
              return {
                currency: ruleDueAmount.currency,
                amount: ruleDueAmount.amount,
              }
            })
          }
        })
      }
    }),
    depositsPayable: (response.depositsPayable || []).map(depositPayable => {
      return {
        dueDate: depositPayable.dueDate,
        amount: depositPayable.amount,
        currency: depositPayable.currency,
      }
    }),
    depositsPayableBreakdown: (response.depositsPayableBreakdown || []).map(
        breakdown => {
          return {
            depositDueDate: breakdown.depositDueDate,
            calculationType: breakdown.calculationType,
            calculationTypeName: breakdown.calculationTypeName,
            depositDueAmount: breakdown.depositDueAmount,
            itineraryId: breakdown.itineraryId,
            currency: breakdown.currency,
            ruleDuration: breakdown.ruleDuration,
            cancellationPolicy: breakdown.depositPolicy,
            calculationPercentage: breakdown.calculationPercentage,
          }
        }),
    ...(response.depositsReceived && {
      depositsReceived: response.depositsReceived.map(depositReceived => {
        return {
          amount: depositReceived.amount,
          reference: depositReceived.reference,
          currency: depositReceived.currency,
          depositReceivedAllocations: depositReceived.depositReceivedAllocations,
          captureDate: depositReceived.captureDate,
          receivedDate: depositReceived.receivedDate,
          depositPaymentType: depositReceived.depositPaymentType,
        }
      })
    }),
    ...(response.specialRequests
        && {
          specialRequests: response.specialRequests.map(sr => ({
            id: sr.specialRequest.id,
            name: sr.specialRequest.name
          }))
        })
  }
};

export const generateSessionId = (length) => {
  let sessionId = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    sessionId += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return sessionId;
};

export const dateDiff = (startDateString, endDateString) => {
  return differenceInCalendarDays(
      parse(endDateString, 'yyyy-MM-dd', new Date()),
      parse(startDateString, 'yyyy-MM-dd', new Date()));
};

export const calcTotals = (reservation) => {
  let totals = {
    taxInclusive: {},
    taxExclusive: {}
  };

  reservation.itinerary.forEach(item => {
    totals.taxInclusive = calcTotals_(totals.taxInclusive, item, true);
    totals.taxExclusive = calcTotals_(totals.taxExclusive, item, false)
  });

  return totals;
};

const calcTotals_ = (map, item, inclusiveOnly) => {
  if (item.currencyCode) {
    if (!map[item.currencyCode]) {
      map = {
        ...map,
        [item.currencyCode]: {
          taxes: {}
        }
      };
      map[item.currencyCode].accommodationIncl = 0;
      map[item.currencyCode].accommodationExcl = 0;
      map[item.currencyCode].servicesIncl = 0;
      map[item.currencyCode].servicesExcl = 0;
      map[item.currencyCode].totalNettTaxes = 0;
      map[item.currencyCode].taxesTotal = 0;
      map[item.currencyCode].grandTotal = 0;
    }

    // accommodation
    if (inclusiveOnly && item.ratesIncludeTaxes) {
      map[item.currencyCode].accommodationIncl += item.amountInclTax;

      // taxes totals
      calcTaxTotals(map, item);

      //grand total
      map[item.currencyCode].grandTotal += item.amountInclTax;
    } else if (!inclusiveOnly && !item.ratesIncludeTaxes) {
      map[item.currencyCode].accommodationExcl += item.amountExclTax;

      // taxes totals
      calcTaxTotals(map, item);

      //grand total
      map[item.currencyCode].grandTotal += item.amountInclTax;
    }

    // services
    item.roomBreakdown.forEach(room => {
      (room.services || []).forEach(service => {
        if (inclusiveOnly && service.serviceDetail.ratesIncludeTaxes) {
          map[service.currencyCode].servicesIncl += service.amountInclTax;

          // taxes totals
          calcTaxTotals(map, service);

          //grand total
          map[service.currencyCode].grandTotal += service.amountInclTax;

        } else if (!inclusiveOnly && !service.serviceDetail.ratesIncludeTaxes) {
          map[service.currencyCode].servicesExcl += service.amountExclTax;

          // taxes totals
          calcTaxTotals(map, service);

          //grand total
          map[service.currencyCode].grandTotal += service.amountInclTax;
        }
      })
    });
  }
  return map;
};

export const calcGrandTotal = (totals, currencyCode) => {
  return totals.taxInclusive[currencyCode].grandTotal
    + totals.taxExclusive[currencyCode].grandTotal
}

  export const calcGrandTotalExcl = (totals, currencyCode) => {
  return (totals.taxInclusive[currencyCode].grandTotal - totals.taxInclusive[currencyCode].taxesTotal)
    + totals.taxExclusive[currencyCode].grandTotal
}


export const calcTaxTotals = (map, taxableItem) => {
  // taxes totals
  if (taxableItem.taxes) {
    map[taxableItem.currencyCode].taxesTotal += taxableItem.taxes.amount;

    map[taxableItem.currencyCode].totalNettTaxes += taxableItem.amountExclTax;

    // taxType totals
    (taxableItem.taxes.breakdown || []).forEach(breakdown => {
      if (!map[taxableItem.currencyCode].taxes[breakdown.taxType.name]) {
        map[taxableItem.currencyCode].taxes = {
          ...map[taxableItem.currencyCode].taxes,
          [breakdown.taxType.name]: 0
        }
      }
      map[taxableItem.currencyCode].taxes[breakdown.taxType.name] += breakdown.amount;
    });
  }
};

export const calcDepositSummary = (reservation) => {
  let depositSummary = {};

  if(reservation.depositsPayable) {
    reservation.depositsPayable.forEach(depositPayable => {

      if (!depositSummary[depositPayable.currency.code]) {
        depositSummary = {
          ...depositSummary,
          [depositPayable.currency.code]: {
            depositsPayable : {},
            totalDepositDue: 0,
            totalDepositReceived: 0,
          }
        }
      }

      if (!depositSummary[depositPayable.currency.code].depositsPayable[depositPayable.dueDate]) {
        depositSummary[depositPayable.currency.code].depositsPayable = {
          ...depositSummary[depositPayable.currency.code].depositsPayable,
          [depositPayable.dueDate]: {
            currencyId: depositPayable.currency.id,
            amount: 0,
          }
        }
      }

      depositSummary[depositPayable.currency.code].depositsPayable[depositPayable.dueDate].amount += depositPayable.amount;
      depositSummary[depositPayable.currency.code].depositsPayable[depositPayable.dueDate].currencyId = depositPayable.currency.id;

      depositSummary[depositPayable.currency.code].totalDepositDue += depositPayable.amount;
      depositSummary[depositPayable.currency.code].currencyId = depositPayable.currency.id;
    });
  }

  if(reservation.depositsReceived) {
    reservation.depositsReceived.forEach(depositReceived => {
      if (!depositSummary[depositReceived.currency.code]) {
        depositSummary = {
          ...depositSummary,
          [depositReceived.currency.code]: {
            depositsPayable : {},
            totalDepositDue: 0,
            totalDepositReceived: 0,
          }
        }
      }

      depositSummary[depositReceived.currency.code].totalDepositReceived += depositReceived.amount;
    })
  }

  return depositSummary;
};

export const hasDepositDue = (depositSummary) => {
  return (depositSummary && Object.keys(depositSummary).length > 0)
}

export const calcAmountPayable = (reservation) => {
  const {depositsPayable, depositsReceived} = reservation;
  if (depositsPayable && depositsPayable.length) {
    let amountPayable = {};
    const currencies = getCurrenciesPayable(depositsPayable);
    const firstCurrency = currencies[0]; // we only support single currency bookings

    currencies.forEach(currency => {
      const totalReceived = calcTotalReceived(depositsReceived, currency.code);
      const totalDue = calcTotalDue(depositsPayable, currency.code)
      const payNow = (totalDue - totalReceived);

      if(payNow > 0) {
        amountPayable[currency.code] = {
          dueDate: new Date(),
          amount: payNow < 0 ? 0 : payNow,
          currencyId: currency.id,
          currencyCode: currency.code,
        }
      }
    })

    return amountPayable[firstCurrency.code];
  }
}

export const calcNextAmountPayable = (reservation) => {
  const {depositsPayable, depositsReceived} = reservation;
  if (depositsPayable && depositsPayable.length) {
    let nextAmountPayable = {};
    const currencies = getCurrenciesPayable(depositsPayable);
    const firstCurrency = currencies[0]; // we only support single currency bookings

    currencies.forEach(currency => {
      const totalReceived = calcTotalReceived(depositsReceived, currency.code);

      const depositsPayableSorted = getDepositsPayableSorted(depositsPayable, currency.code);
      depositsPayableSorted.forEach(depositPayable => {
        const dueDate = parse(depositPayable.dueDate, 'yyyy-MM-dd', new Date());
        const totalDue = calcTotalDue(depositsPayable, currency.code, dueDate);

        if(totalReceived < totalDue && !nextAmountPayable[currency.code]) {
          nextAmountPayable[currency.code] = {
            dueDate: dueDate,
            amount: totalDue - totalReceived,
            currencyId: currency.id,
            currencyCode: currency.code,
          }
        }

      });
    })

    return nextAmountPayable[firstCurrency.code];
  }
}

export const getDepositsPayableSorted = (depositsPayable, currencyCode) => {
  const filtered = _.filter(depositsPayable, (d) => {
    return d.currency.code === currencyCode;
  });

  return _.sortBy(filtered, (d) => {
    return  parse(d.dueDate, 'yyyy-MM-dd', new Date());
  })
}

export const getDepositsReceivedSorted = (depositsReceived) => {
  return _.sortBy(depositsReceived, (d) => {
    return parse(d.captureDate, 'yyyy-MM-dd', new Date());
  })
}

export const isPayableToday = (amountPayable) => {
  return isToday(amountPayable.dueDate) && amountPayable.amount > 0
}

export const getCurrenciesPayable = depositsPayable => {
  return _.uniqWith(depositsPayable, (a, b) => {
    return a.currency.code === b.currency.code;
  }).map(
    d => (d.currency))
}

export const getReservationCurrencies = reservation => {
  return _.uniqWith(getItinerary(reservation), (a, b) => {
    return a.currencyCode === b.currencyCode;
  }).map(
    d => ({
      code: d.currencyCode,
      name: d.currency,
      id: d.currencyId,
    }))
}

export const calcTotalDue = (depositsPayable, currencyCode,
  date = new Date()) => {
  return _.sumBy(_.filter(depositsPayable, (d) => {
    const dueDate = parse(d.dueDate, 'yyyy-MM-dd', new Date());
    return d.currency.code === currencyCode
      && (isEqual(dueDate, date) || isBefore(dueDate, date))
  }), 'amount');
}

export const calcTotalReceived = (depositsReceived, currencyCode) => {
  return _.sumBy(_.filter(depositsReceived, (d) => {
    return d.currency.code === currencyCode;
  }), 'amount');
}

export const storeReservation = async (reservation) => {
  sessionStorage.setItem('bn_reservation', JSON.stringify(reservation));
};

export const removeReservation = async () => {
  let reservation = JSON.parse(sessionStorage.getItem('bn_reservation'));
  if (reservation) {
    sessionStorage.removeItem('bn_reservation');
  }
};

export const getReservation = () => {

  if(sessionStorage.getItem('bn_reservation')) {
    let reservation = JSON.parse(sessionStorage.getItem('bn_reservation'));
      return reservation;
  }

  return undefined;
};

export const buildTempHoldReleaseRQ = (sessionId) => {
  return buildRestEnvelope(
      {
        SessionID: sessionId
      },
      {
        dataSource: "TempHold",
        operationType: "custom",
        operationId: "spTempHold_ReleaseSession",
      }, 0, 75);
};

export const getFirstRoomProfile = (room) => {
  return ((room.roomProfiles && room.roomProfiles.length > 0)
      ? room.roomProfiles[0]
      : undefined);
};

export const getArrivalDate = (itinerary) => {
  return getArrivingItem(itinerary).startDate;
};

export const getArrivingItem = (itinerary) => {
  let arrivingItem = undefined;
  itinerary.forEach(item => {

    const startDate = parseDate(item.startDate);

    if (arrivingItem === undefined || isBefore(startDate,
        arrivingItem.startDate)) {
      arrivingItem = item
    }
  });
  return arrivingItem;
};

export const getDepartDate = (reservation) => {
  let departDate = undefined;
  if(reservation) {
    (reservation.itinerary||[]).forEach(item => {
      const endDate = parseDate(item.endDate);

      if (departDate === undefined || isAfter(endDate, departDate)) {
        departDate = endDate;
      }
    });
  }
  return departDate;
};

export const getNoOfAdults = roomBreakdown => {
  let adults = 0;
  roomBreakdown.forEach((room, index) => {
    adults = adults + room.noOfAdults;
  });

  return adults;
};

export const getNoOfChildren = roomBreakdown => {
  let children = 0;
  roomBreakdown.forEach((room, index) => {
    // children = children + room.noOfKids1 + room.noOfKids2 + room.noOfKids3;
    children += (room.kids||[]).length
  });

  return children;
};

export const parseDate = (dateString, pattern = 'yyyy-MM-dd') => {
  return parse(dateString, pattern, new Date());
}

export const getStatusName = (status) => {
  switch (status) {
    case ReservationStatus.GUARANTEED.id :
      return ReservationStatus.GUARANTEED.label;
    case ReservationStatus.PROVISIONAL.id :
      return ReservationStatus.PROVISIONAL.label;
    case ReservationStatus.CANCELLED.id :
      return ReservationStatus.CANCELLED.label;
    case ReservationStatus.HOLD.id :
      return ReservationStatus.HOLD.label;
    case ReservationStatus.WAIT_LIST.id :
      return ReservationStatus.WAIT_LIST.label;
    default :
      return ''
  }
};

export const hasRequiredFields = (payerProfile) => {
  return payerProfile?.surname;
};

export const buildGetPayNowRQ = (reservation, amount, currencyId, redirectUrl) => {
  return {
    amount,
    currencyId,
    reservation: {
      id: reservation.id,
      reservationNo: reservation.reservationNo,
      status: reservation.status,
      resType: reservation.resType,
      channelId: reservation.channelId,
      agentId: reservation.agentId,
      companyId: reservation.companyId,
      wholesalerId: reservation.wholesalerId,
      allocationId: reservation.allocationId,
      contactId: reservation.contactId,
      sourceId: reservation.sourceId,
      originId: reservation.originId,
      marketId: reservation.marketId,
      ...(reservation.channelConfNo !== ''
        && {channelConfirmationNo: reservation.channelConfNo}),
      accountNo: reservation.accountNo,
      voucherNo: reservation.voucherNo,
      itineraries: reservation.itinerary.map(item => {
        return {
          id: item.id,
          type: item.type,
          propertyId: item.propertyId,
          rateCodeId: item.rateCodeId,
          inventoryId: item.inventoryId,
          arrivalDate: item.startDate,
          duration: dateDiff(item.startDate, item.endDate),
          noOfRooms: item.roomBreakdown.length,
          roomBreakdown: item.roomBreakdown.map(room => {
            return {
              id: room.id,
              noOfAdults: room.noOfAdults,
              kids: room.kids,
              noOfRooms: 1,
            }
          }),
        }
      }),
      payerProfile: copyObjectExcept(reservation.payerProfile, ['loyaltyTier', 'title']),
      contactName: reservation.contactName,
      contactEmail: reservation.contactEmail,
    },
    redirectUrl,
  }
}

export const canEdit = (reservation, agent) => {
  return !isPast(getDepartDate(reservation))
    && (agent ? agent.agentId === reservation.agentId : true);
}

export const isPayNow = (amountPayable, agent) => {
  if(isOnAccount(agent)) {
    return false;
  }

  return (amountPayable && isPayableToday(amountPayable))
}

export const isOnAccount = agent => {
  return agent && agent.paymentTypeId === 1;
}

export const memoContains = (memo, text) => {
  return memo.includes(text);
}

export const appendToMemo = (memo, textToAppend) => {
  return memo + `\n\r${textToAppend}\n\r`
}

export const dateAdd = (date, days) => {
  return format(addDays(
    parse(date, 'yyyy-MM-dd', new Date()),
    days), 'yyyy-MM-dd');
}

export const allowCurrency = (reservation, currencyId) => {
  const arrivingItem = getArrivingItem(getItinerary(reservation));

  if(arrivingItem) {
    return arrivingItem.currencyId === currencyId;
  }

  return true
}

export const calcPointsToRedeemForItem = (reservation, pointsToRedeem, item) => {
  return (pointsToRedeem / getItinerary(reservation).length);
}

export const calcDepositPolicyDescription = (depositPolicy, criteria) => {

  const {rules} = depositPolicy;

  const groupByDueDate = _.groupBy(rules, (rule) => {
    const today = parseDate(format(new Date(), 'yyyy-MM-dd'));

    const {ruleType} = rule;

    const ruleDueDate = ruleType === POLICY_TYPE_FROM_ARRIVAL ? addDays(
      parseDate(criteria.startDate), -Math.abs(
        rule.ruleDuration)) : addDays(today, rule.ruleDuration);

    if (!isAfter(ruleDueDate, today)) {
      return format(today, 'yyyy-MM-dd');
    }

    return format(ruleDueDate, 'yyyy-MM-dd');
  });

  let descriptions = [];
  for (const ruleDueDate of Object.keys(groupByDueDate).toSorted()) {
    descriptions.push(...groupByDueDate[ruleDueDate].map(rule => {

        if (rule.calculationType === POLICY_RULE_CALC_PERCENT) {

          const percentage = _.sumBy(groupByDueDate[ruleDueDate],
            (rule) => Number.parseFloat(rule.calculationPercentage));

          const dueDate = parseDate(ruleDueDate);
          const today = parseDate(format(new Date(), 'yyyy-MM-dd'));

          if (!isAfter(dueDate, today)) {
            return `${percentage}% to be paid immediately.`
          } else {
            return `${percentage}% due by ${dueDate.toLocaleDateString()}.`
          }

        } else { // 1st night
          if (rule.type === POLICY_TYPE_FROM_ARRIVAL) {
            return `1st night rate, payable ${rule.ruleDuration} days before arrival.`
          } else {
            return "1st night's rate, to be paid immediately."
          }
        }

      })
    )
  }

  return _.uniq(descriptions);

}

export const calcCancelPolicyDescription = (cancellationPolicy, criteria) => {
  const {rules} = cancellationPolicy;

  const groupByDueDate = _.groupBy(rules, (rule) => {
    const today = parseDate(format(new Date(), 'yyyy-MM-dd'));

    const ruleDueDate = addDays(parseDate(criteria.startDate), -Math.abs(
        rule.ruleDuration));

    if (!isAfter(ruleDueDate, today)) {
      return format(today, 'yyyy-MM-dd');
    }

    return format(ruleDueDate, 'yyyy-MM-dd');
  });

  // console.log('groupByDueDate', groupByDueDate)

  let mostRestrictiveRules = [];
  for (const ruleDueDate of Object.keys(groupByDueDate).toSorted()) {

    const highestPercentageRule = _.maxBy(groupByDueDate[ruleDueDate],
        r => Number.parseFloat(r.calculationPercentage));

    // console.log('highestPercentageRule', highestPercentageRule)

    if(highestPercentageRule && highestPercentageRule.calculationType === POLICY_RULE_CALC_PERCENT) {
      mostRestrictiveRules.push({ruleDueDate, rule : highestPercentageRule})
    }

    const firstNightRule = _.find(groupByDueDate[ruleDueDate], rule => rule.calculationType === POLICY_RULE_CALC_1STNIGHT);

    // console.log('firstNightRule', firstNightRule);

    if(firstNightRule && !mostRestrictiveRules.find(r => r.rule.calculationType === POLICY_RULE_CALC_1STNIGHT)) {
      mostRestrictiveRules.push({ruleDueDate, rule: firstNightRule})
    }
  }

  // console.log('mostRetsrictiveRules', mostRestrictiveRules)

  let descriptions = [];
  for(const mostRestrictiveRule of mostRestrictiveRules) {

    const {ruleDueDate, rule} = mostRestrictiveRule;

    if (rule.calculationType === POLICY_RULE_CALC_PERCENT) {

      const percentage = Number.parseFloat(rule.calculationPercentage)

      descriptions.push(`${percentage}% if cancelled on or after ${parseDate(ruleDueDate).toLocaleDateString()}`)
    }
    else {
      descriptions.push(`1st night accommodation, if cancelled on or after ${parseDate(ruleDueDate).toLocaleDateString()}`)
    }
  }

  return descriptions;
}

export const firstProperty = (reservation) => {
  return (getArrivingItem(getItinerary(reservation))||{}).property;
}

export const buildCalcAmountRQ = (item, agentId) => {
  return {
    ...(agentId && {agentId: agentId}),
    itineraries: [{
      propertyId: item.propertyId,
      rateCodeId: item.rateCodeId,
      inventoryId: item.inventoryId,
      arrivalDate: item.startDate,
      duration: item.duration,
      noOfRooms: item.roomBreakdown.length,
      roomBreakdown: item.roomBreakdown.map(room => {
        return {
          noOfAdults: room.noOfAdults,
          kids: room.kids,
          noOfRooms: 1,
          id: room.id
        }
      }),
      rateBreakdown: item.rateBreakdown.map(rateBreakDownItem => {
        return {
          effectiveDate: rateBreakDownItem.effectiveDate,
          rateCodeId: rateBreakDownItem.rateCode.id,
          mealPlanId: rateBreakDownItem.mealPlan.id,
          roomRate: rateBreakDownItem.roomRate,
          upToPax: rateBreakDownItem.upToPax,
          singlePP: rateBreakDownItem.singlePP,
          sharingPP: rateBreakDownItem.sharingPP,
          extraAdult: rateBreakDownItem.extraAdult,
          kids1: rateBreakDownItem.kids1,
          kids2: rateBreakDownItem.kids2,
          kids3: rateBreakDownItem.kids3
        }
      }),
      ...(item.rateCodeOverridden === true
        && {rateCodeId: item.rateCodeId}),
      ...((item.rateOverridden === true || item.rateCodeOverridden
          === true)
        && {currencyId: item.currencyId}),
      rateOverridden: item.rateOverridden,
      rateCodeOverridden: item.rateCodeOverridden,
    }],
  };
}

export const createSearchByPaxRoomBreakdown = (roomBreakdown) => {
  const noOfAdults = _.sumBy(roomBreakdown, 'noOfAdults')

  let kids = [];
  for (let room of roomBreakdown) {
    kids = kids.concat(room.kids)
  }

  return {
    noOfAdults,
    kids,
  }
}

export const buildCancelRQ = (reservation, messageType, cancellationReasonId,
  cancellationDescription, cancellationPolicyId, overriddenCancelFeesPayable) => {
  return {
    messageType: messageType,
    reservationId: reservation.reservationNo.toString(),
    id: reservation.id,
    cancellationReasonId,
    cancellationDescription,
    cancellationPolicyId,
    ...(overriddenCancelFeesPayable && {
      cancelFeesPayable: overriddenCancelFeesPayable.map(cancelFee => {
        return {
          itineraryId: cancelFee.itineraryId,
          calculationPercentage: cancelFee.calculationPercentage,
        }
      })
    }),
  }
}