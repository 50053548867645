import FileUpload from "./FileUpload";
import useStorageRefs from "../hooks/useStorageRefs";
import {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "./Spinner";
import useFileDelete from "../hooks/useFileDelete";

export default function Attachments({path, onSuccess, viewOnly}) {

  const [reFetch, setReFetch] = useState(0);

  const {storageRefs, busy, error} = useStorageRefs(path, reFetch);
  const {deleteFile, busy: deleteBusy, error: deleteError} = useFileDelete();

  const onUploadSuccess = () => {
    setReFetch(reFetch+1)

    onSuccess()
  }

  const handleDelete = (filename) => {
    deleteFile(path, filename, onDeleteSuccess)
  }

  const onDeleteSuccess = () => {
    setReFetch(reFetch+1)
  }

  return (
    <div className={'bn-attachments-container'}>
      {(!busy && storageRefs.length > 0) &&
        <div className={'bn-attachment-items'}>
          {storageRefs.map(storageRef => (
            <div className={'bn-file-item'}>
              <div className={'bn-file-name'}>
                <span>{storageRef.filename}</span>
                {(!deleteBusy &&!viewOnly) && <div onClick={() => handleDelete(storageRef.filename)}
                      className={'bn-file-remove'}>
                  <FontAwesomeIcon icon="fa-solid fa-trash"/>
                </div>}
                {deleteBusy && <div><Spinner size={"16px"}/></div>}
              </div>
              <div className={'bn-file-download'}>
                <a target={'_blank'} href={storageRef.downloadURL}>Download</a>
              </div>
            </div>
          ))}
        {error && <div className={'bn-error'}>{error}</div>}
      </div>}
      {(!busy && storageRefs.length === 0) && <p>None</p>}
      {busy && <div><Spinner size={"48px"}/></div>}
      {!viewOnly && <FileUpload disabled={busy} path={path} onSuccess={onUploadSuccess}/>}
    </div>
  )
}