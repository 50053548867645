import React, {useCallback, useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom'
import {
  createHashRouter,
  RouterProvider,
} from "react-router-dom";
import {SearchResults} from "./booknow/results/components/SearchResults";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCircleMinus,
  faCirclePlus,
  faMagnifyingGlass,
  faArrowCircleLeft,
  faArrowLeft,
  faArrowRight,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faRectangleXmark,
  faXmark,
  faPlus,
  faMinus,
  faHotel,
  faCircleExclamation,
  faCheck,
  faUser,
  faCloudArrowUp,
  faTrash,
  faBed,

  // features
  faBus,
  faChampagneGlasses,
  faChildren,
  faUmbrellaBeach,
  faWheelchair,
  faPaw,
  faGolfBall,
  faPersonHiking,
  faHorse,
  faGlobe,
  faWaterLadder,
  faUtensils,
  faBellConcierge,
  faSatelliteDish,
  faSpa,
  faPersonBiking,
  faBinoculars,
  faSailboat,
  faMugSaucer,
  faWifi,
  faKitchenSet,
  faTv,
  faSquareParking,
  faDumbbell,
  faVanShuttle,
  faLeaf,
  faBanSmoking,
  faBriefcase,
  faCheckSquare,
  faCartFlatbedSuitcase,
  faShower,
  faShip,
  faPencil,
} from '@fortawesome/free-solid-svg-icons'

import {
  faArrowDownToLine,

  // features
  faScreenUsers,
  faFishingRod,
  faSlotMachine,
  faRacquet,
  faTennisBall,
  faPawClaws,
  faPancakes,
  faLightbulbOn,
  faMaskSnorkel,
} from '@fortawesome/pro-solid-svg-icons'

import PageHeader from "./components/PageHeader";
import HotelDetail from "./booknow/results/components/HotelDetail";
import ErrorPage from "./components/ErrorPage";
import GuestDetail from "./booknow/session/components/GuestDetail";
import {useDispatch, useSelector} from "react-redux";
import {
  restoreReservation,
} from "./booknow/session/sessionSlice";
import {
  getDefaultLoyaltyProgram,
  getSite, restoreAgentLogin, selectApp,
  setCustomer,
  setCustomerBusy, setRateCodes, setScriptOptions,
} from "./booknow/app/appSlice";
import {getPropertySuggestions} from "./booknow/propertySuggestion/propertySuggestionSlice";
import {selectResults, setPickingStay} from "./booknow/results/resultsSlice";
import {getCustomerData, getRateCodesQuery} from "./api/firebaseApi";
import {initializeApp} from "firebase/app";
import { getAuth, signInAnonymously, signInWithCustomToken } from "firebase/auth";
import Confirmation from "./booknow/session/components/Confirmation";
import AgentInvite from "./booknow/signup/AgentInvite";
import {getFirestore} from "firebase/firestore";
import {getInstanceName} from "./booknow/Util";
import useFirestoreGetDocs from "./hooks/useFirestoreGetDocs";
import IdleTimer from "./components/IdleTimer";
import AgentLogin from "./booknow/app/components/AgentLogin";
import AgentProfilePage from "./booknow/corporate/AgentProfilePage";
import GuestProfilePage from "./booknow/corporate/GuestProfilePage";
import useRestFetch, {FETCH} from "./hooks/useRestFetch";
import {initializeAnalytics} from "./api/googleTag";

// firebase config
let firebaseConfig = {
  apiKey: "AIzaSyCGJ436UR3dbXGJOAlY6uCOs5vDuiNb85Q",
  authDomain: "hti-customer-app.firebaseapp.com",
  databaseURL: "https://hti-customer-app.firebaseio.com",
  storageBucket: "gs://hti-customer-app.appspot.com",
  projectId: "hti-customer-app",
};

// firebase config CRS
let firebaseConfigCRS = {
  apiKey: "AIzaSyC6xNg8wgYY9jjJ5dRES9Lb3E1NmK77kLA",
  authDomain: "hti-nebulacrs.firebaseapp.com",
  databaseURL: "https://hti-nebulacrs.firebaseio.com",
  projectId: "hti-nebulacrs",
};

// initialize firebase
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAppCRS  = initializeApp(firebaseConfigCRS, 'crs');

const auth = getAuth(firebaseApp);
const authCRS = getAuth(firebaseAppCRS);
export const db = getFirestore(firebaseApp);
export const dbCrs = getFirestore(firebaseAppCRS);

function AppLayout({siteId,
  customer_key,
  autoSearch,
  singleProperty,
  connectionCode}) {

  const location = useLocation();

  const {site, agent, busy, pickingStay} = useSelector(state => ({
    site: selectApp(state).site,
    agent: selectApp(state).agent,
    busy: selectApp(state).busy,
    pickingStay: selectResults(state).pickingStay,
  }))

  const dispatch = useDispatch();
  const setPickingStay_ = useCallback((pickingStay) => dispatch(setPickingStay({pickingStay})),[dispatch])

  const [offset, setOffset] = React.useState(0);

  const {fetchData: getRateCodes} = useFirestoreGetDocs();
  const {fetch: fetchCustomToken} = useRestFetch();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    if(location.pathname.indexOf('/confirmation') === -1
      && queryParams.get('status') != null) {

      const urlStr = window.location.origin+window.location.hash;
      const url = new URL(urlStr);

      window.history.replaceState({}, "", url);
    }

  }, [location]);


  const setScroll = () => {
    const {scrollY} = window;
    setOffset(scrollY);

    // if(pickingStay) {
    //   setPickingStay_(false);
    // }
  }

  React.useEffect(() => {
    window.addEventListener("scroll", setScroll);
    return () => {
      window.removeEventListener("scroll", setScroll);
    };
  }, [pickingStay]);

  const fetchData = useCallback((customer, token) => {
    Promise.all(
      [
        dispatch(getSite({siteId, customer, token})),
        dispatch(getPropertySuggestions({siteId, customer, token})),
        dispatch(getDefaultLoyaltyProgram({customer, token}))
      ]).then(() => {
      getRateCodes(getRateCodesQuery(getInstanceName(customer.api_url), 1),
        (docs) => {
          dispatch(setRateCodes(docs))
        });

      fetchCustomToken({}, 'User', 'customToken', FETCH, customer, token)
      .then(data => {
        if (data) {
          const {customToken} = data[0];

          signInWithCustomToken(authCRS, customToken).catch(error => {
            console.log(
              'firebase.auth() signInWithCustomToken() error: ' + error)
          });
        }
      })

    }).catch((e) => {
      console.log('fetchData() failed', e)
    })
  }, [siteId, dispatch]);

  useEffect(() => {

    signInAnonymously(auth).catch(error => {
      console.log('firebase.auth() error: ' + error)
    });

    dispatch(setCustomerBusy());

    getCustomerData(customer_key, firebaseApp, auth, (customer, token) => {
      dispatch(setCustomer(
        {customer, token, instance: getInstanceName(customer.api_url)}))
      fetchData(customer, token);
    })

    dispatch(setScriptOptions({singleProperty, connectionCode}))

  }, [customer_key, singleProperty]);

  useEffect(() => {

    if(site) {
      initializeAnalytics(site);
    }

  }, [site]);

  return (
      <React.Fragment>
        {(site || busy) && <PageHeader overContent={offset > 0} siteId={siteId} autoSearch={autoSearch} />}
        {(site || busy) && <div className={"bn-page-container"}>
          <div className={"bn-page-container-scroll-offset"}
               id="bn-page-container-scroll-offset"
               style={{position: 'absolute', top: '-165px', left: 0}}/>
          <Outlet/>
          <AgentLogin open={(site?.agentLoginRequired && !agent)} onOpenChange={() => {}}/>
        </div>}
        {(!site && !busy) && <div className={'bn-error-message'}>Site not loaded. Please contact Support</div>}
      </React.Fragment>
  )
}

function App({siteId,
  customer_key,
  autoSearch,
  singleProperty,
  connectionCode}) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restoreReservation());
    dispatch(restoreAgentLogin())
  }, []);

  library.add(faCircleMinus, faCirclePlus, faMagnifyingGlass, faArrowCircleLeft,
    faArrowLeft, faArrowRight, faAngleLeft, faAngleRight, faRectangleXmark,
    faXmark, faCloudArrowUp, faTrash, faAngleUp, faAngleDown, faBed,
    faArrowDownToLine, faPencil,

    // features
    faBus,
    faChampagneGlasses,
    faChildren,
    faUmbrellaBeach,
    faWheelchair,
    faMugSaucer,
    faPersonHiking,
    faGolfBall,
    faHorse,
    faGlobe,
    faWaterLadder,
    faUtensils,
    faBellConcierge,
    faSatelliteDish,
    faWifi,
    faSpa,
    faPersonBiking,
    faBinoculars,
    faSailboat,
    faKitchenSet,
    faPaw,
    faTv,
    faSquareParking,
    faPlus,
    faMinus,
    faHotel,
    faCircleExclamation,
    faCheck,
    faUser,
    faDumbbell,
    faVanShuttle,
    faLeaf,
    faBanSmoking,
    faSlotMachine,
    faBriefcase,
    faCheckSquare,
    faCartFlatbedSuitcase,
    faShower,
    faShip,

    // pro icons
    faScreenUsers,
    faFishingRod,
    faRacquet,
    faTennisBall,
    faPawClaws,
    faPancakes,
    faLightbulbOn,
    faMaskSnorkel,
    );

  const router = createHashRouter([
    {
      path: "/",
      element: <AppLayout siteId={siteId}
                          customer_key={customer_key}
                          autoSearch={autoSearch}
                          singleProperty={singleProperty}
                          connectionCode={connectionCode}
      />,
      errorElement : <ErrorPage/>,
      children: [
        {
          element: <SearchResults/>,
          index: true
        },
        {
          path: "/hotel/:id",
          element: <HotelDetail/>,
        },
        {
          path: "/guestdetails",
          element: <GuestDetail/>,
        },
        {
          path: "/confirmation/:reservationNo/:email",
          element: <Confirmation/>,
        },
        {
          path: "/contactinvite/:contactId",
          element: <AgentInvite/>,
        },
        {
          path: "/agentprofile",
          element: <AgentProfilePage/>,
        },
        {
          path: "/guestprofile",
          element: <GuestProfilePage/>,
        },
        {
          path: "*",
          element : <ErrorPage/>,
        },
      ]
    },
  ],);

  return (
      <React.Fragment>
        <div className={"booknow-container"} id={"booknow-container"}>
          <RouterProvider router={router}/>
        </div>

        <IdleTimer/>

      </React.Fragment>
  );
}

export default App;
