import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {client, DIRECT_AVAIL, getApiUrl} from "../../api/client";
import {buildAvailRQ} from "./resultsSlice";
import {selectApp} from "../app/appSlice";

const alternateResultsSlice = createSlice({
  name: 'alternateResults',
  initialState: {
    criteria: undefined,
    properties: [],
    hasResults: false,
    busy: false,
  },
  reducers: {

  },
  extraReducers(builder) {
    builder.addCase(getAlternateResults.pending, (state, action) => {
      state.busy = true;
      state.error  = '';
      state.hasResults = false;
      state.criteria = Object.assign({}, action.meta.arg);
    })
    .addCase(getAlternateResults.fulfilled, (state, action) => {
      const data = action.payload;
      state.busy = false;

      // console.log('alternateResults/fetch response:', JSON.stringify(data))

      if (data.properties && data.properties.length > 0) {
        const property = data.properties[0];
        const {id, inventory} = property;
        state.properties = {
          [property.id]: {
            id, inventory: inventory.map(i => {
              return {
                id: i.id, rates: i.rates.filter(r => r.alternateRate === true)
              }
            })
          }
        };
        state.hasResults = true;
      }
      else {
        state.properties = {};
        state.hasResults = false;
      }

    }).addCase(getAlternateResults.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message
    })
  },
});

export const selectAlternateResults = state => state.alternateResults;

export const getAlternateResults = createAsyncThunk('alternateResults/fetch',
    async (criteria, {getState}) => {
      const {customer, token} = selectApp(getState())

      return await client.post(getApiUrl(customer, DIRECT_AVAIL), buildAvailRQ(criteria), token)
    });

export default alternateResultsSlice.reducer