import _ from "lodash";

export const getBestRate = (property) => {
  let bar = Number.MAX_SAFE_INTEGER;
  let currency = {};

  let hasRates = false;
  property.inventory.forEach(inventory => {
    inventory.rates.forEach(rate => {
      if ((rate.total * getCurrencyRate(rate.currency)) < (bar
          * getCurrencyRate(currency))) {
        hasRates = true;
        bar = rate.total;
        currency = rate.currency;
      }
    })
  });

  if(hasRates) {
    return {
      bar, currency
    }
  }
}


export const sortResults = (a, b) => {

  const bestRateA = getBestRate(a);
  const bestRateB = getBestRate(b);

  if(bestRateA === undefined) {
    return 1
  }

  if(bestRateB === undefined) {
    return -1
  }

  if ((Number.parseFloat(bestRateA.bar) * bestRateA.currency.rate)
      < (Number.parseFloat(
          bestRateB.bar) * bestRateB.currency.rate)) {
    return -1
  } else if ((Number.parseFloat(bestRateA.bar) * bestRateA.currency.rate)
      > (Number.parseFloat(
          bestRateB.bar) * bestRateB.currency.rate)) {
    return 1
  }

  return 0;
}

const getCurrencyRate = (currency) => {
  return (currency.rate || 1);
}

export const hasContractedRates = (property) => {
  let isContractedRate = false;

  property.inventory.forEach(inventory => {
    inventory.rates.forEach(rate => {
      rate.rateBreakdown.forEach(breakdown => {

        if (!isContractedRate &&
          breakdown.rateCode.rateCodeType.id === 3) {
          isContractedRate = true;
        }

      })
    })
  })

  return isContractedRate;
}

const sortByWeight = (property) => {
  return hasContractedRates(property) ? 0 : 1 // contratced first
}

export const sortResults_ = (properties) => {
  return _.sortBy(properties.slice().sort(sortResults), sortByWeight);
}

export const getBestRateForInventory = (inventory) => {

  let bar = Number.MAX_SAFE_INTEGER;
  let currency = {};
  let hasRates = false;

  inventory.rates.forEach(rate => {
    if ((rate.total * getCurrencyRate(rate.currency)) < (bar
      * getCurrencyRate(currency))) {
      hasRates = true;
      bar = rate.total;
      currency = rate.currency;
    }
  })

  if(hasRates) {
    return {
      bar, currency
    }
  }

}