import React, {useState} from "react";
import classNames from "classnames";
import TextInputItem from "../../../components/TextInputItem";
import {Spinner} from "../../../components/Spinner";
import {useDispatch, useSelector} from "react-redux";
import {queuePswForgetEmail, selectApp} from "../appSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default React.memo(({onEmailSent, onCancelClick}) => {

  const {forgetPswBusy} = useSelector(
      state => ({
        forgetPswBusy: selectApp(state).forgetPswBusy,
      }));

  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);

  const dispatch = useDispatch();
  const queuePswForgetEmail_ = React.useCallback(
      (email) => dispatch(queuePswForgetEmail({email})),
      [dispatch]);

  const handleForgetPassword = () => {
    queuePswForgetEmail_(email).then(() => {
      setEmailSent(true)
    })
  }

  return (
      <div className={"bn-forget-password-container"}>
        {!emailSent && <p>Please enter the email associated with your profile.</p>}
        {emailSent && <div className={"bn-success-message"}>
          <FontAwesomeIcon icon="fa-solid fa-check"/>
          Success! If a profile is linked to the email address you provided, you should receive an e-mail.
        </div>}
        {emailSent && <p>
          Please click <span onClick={() => onEmailSent()}>here</span> to login.
        </p>}
        {!emailSent && <div className={classNames("bn-inputs-group")}>
          <TextInputItem className={"bn-group bn-input-item"}
                         type={"text"}
                         id={"email"}
                         label={"E-mail"}
                         placeholder={"E-mail"}
                         mandatory={true}
                         maxLength={100}
                         value={email}
                         onChange={(event) => setEmail(
                         event.target.value)}
          />
        </div>}
        {!emailSent && <div className={'bn-dialog-button-container'}>
          {!forgetPswBusy && <button
              className={'bn-booknow-button'}
              onClick={() => handleForgetPassword()}
              disabled={!email}>OK</button>}
          {!forgetPswBusy && <button
              className={'bn-booknow-button'}
              onClick={() => onCancelClick()}>Cancel</button>}
          {forgetPswBusy && <Spinner size={'45px'}/>}
        </div>}
      </div>
  )
})