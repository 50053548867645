import {useState} from "react";
import {deleteFile} from "../api/firebaseApi";

const useFileDelete = () => {
  const [state, setState] = useState({
    busy: false,
    error: ''
  })

  const deleteFile_ = (path, filename, onSuccess) => {

    setState(prev => ({
      ...prev,
      busy: true,
    }));

    deleteFile(path, filename).then(result => {

      if (onSuccess) {
        onSuccess()
      }

      setState({
        busy: false,
        error: '',
      })
    }).catch(error => {
      let msg = 'Unknown error occurred'

      console.log(error)

      switch (error.code) {
        case 'storage/unauthorized':
          msg = "User doesn't have permission to access the object"
          break;

        case 'storage/object-not-found' :
          msg = "Object does not exist.";
          break;

        default: break;
      }

      setState({
        busy: false,
        error: msg,
      })
    })

  }

  return {deleteFile: deleteFile_, ...state}
}

export default useFileDelete;