import {useState} from "react";
import {uploadFile} from "../api/firebaseApi";

const useFileUpload = () => {
  const [state, setState] = useState({
    busy: false,
    error: ''
  })

  const upload = (file, path, onSuccess) => {

    setState(prev => ({
      ...prev,
      busy: true,
    }));

    uploadFile(file, path).then(result => {

      if (onSuccess) {
        onSuccess()
      }

      setState({
        busy: false,
        error: '',
      })


    }).catch(error => {
      let msg = 'Unknown error occurred'
      switch (error.code) {
        case 'storage/unauthorized':
          msg = "User doesn't have permission to access the object"
          break;
        case 'storage/canceled':
          msg = "User canceled the upload"
          break;

        default: break;
      }

      setState({
        busy: false,
        error: msg,
      })
    })

  }

  return {upload, ...state}
}

export default useFileUpload;