import React from "react";
import {Spinner} from "./Spinner";
import classNames from "classnames";

export default function ResponsiveTable({columns = [],
  actions = [],
  data = [],
  busy = false,
  isRowSelected,
  onRowSelected = (row) => {},
  emptyListMessage= 'No records to display.'}) {

  return (
    <table className={'bn-responsive-table'}>
      <thead>
        <tr>
          {columns.map(c => (<th scope="col">{c.label}</th>))}
          {actions.map(a => (<th scope="col"></th>))}
        </tr>
      </thead>
      <tbody>
      {data.map(
        (row, index) => (
          <tr key={index}
              className={classNames(
            {
              'bn-responsive-table-clickable-row': isRowSelected,
              'bn-responsive-table-row-selected': isRowSelected ? isRowSelected(row) : false,
              'bn-responsive-table-row-hover': isRowSelected
            }
          )}
              onClick={event => onRowSelected(row)}
          >
            {columns.map(column => {
              const {id, label, renderValue} = column;
              const renderValue_ = renderValue ? renderValue(row[id]) : row[id];
              return (<td data-label={label}>{renderValue_}</td>)
            })}
            {actions.map(a => {
              const {Component, onClick, className, children, disabled} = a;
              const _onClick = onClick ? event => {
                event.preventDefault();
                event.stopPropagation();
                onClick(row)
              } : undefined;

              const element = React.createElement(
                Component,
                {
                  onClick: _onClick,
                  className,
                  disabled,
                },
                children
              )
              return (<td>{element}</td>)
            })}
          </tr>
        ))}
      {(data.length === 0 && !busy) &&
        <tr>
          <td colSpan={(columns.length+1)}>{emptyListMessage}</td>
        </tr>
      }
      {busy &&
        <tr>
          <td colSpan={(columns.length+1)}>
            <Spinner size={"24px"} />
          </td>
        </tr>
      }
      </tbody>
    </table>
  )
}