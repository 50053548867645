import {Spinner} from "../../../components/Spinner";
import {sortResults_} from "../resultsUtil";
import {HotelResult} from "./HotelResult";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../../app/appSlice";
import useDirectRestFetch from "../../../hooks/useDirectRestFetch";
import {DIRECT_AVAIL} from "../../../api/client";
import {
  addAlternateHotels,
  buildAvailRQ,
  selectResults,
  setSearchForAlternateHotels
} from "../resultsSlice";

export default function AlternateHotels({property}) {

  const {
    criteria, searchForAlternateHotels,
    site, customer, token
  } = useSelector(state => (
    {
      site: selectApp(state).site,
      customer: selectApp(state).customer,
      token: selectApp(state).token,
      criteria: selectResults(state).criteria,
      searchForAlternateHotels: selectResults(state).searchForAlternateHotels,
    }
  ));

  const [show, setShow] = useState(true)

  const {fetch, response: results = [], busy, error} = useDirectRestFetch();
  const dispatch = useDispatch()

  useEffect(() => {
    if (searchForAlternateHotels) {

      const alternateHotelIds = property.alternateHotels.filter(
        p => site.propertyIds.indexOf(p.alternatePropertyId) !== -1);

      if (alternateHotelIds && alternateHotelIds.length > 0) {
        setShow(true)
        fetch({
          ...buildAvailRQ(criteria),
          properties: alternateHotelIds
          .map(p => ({id: p.alternatePropertyId})),
        }, DIRECT_AVAIL, customer, token, (response) => {

          dispatch(
            setSearchForAlternateHotels({searchForAlternateHotels: false}))

          if (response.properties) {
            dispatch(
              addAlternateHotels({alternateProperties: response.properties}))

            // has no results to show
            if ((response.properties || []).filter(
                property => (property.responseType === 'available')).length
              === 0) {
              setShow(false)
            }
          } else {
            setShow(false)
          }
        })
      } else {
        setShow(false)
      }
    }
  }, [searchForAlternateHotels]);

  const availableResults = sortResults_((results.properties || []))
  .filter(property => (property.responseType === 'available'));

  return (
    <>
      {show && <div className={"bn-alternative-hotels-results-container"}>
        <h2>Looking for alternatives</h2>
        <div className={"bn-search-results-container"}>
          {busy && <Spinner size={"10em"}/>}
          {(!busy && !error)
            && availableResults.map(
              (property, idx) => {
                return (
                  <HotelResult site={site} property={property} key={idx}/>
                )
              })}
        </div>
      </div>}
    </>
  )
}