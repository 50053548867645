import React, {useCallback, useEffect, useState} from "react";
import Dialog from "../../../components/Dialog";
import {isPast} from "date-fns";
import {
  buildCancelRQ, CANCEL_COMMIT,
  CANCEL_PENDING, convertReservation,
  getArrivalDate,
  getItinerary, parseDate, removeReservation
} from "../reservationUtil";
import useDirectRestFetch from "../../../hooks/useDirectRestFetch";
import {DIRECT_RESERVATION} from "../../../api/client";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../../app/appSlice";
import {formatAmount} from "../../Util";
import _ from "lodash";
import SelectLookup from "../../../components/SelectLookup";
import TextInputItem from "../../../components/TextInputItem";
import {Spinner} from "../../../components/Spinner";
import {useNavigate} from "react-router-dom";
import {releaseTemphold, updateReservation} from "../sessionSlice";


const CancelFeesTable = ({cancelFeesPayable}) => {

  const total = _.sumBy(cancelFeesPayable, 'cancelDueAmount')

  return (
    <>
      <div className={"bn-dialog-text"}>
        {cancelFeesPayable.length > 0 && <p>The following cancellation fee(s) apply.</p>}
        {cancelFeesPayable.length === 0 && <p>No cancellation fee apply.</p>}
      </div>

      {cancelFeesPayable.length > 0 && <table className={'bn-responsive-table'}>
        <thead>
        <tr>
          <th scope="col">Hotel</th>
          <th scope="col">Room</th>
          <th scope="col">Amount</th>
        </tr>
        </thead>
        <tbody>
        {
          cancelFeesPayable.map((cancelFee, idx) => (
            <tr key={idx}>
              <td data-label={"Hotel"}>
                {cancelFee.propertyName}
              </td>
              <td data-label={"Room"}>
                {cancelFee.inventoryName}
              </td>
              <td data-label={"Room"}>
                {formatAmount(cancelFee.cancelDueAmount,
                  cancelFee.currency.code)}
              </td>
            </tr>
          ))
        }

        <tr key={'total'}>
          <td className={"table-colspan"} colSpan={1}></td>
          <td className={"bn-table-heading"}>Total</td>
          <td data-label={"Grand Total"}>
            {formatAmount(total, cancelFeesPayable[0]?.currency.code)}
          </td>
        </tr>
        </tbody>
      </table>}
    </>
  )
}

export function CancelReservation({reservation, onCancelSuccess}) {

  const navigate = useNavigate();

  const {
    customer, token
  } = useSelector(state => (
    {
      customer: selectApp(state).customer,
      token: selectApp(state).token,
    }
  ));

  const dispatch = useDispatch();
  const updateReservation_ = useCallback((reservation) =>
    dispatch(updateReservation({reservation})), [dispatch]);
  const releaseTemphold_ = useCallback(
    (params) => dispatch(releaseTemphold(params)), [dispatch]);

  const [open, setOpen] = useState(false);
  const [cancelFeesPayable, setCancelFeesPayable] = useState([])
  const [cancelReasonId, setCancelReasonId] = useState(null)
  const [description, setDescription] = useState('')

  const {fetch, data, busy, error} = useDirectRestFetch();

  useEffect(() => {

    if (open) {
      fetch(
        buildCancelRQ(reservation, CANCEL_PENDING), DIRECT_RESERVATION,
        customer, token,
        (response) => {
          const reservation = convertReservation({}, response.reservation);

          setCancelFeesPayable(
            (reservation?.cancelFeesPayable || []).map(cancelFee => {
              const item = getItinerary(reservation).find(
                item => item.id === cancelFee.itineraryId);

              return {
                ...cancelFee,
                propertyName: item.propertyName,
                inventoryName: item.inventoryName,
              }
            }));
        })

      setCancelReasonId(null)
      setDescription('')
    }

  }, [open]);

  const handleCancel = (reservation, cancelReasonId, description) => {
    const {reservationNo, payerProfile: {email}} = reservation;

    fetch(
      buildCancelRQ(reservation, CANCEL_COMMIT, cancelReasonId, description),
      DIRECT_RESERVATION,
      customer, token,
      (response) => {
        setOpen(false)

        removeReservation().then(() => {
          releaseTemphold_({sessionId: reservation.sessionId});
          updateReservation_(undefined);

          // window.location.reload()
          onCancelSuccess(reservationNo, email);
          // navigate(`/confirmation/${reservationNo}/${email}`, {replace: true})
        })
      })
  }

  const canCancel = !!cancelReasonId;

  return (
    <>

      <button className={'bn-booknow-button'}
              disabled={isPast(parseDate(getArrivalDate(getItinerary(reservation))))}
              onClick={() => setOpen(!open)}>Cancel Booking
      </button>

      <Dialog portalId={"bn-cancel-reservation-dialog"} open={open} onOpenChange={setOpen}>
        <>
          <div className={"bn-dialog-content"}>
            <h1>Cancel Booking</h1>

            {busy && <div className={"bn-dialog-busy-container"}> <Spinner size={'10em'} /> </div>}

            {!busy && <CancelFeesTable cancelFeesPayable={cancelFeesPayable}/>}

            <div className={"bn-dialog-text"}>
              <p>Please pick a reason and enter a description(optional) to continue.</p>
            </div>

            <div className={"bn-inputs-left"}>

              <SelectLookup className={"bn-group bn-select-item"}
                            id={"cancelreason"}
                // label={"Cancellation Reason"}
                            name={"CancellationReason"}
                            operationId={'onlyGuestFacing'}
                            value={cancelReasonId}
                            placeholder={"Cancellation Reason"}
                            onChange={(value) => setCancelReasonId(value?.ID)}
                            defaultToFirstOption={true}
                            isClearable={false}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"description"}
                             label={"Description"}
                             placeholder={"Description (optional)"}
                             maxLength={200}
                             value={description}
                             onChange={(event) => setDescription(
                               event.target.value)}
              />
            </div>
          </div>

          <div className={"bn-dialog-button-container"}>
            <button
              className={'bn-booknow-button'}
              disabled={!canCancel}
              onClick={() => handleCancel(reservation, cancelReasonId,
                description)}>Cancel Booking
            </button>
            <button className={"bn-booknow-button bn-secondary-button"}
                    onClick={() => setOpen(false)}>
              Close
            </button>
          </div>
        </>
      </Dialog>
    </>
  )

}