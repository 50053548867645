import React, {useEffect} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './styles/main.css';

export const display = (options) => {

  const container = document.getElementById(options.elementId);
  const root = createRoot(container);

  const {siteId, key: customer_key,
    autoSearch = true,
    singleProperty= false,
    connectionCode = "BOOKNOW",
  } = options;

  root.render(
      <Provider store={store}>
        <App siteId={siteId}
             customer_key={customer_key}
             autoSearch={autoSearch}
             singleProperty={singleProperty}
             connectionCode={connectionCode}
        />
      </Provider>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();

};
