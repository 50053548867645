import * as React from "react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "./Spinner";

export default function TextInputItemButton({
  className,
  id,
  label,
  placeholder,
  value,
  mandatory,
  maxLength,
  onChange,
  onBlur,
  type = 'text',
  validate,
  onButtonClick,
  busy,
  buttonLabel,
  buttonComponent,
  ...otherProps
}) {

  const error = (validate && validate(value));

  return (
      <div className={classNames( className, {
        'bn-error': error
      } )}>
        <label htmlFor={id}>
          <div>{label} {mandatory && <span>*</span>}</div>
          {error && <div className={'bn-error'}>{error}</div>}</label>
        <div className={"bn-input-with-button-container"}>
          <input type={type} id={id}
                 value={value}
                 maxLength={maxLength}
                 placeholder={placeholder}
                 onChange={onChange}
                 onBlur={onBlur}
                 {...otherProps}
          >
          </input>
          {(onButtonClick && !busy) &&
            <button className={"bn-booknow-button"}
                    type={"button"}
                    name={"booknow-button"}
                    onClick={() => onButtonClick()}
            >
              {!buttonLabel && <FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/>}
              {buttonLabel}
            </button>}
          {(onButtonClick && busy) && <Spinner size={"24px"} /> }
          {buttonComponent}
        </div>
      </div>
  )
}