import {useState} from "react";
import {loadLookupItemsPage} from "../booknow/Util";

export const FETCH = 'fetch';
export const CUSTOM = 'custom';

const useRestFetch = () => {

  const [state, setState] = useState({
    busy: false,
    data: [],
    error: undefined,
  })

  const fetch = async (payload, dataSource, operationId, operationType, customer, token) => {
    try {

      setState({
        busy: true,
        data: [],
      })

      let page = 0;
      let pageSize = 50;

      let criteriaWithPaging = {
        dataSource,
        operationType,
        operationId,
        data: {
          ...payload,
        },
        startRow: 0,
        endRow: pageSize,
        textMatchStyle: 'substring',
        sortBy: [
          "Name"
        ]
      };

      let hasMoreRows = true;
      let data = [];

      while (hasMoreRows) {
        page++;
        const response = await loadLookupItemsPage(criteriaWithPaging, page,
          pageSize,
          customer, token);

        const moreData = response.data;
        data = data.concat(moreData)

        hasMoreRows = (page * pageSize) < response.totalRows;

        setState({
          ...state,
          data,
        })
      }

      setState({
        busy: false,
        data,
        error: undefined,
      })

      return data;

    } catch (error) {
      setState({
        data: [],
        busy: false,
        error
      })

      console.log('Rest fetch error: ', error)
    }

  }

  return {fetch, ...state};
}

export default useRestFetch