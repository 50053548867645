import {useEffect, useState} from "react";
import axios from "axios";
import {
  getSingleDoc,
  getStorageRefs
} from "../api/firebaseApi";
import {getInventoryIdFromPath, getPropertyIdFromPath} from "../booknow/Util";
import _ from "lodash";

const extionsion_options = {"avif" : {"quality": 90, "lossless": false }}
const extionsion_resize = {"position" : "centre", "fit": "inside"}

const useImageUrls = (path, cached, setImgUrls, docId, limit = 15) => {
  const [state, setState] = useState({
    data: [],
    busy: true,
    error: '',
  });

  useEffect(() => {
    const fetch = async () => {
      try {

        if (!cached) {

          // const {imageUrls, error} = await getImageUrls(path);

          const {storageRefs} = await getStorageRefs(path);

          getSingleDoc(docId)
          .then(async doc => {
            const {imageOrder = []} = (doc || {});

            const sortByOrder = _.sortBy(storageRefs,
              ref => _.findIndex(imageOrder, o => o === ref.filename));

            const responses = await Promise.all(sortByOrder.slice(0, _.min([limit, sortByOrder.length])).map(ref => {
                  return axios.get(ref.downloadURL, {responseType: 'blob'});
            }));

            const data = responses.map(r => URL.createObjectURL(r.data));

            if (data.length) {
              setImgUrls({
                propertyId: getPropertyIdFromPath(path),
                inventoryId: getInventoryIdFromPath(path),
                data
              })
            }

            setState({
              data,
              busy: false
            })

          })

        } else {
          setState({
            data: cached ?? [],
            busy: false
          })
        }
      } catch (error) {
        console.log(error)
        setState({
          data: [],
          busy: false,
          error
        })
      }

    };

    fetch();
  }, [path, cached, setImgUrls]);

  return state;
};

export default useImageUrls;