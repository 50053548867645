import React, {useEffect, useState} from "react";
import {useIdleTimer} from "react-idle-timer";
import {useDispatch} from "react-redux";
import {
  clearRoomProfiles,
  updatePayerProfile
} from "../booknow/session/sessionSlice";
import IdleTimeDialog from "./IdleTimeDialog";

const timeout = (20000 * 60)
const promptBeforeIdle = 60000
export default function IdleTimer() {
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const [detailsCleared, setDetailsCleared] = useState(false)

  const onIdle = () => {
    // setOpen(false)
    setDetailsCleared(true)

    updatePayerProfile_({
      title: 'Mr',
      titleId: 1,
      countryId: 197, // defaults
    })

    clearRoomProfiles_()
  }

  const onActive = () => {
    // setOpen(false)
  }

  const onPrompt = () => {
    setDetailsCleared(false)
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    setOpen(false)
    activate()
  }

  const dispatch = useDispatch();
  const updatePayerProfile_ = React.useCallback((payerProfile) => dispatch(updatePayerProfile({payerProfile})), [dispatch]);
  const clearRoomProfiles_ = React.useCallback(() => dispatch(clearRoomProfiles()), [dispatch]);

  return (
    <IdleTimeDialog open={open}
                    setOpen={setOpen}
                    onIdle={onIdle}
                    handleStillHere={handleStillHere}
                    detailsCleared={detailsCleared}
                    remaining={remaining} />
  )
}