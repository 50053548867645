import {useState} from "react";
import {getSingleDoc} from "../api/firebaseApi";

const useFirestoreFetch = () => {

  const [state, setState] = useState({
    data: {},
    busy: true,
    error: '',
  })

  const fetchData = (docId, callback) => {

    setState({
      data: {},
      busy: true,
      error: '',
    })

    getSingleDoc(docId).then(doc => {
      setState({
        data: doc,
        busy: false,
        error: ''
      })

      if(callback) {
        callback(doc)
      }

    }).catch(error => {
      console.log('useFirestoreFetch error', error)
    })
  }

  return {fetchData, ...state};
}

export default useFirestoreFetch;