export const getPropertyIds = (suggestions) => {
  return (suggestions||[]).map((suggestion) => {
    return (suggestion.properties||[]).map(property => {
      return {id: property.id, name: property.name}
    });
  }).flat()
};

export const getDisplayValue = (area, location, property) => {
  let text = 'Anywhere';

  if (property) {
    return property.name;
  } else if (location) {
    return location.name;
  } else if (area) {
    return area.name;
  }

  return text;
};

export const noOfProperties = (suggestion) => {
  if (suggestion.properties) {
    return '(' + suggestion.properties.length + ')'
  }

  return '';
};

export const isSelected = (item, suggestion) => {
  return item.name === suggestion?.name
};

export const suggestionIncludesTerm = (suggestion, searchTerm) => {
  return String(suggestion.name).toLowerCase().includes(
    String(searchTerm).toLowerCase())
};

export const locationHasSearchTermFound = (suggestions) => {
  const map = suggestions.filter(area => {
    return hasSearchTermFound(area.locations)
  });

  return map.length > 0;
};

export const propertyHasSearchTermFound = (suggestions) => {
  const map = suggestions.filter(area => {
    return area.locations.filter(location => {
      return hasSearchTermFound(location.properties);
    }).length > 0
  });

  return map.length > 0;
};

export const hasSearchTermFound = (suggestions) => {
  return suggestions.find(sug => (sug.searchTermFound === true))
};

export const filterSuggestions = (suggestions, searchTerm) => {
  const copy = suggestions.slice();

  return copy.map(area => {
    return Object.assign({}, area, {
      searchTermFound: suggestionIncludesTerm(area, searchTerm),
      locations: area.locations.map(location => {
        return Object.assign({}, location, {
          searchTermFound: suggestionIncludesTerm(location, searchTerm),
          properties: location.properties.map(property => {
            return Object.assign({}, property, {
              searchTermFound: suggestionIncludesTerm(property, searchTerm)
            });
          })
        })
      })
    })
  });
};