import Dialog from "./Dialog";
import React from "react";

export default function IdleTimeDialog({open, setOpen, handleStillHere, detailsCleared, onIdle, remaining}) {
  return (
    <Dialog open={open} onOpenChange={() => handleStillHere()} >
      <>
        <div className={"bn-dialog-content bn-dialog-idle-message"}>
          <h3>Are you still here?</h3>
          <p>You have been inactive for 20 minutes.</p>
          {!detailsCleared && <p>
            To protect your privacy your details will be cleared
            in {remaining} seconds.
          </p>}
          {detailsCleared && <p>
            To protect your privacy your details have been cleared.
          </p>}
        </div>
        {!detailsCleared && <div className={"bn-dialog-button-container"}>
          <button
            className={'bn-booknow-button'}
            onClick={() => handleStillHere()}>Yes
          </button>
          <button
            className={'bn-booknow-button'}
            onClick={() => onIdle()}>Clear Now
          </button>
        </div>}
        {detailsCleared && <div className={"bn-dialog-button-container"}>
          <button
            className={'bn-booknow-button'}
            onClick={() => setOpen(false)}>OK
          </button>
        </div>}
      </>
    </Dialog>
  )
}
