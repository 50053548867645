import * as React from "react";
import classNames from "classnames";

export default function TextInputItem({
  className,
  id,
  label,
  placeholder,
  value,
  mandatory,
  maxLength,
  onChange,
  onBlur,
  type = 'text',
  validate,
  ...otherProps
}) {

  const error = (validate && validate(value));

  return (
      <div className={classNames( className, {
        'bn-error': error
      } )}>
        <label htmlFor={id}>
          <div>{label} {mandatory && <span>*</span>}</div>
          {error && <div className={'bn-error'}>{error}</div>}</label>
        <input type={type} id={id}
               value={value}
               maxLength={maxLength}
               placeholder={placeholder}
               onChange={onChange}
               onBlur={onBlur}
               {...otherProps}
        >
        </input>
      </div>
  )
}