import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  buildDataSourcePayload,
  client,
  getApiUrl,
  NEBULA_REST
} from "../../api/client";
import {convertAgent, selectApp} from "../app/appSlice";
import {getRestrictionName} from "./signupUtil";

const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    busyGetContact: false,
    changePasswordBusy: false,
    passwordRestrictions: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getContact.pending, (state) => {
      state.busyGetContact = true;
      state.contact = undefined;
    }).addCase(getContact.fulfilled, (state, action) => {
      state.busyGetContact = false;
      state.contact = convertAgent(action.payload);
    })
    .addCase(getContact.rejected, (state, action) => {
      state.busyGetContact = false;
      state.error = action.error.message;
    })

    // password restrictions
    .addCase(getPasswordRestrictions.pending, (state) => {
      state.busy = true;
      state.passwordRestrictions = [];
    })
    .addCase(getPasswordRestrictions.fulfilled, (state, action) => {
      state.busy = false;
      state.passwordRestrictions = action.payload;
    })
    .addCase(getPasswordRestrictions.rejected, (state, action) => {
      state.busy = false;
      state.error = action.error.message;
    })
  }
});

export const selectSignup = state => state.signup;

export const getContact = createAsyncThunk('contact/fetch', async (id, thunkAPI) => {
  const {getState} = thunkAPI;
  const {customer, token} = selectApp(getState())

  const response = await client.post(getApiUrl(customer, NEBULA_REST),
      buildDataSourcePayload({ID: id}, "Contact", "fetch"), token)

  return response[0];
});

export const getPasswordRestrictions = createAsyncThunk('passwordRestrictions/fetch', async (id, thunkAPI) => {
  const {getState} = thunkAPI;
  const {customer, token} = selectApp(getState())

  const response = await client.post(getApiUrl(customer, NEBULA_REST),
      buildDataSourcePayload({}, "UserAccountMaintenance", "fetch"), token)

  return response.filter(r => ((r.Value !== "0" && r.Value !== "false")
      && [1,2,4,8,9].indexOf(r.ID) === -1 )).map(r => {
    return {
      id: r.ID,
      name: getRestrictionName(r.ID, r.Value),
      value: r.Value,
    }
  })
});

export default signupSlice.reducer;