import React, {useEffect} from 'react';
import '../../../styles/main.css'
import {HotelResult} from "./HotelResult";
import {Spinner} from '../../../components/Spinner'
import {useSelector} from "react-redux";
import {selectResults} from "../resultsSlice";
import {scrollToBookNow} from "../../Util";
import {sortResults_} from "../resultsUtil";
import {selectApp} from "../../app/appSlice";
import Itinerary, {ItineraryActions} from "../../session/components/Itinerary";
import {selectSession} from "../../session/sessionSlice";
import {getItinerary} from "../../session/reservationUtil";
import AlternateHotels from "./AlternateHotels";
import {
  trackPageView
} from "../../../api/googleTag";

export function SearchResults({onScroll}) {

  const {results, busy, site, reservation} = useSelector(state => (
      {
        results: selectResults(state),
        busy: selectResults(state).busy,
        site: selectApp(state).site,
        reservation: selectSession(state).reservation,
      }
  ));

  React.useEffect(() => {
    scrollToBookNow();
  }, []);

  useEffect(() => {
      trackPageView("/results", site)
  }, [site]);

  // sortResults_(results.properties?? [])

  return (
      <div className={"bn-search-results"} onScroll={onScroll}>
        <div className={"bn-search-results-container"}>
          {busy && <div className={"bn-search-spinner-container"}>
            <Spinner size={"10em"}/>
          </div>}
          {(!busy && !results.error)
            // && results.properties.slice().sort(sortResults)
            && sortResults_(results.properties)
            .filter(
            property => (!property.alternateHotel))
            .map(
            (property, idx) => {
              return (
                <HotelResult site={site} property={property} key={idx} index={idx}/>
              )
            })}

          {(!busy
              && results.properties.filter(p => (!p.alternateHotel)).length === 1
              && results.properties[0].responseType === 'unavailable')
            && <AlternateHotels property={results.properties[0]}/>
          }

          {results.error && <div className={"bn-error-message"}>
            <strong>{results.error}</strong></div>}

        </div>

        {(reservation && getItinerary(reservation).length > 0) &&
          <Itinerary action={ItineraryActions.GUESTDETAIL}/>
        }
      </div>
  )
}