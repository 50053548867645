import {useState} from 'react';
import {client, DIRECT_AVAIL, getApiUrl} from "../api/client";
import {useSelector} from "react-redux";
import {selectApp} from "../booknow/app/appSlice";
import {getPropertyContent} from "../api/firebaseApi";

export function usePropertyResults() {

  const {instance, customer, connectionCode, token} = useSelector(state => ({
    instance: selectApp(state).instance,
    connectionCode: selectApp(state).connectionCode,
    customer: selectApp(state).customer,
    token: selectApp(state).token,
  }))

  const [state, setState] = useState({
    busy: false,
  })

  const getResults = async (criteria) => {
    setState({
      ...state,
      busy: true,
      property: undefined,
      error: undefined,
    })

    try {

      const data = await client.post(getApiUrl(customer, DIRECT_AVAIL),
        Object.assign({}, criteria,
          {
            connectionCode: connectionCode,
          }), token)

      const docs = (await Promise.all(data.properties.map((property) => {
        return getPropertyContent(instance, property.id, 1);
      }))).flat()

      const properties =  data.properties.map(property => {
        const propertyContent = docs.find(
          doc => doc.propertyId === property.id && !doc.inventoryId);

        const inventory = property.inventory.map(inventory => {
          const inventoryContent = docs.find(
            doc => doc.propertyId === property.id && doc.inventoryId
              === inventory.id)

          return {
            ...inventory,
            ...inventoryContent
          }
        })

        return {...property, ...propertyContent, inventory};
      })

      setState({
        ...state,
        busy: false,
        property: properties[0],
        error: undefined,
      })

      return data;

    } catch (error) {
      setState({
        ...state,
        busy: false,
        property: undefined,
        error
      })
    }
  }

  return {
    getResults, ...state
  }
}