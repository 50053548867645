import React from "react";
import {
  FloatingFocusManager,
  FloatingPortal, useClick, useDismiss, useFloating, useInteractions,
  useRole, useTransitionStyles
} from '@floating-ui/react';

export default function Menu ({open, onOpenChange, anchorElement, children, ...otherProps}) {

  const {x, y, strategy, refs, context} = useFloating({
    placement: "bottom-start",
    open,
    onOpenChange,
  });

  const { styles} = useTransitionStyles(context, {
    duration: {
      open: 200,
      close: 100,
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const ref = refs.setReference;

  return(
    <>
      {React.cloneElement(anchorElement, {
        ref,
        ...otherProps,
        ...anchorElement?.props,
        ...getReferenceProps()
      })}
      <FloatingPortal id={"bn-floating-menu"}>
        {open && (
            <FloatingFocusManager context={context} modal={false}>
              <div
                  ref={refs.setFloating}
                  className={"bn-floating-menu"}
                  style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    ...styles,
                  }}
                  {...getFloatingProps()}
              >
                  {children}
              </div>
            </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
};