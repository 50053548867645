import {format, parse} from "date-fns";
import _ from "lodash";
import {client, getApiUrl, NEBULA_REST} from "../api/client";
import {useEffect, useRef} from "react";

export const DATE_TIME = "yyyy-MM-dd";
export const DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS";

export const DATE_TIME_FORMAT_TIME_ZONE = "yyyy-MM-dd'T'HH:mm:ss.SSSX";

export const copyObjectExcept = (obj, array) => {
  return Object.keys(obj).reduce((object, key) => {
    if (!array.includes(key)) {
      object[key] = obj[key]
    }
    return object
  }, {});
};

export const round = (value) => {
  return roundToDecimal(value, 2);
};

export const roundToDecimal = (value, decimals) => {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const getCurrencySymbol = (code) => {
  if (code === 'ZAR') {
    return "R"
  } else if (code === 'USD') {
    return '$'
  } else if (code === 'GBP') {
    return '£'
  } else if (code === 'EUR') {
    return '€'
  } else if (code === 'AUD') {
    return 'A$'
  } else if (code === 'NAD') {
    return 'N$'
  } else if (code === 'BWP') {
    return 'P'
  } else if (code === 'CDF') {
    return 'FC'
  } else if (code === 'KES') {
    return 'Ksh'
  } else if (code === 'MZN') {
    return 'MT'
  } else if (code === 'ZMW') {
    return 'ZK'
  } else if (code === 'MWK') {
    return 'MK'
  } else {
    return code
  }
};

export const formatAmount = (amt, currencyCode) => {
  let locale = window.navigator.language === 'en-ZA' ? 'en-GB' : window.navigator.language; //QA wants GB format for SA

  if (currencyCode !== undefined) {
    return getCurrencySymbol(currencyCode) + ' ' + round(amt).toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  } else {
    return round(amt).toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  }
};

export const formatAmountTax = (amt, currencyCode, ratesIncludeTaxes = true) => {
  return `${formatAmount(amt, currencyCode)}${!ratesIncludeTaxes ? '*' : ''}`
}

export const scrollIntoView_ = (id, options) => {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView(options)
  } else {
    console.log('Element with id ' + id + ' not found.');
  }
};

export const isElementInViewport = (id) => {
  const elementById = document.getElementById(id);
  if (elementById) {
    const rect = elementById.getBoundingClientRect();
    return (rect.top <= 0) // this only supports booknow on its own page or at bottom
  }
  return false
};

export const scrollToBookNow = () => {
  if(isElementInViewport('booknow-container')) {
    scrollIntoView_('bn-page-container-scroll-offset',
        {behavior: 'smooth', block: 'start'});
  }
};

export const scrollToContinue = (id) => {
  scrollIntoView_(id,
    {behavior: 'smooth', block: 'end'});
}

export const isEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)
        && (obj[prop] !== undefined && obj[prop] !== null)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

const getAllPropertyIds = (suggestions) => {
  return suggestions.flatMap(
      area => (area.properties.map(property => ({id: property.id}))));
};

export const storeAgentLogin = (login) => {
  sessionStorage.setItem('agentLogin', JSON.stringify(login));
};

export const getAgentLogin = () => {
  return JSON.parse(sessionStorage.getItem('agentLogin'));
};

export const isLoggedIn = () => {
  return (sessionStorage.getItem('agentLogin') !== undefined && sessionStorage.getItem(
      'agentLogin') !== null);
};

export const removeAgentLogin = () => {
  sessionStorage.removeItem('agentLogin');
};

export const getInnerHTML = (innerHtml, undefinedText) => {
  return innerHtml ? {__html: innerHtml} : {__html: undefinedText}
}

export const getPath = (instance, propertyId, inventoryId) => {
  let path = `${instance}/${propertyId}`

  if(inventoryId) {
    return `${path}/${inventoryId}`
  }

  return path;
}

export const getAttachmentsPath = (instance, reservationNo) => {
  return  `${instance}/attachments/${reservationNo}`;
}

export const getPropertyIdFromPath = (path) => {
  const split = path.split('/');
  return split[1];
}

export const getInventoryIdFromPath = (path) => {
  const split = path.split('/');
  return split[2];
}


export const getInstanceName = (api_url) => {
  const split = api_url.split('/');

  if(!isDev()) {
    return split[(split.indexOf('eres') - 1)];
  } else return 'dev'
}

export const isDev = () => {
  return process.env.NODE_ENV === "development";
  // return true
};

export const validEmail = (value) => {
  const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return validRegex.test(value.trim())
}

export const validAmount = (value) => {
  const regex = /^[0-9]\d*(\.\d{1,2})?$/;
  return regex.test(value.trim());
}

export const numericOnly = (value) => {
  const regex = /^[0-9.,]*$/;
  return regex.test(value.trim());
}

// export const getHostUrl = location => {
//   return `${location.protocol}//${location.hostname}${isDev()?(':'+location.port):location.pathname}`;
// }

export const getHostUrl = hostUrl => {
  if(_.endsWith(hostUrl,"/")) {
    return hostUrl.substring(0, (hostUrl.lastIndexOf("/")));
  } else {
    return hostUrl;
  }
}

export const getPropertyFeatureFontName = (id) => {
  switch (id) {
    case 1: return 'fa-champagne-glasses';
    case 2: return 'fa-umbrella-beach';
    case 3: return 'fa-bus';
    case 4: return 'fa-children';
    case 5: return 'fa-screen-users';
    case 6: return 'fa-wheelchair';
    case 7: return 'fa-fishing-rod';
    case 8: return  'fa-paw-claws';
    case 9: return  'fa-golf-ball'//'fa-golf-club';
    case 10: return 'fa-person-hiking'
    case 11: return 'fa-horse';
    case 12: return 'fa-globe';
    case 13: return 'fa-water-ladder';
    case 14: return 'fa-utensils';
    case 15: return 'fa-bell-concierge';
    case 16: return 'fa-satellite-dish';
    case 17: return 'fa-racquet';
    case 18: return 'fa-tennis-ball';
    case 19: return 'fa-wifi';
    case 20: return 'fa-spa';
    case 21: return 'fa-person-biking'; // quads
    case 22: return 'fa-mask-snorkel'; // scuba diving
    case 23: return 'fa-mask-snorkel';
    case 24: return 'fa-binoculars';
    case 25: return 'fa-sailboat';
    case 26: return 'fa-paw';
    case 27: return 'fa-dumbbell';
    case 28: return 'fa-van-shuttle';
    case 29: return 'fa-leaf';
    case 30: return 'fa-ban-smoking';
    case 31: return 'fa-slot-machine';
    case 32: return 'fa-briefcase';
    case 33: return 'fa-check-square';
    case 34: return 'fa-cart-flatbed-suitcase';
    case 35: return 'fa-water-ladder';
    case 36: return 'fa-square-parking';
    case 37: return 'fa-pancakes';
    case 38: return 'fa-lightbulb-on';
    case 39: return 'fa-shower';
    case 40: return 'fa-ship';
  }
}

export const getHtiHostedImageURL = (customer, filename) => {
  const {api_url} = customer;

  const instanceUrl = api_url.substring(0, api_url.toLowerCase().indexOf('/eres/admin')); // 	https://test.hti-systems.com/sandbox/eres/admin/direct

  if (!isDev()) {
    return `${instanceUrl}/booknow/images/${filename}`
  } else {
    return `http://localhost:3000/images/${filename}`;
  }
}

export const formatDateStr = (dateString, datePattern = 'yyyy-MM-dd',
  formatPattern = 'dd MMM yyyy') => {
  return format(parse(dateString, datePattern, new Date()), formatPattern);
}

export const formatDate = (date, formatPattern = 'dd MMM yyyy') => {
  return format(date, formatPattern);
}

export const loadLookupItemsPage = async (criteria, page, pageSize, customer, token) => {
  let lookup_criteria = {
    ...criteria,
    startRow: ((page - 1) * pageSize),
    endRow: (page * pageSize),
  };

  return  await client.post(getApiUrl(customer, NEBULA_REST),
    lookup_criteria, token, undefined, false)
};

export const getContactUsUrl = (site, property) => {
  if(site.contactUsUrl) {
    return site.contactUsUrl;
  }
  else return property.webAddress;
}

export const isResultsPage = (location) => {
  return !(location.pathname.indexOf('/confirmation') !== -1
    || location.pathname.indexOf('/guestdetails') !== -1
    || location.pathname.indexOf('/contactinvite') !== -1
    || location.pathname.indexOf('/agentprofile') !== -1
    || location.pathname.indexOf('/guestprofile') !== -1)
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}