import axios from "axios";
import {isDev} from "../booknow/Util";

export const DIRECT_AVAIL = '/availability';
export const DIRECT_RESERVATION = '/reservation';
export const DIRECT_SEARCH = '/reservation/search';
export const DIRECT_LOYALTY = '/loyalty';
export const DIRECT_PAYMENT_RECEIVED = '/paymentreceived';
export const NEBULA_REST = '/rest';

// export const CONNECTION_CODE = 'BOOKNOW';

export async function client(url,
    {payload, method, customConfig, token, returnDataOnly = true} = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '.concat(token),
    // ...(isDev() && {'Authorization': 'Basic YWRtaW46YWRtaW4='}),
  };
  let data;

  console.log(url + " rq: ", JSON.stringify(payload));

  try {
    const response = await axios({
      method: method,
      ...customConfig,
      url: url,
      responseType: 'json',
      ...(method === 'POST' && {headers}),
      data: payload
    });

    if (!url.includes(NEBULA_REST)) {
      if (!isErrorResponse(response.data)) {
        console.log(url + " rs: ", JSON.stringify(response.data));
        return response.data;
      } else {
        console.log(url + " error rs: ", JSON.stringify(response.data));
        throw new Error(processErrorRS(response.data))
      }
    } else {
      if (!isRESTErrorRS(response)) {
        console.log(url + " rs: ", JSON.stringify(response.data));
        if (returnDataOnly) {
          return response.data.response.data;
        } else {
          return response.data.response;
        }
      } else {
        console.log(url + " error rs: ",
            JSON.stringify(response.data.response.data));
        throw new Error(processRESTErrorRS(response))
      }
    }
  } catch (err) {
    console.log('doPost err: ' + JSON.stringify(err.message));
    return Promise.reject(err.message ? err.message : data)
  }
}

client.get = function (api, token, customConfig = {}) {
  return client(api, {customConfig, method: 'GET', token})
};

client.post = function (api, payload, token, customConfig = {},
    returnDataOnly = true) {
  return client(api, {
    ...customConfig, payload, method: 'POST', token,
    returnDataOnly
  })
};

export const isErrorResponse = (data) => {
  return data.errors && data.errors.length > 0;
};

export const processErrorRS = (data) => {
  return (data.errors || [{description: "Unknown error."}])[0].description;
};

export const isRESTErrorRS = (response) => {
  return (response.data.response.status === -1
      || response.data.response.status === -4
    || response.data.response.status === -9)
};

export const processRESTErrorRS = (response) => {
  if (response.data.response.data && !Array.isArray(
      response.data.response.data)) {
    if(response.data.response.data.errors) {
      return response.data.response.data.errors[0].errorType;
    }
    else {
      return response.data.response.data; // should be a string containing the error
    }
  } else {
    return "Unknown Error";
  }
};

export const buildDataSourcePayload = (data, dataSourceName, operationType,
    operationId) => {
  const dsOptions = {
    dataSource: dataSourceName,
    operationType: operationType,
    operationId: operationId,
  };

  return buildRestEnvelope(data, dsOptions)
};

export const buildRestEnvelope = (data, dsOptions) => {
  return {
    dataSource: dsOptions.dataSource,
    operationType: dsOptions.operationType,
    operationId: dsOptions.operationId,
    data: data,
    startRow: 0,
    endRow: 1000,
    textMatchStyle: 'substring',
    sortBy: dsOptions.sortBy
  }
};

export const getApiUrl = (customer, api) => {
  const {api_url} = customer;

  switch (api) {
    case NEBULA_REST :
      return api_url.substring(0, api_url.indexOf('/direct')) + api;

    default:
      return api_url + api;
  }
}

export const getErrorType = ({error}) => {
  return error.message;
}