export const BAD_CREDENTIALS = 'BAD_CREDENTIALS';
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD';

export const getErrorTypeDescription = errorType => {
  switch (errorType) {
    case BAD_CREDENTIALS :  return "Invalid username or password.";
    case USER_CHANGE_PASSWORD: return "Password change required.";

    default: return "Unknown error."
  }
}

