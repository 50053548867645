import React, {
  forwardRef, useCallback,
  useEffect,
  useImperativeHandle, useRef,
  useState
} from 'react';

import {addDays, addMonths, format, isAfter, isBefore} from 'date-fns';
import {Button, DayPicker, useDayRender} from 'react-day-picker';
import {
  autoUpdate,
  FloatingFocusManager, FloatingPortal, size,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import {
  selectResults,
  setPickingStay
} from "../booknow/results/resultsSlice";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import {flip, shift, offset} from "@floating-ui/react";
import {flushSync} from "react-dom";
import {selectApp} from "../booknow/app/appSlice";
import useFirestoreGetDocs from "../hooks/useFirestoreGetDocs";
import {getDensityQuery} from "../api/firebaseApi";
import {
  getInstanceName,
  formatDate as formatDate_,
  isDev
} from "../booknow/Util";
import _ from "lodash";
import {parseDate} from "../booknow/session/reservationUtil";

const startMonth = new Date();
const ARRIVAL_DATE = 0;
const DEPART_DATE = 1;

const formatDate = (date) => {
  return format(new Date(date.toDateString()), 'MMM d')
};

export default forwardRef(function DateRangePicker({
  portalId = 'bn-date-picker-portal',
  setArrivalDate,
  setDepartDate,
  arrivalDate,
  closeOnScroll = true,
  showNext,
}, ref) {

  const {pickingStay, criteria, customer} = useSelector(state => ({
    pickingStay: selectResults(state).pickingStay,
    criteria: selectResults(state).criteria,
    customer: selectApp(state).customer,
  }));

  const [open, setOpen] = useState(false);
  const [range, setRange] = useState(undefined);
  const [maxHeight, setMaxHeight] = useState(600)
  const [nextClickType, setNextClickType] = useState(ARRIVAL_DATE)
  const [month, setMonth] = useState(startMonth);
  const [disabledDays, setDisabledDays] = useState([{before: new Date()}])
  const [notAvailableDays, setNotAvailableDays] = useState([])
  const [queryTimer, setQueryTimer] = useState(0)

  const dispatch = useDispatch();
  const {fetchData: fetchDensity, data, busy, error} = useFirestoreGetDocs();

  const handleOpen = React.useCallback((open) => {
    setOpen(open);
    dispatch(setPickingStay({pickingStay: open}))
  }, [setOpen, dispatch]);

  const handleOnSelect = React.useCallback((range) => {

    if (nextClickType === ARRIVAL_DATE) {
      setNextClickType(DEPART_DATE)

      if (range?.from && isBefore(new Date(range.from.toDateString()),
        new Date(arrivalDate.toDateString()))) {

        setArrivalDate(new Date(range.from.toDateString()));

        setRange({
          from: range.from,
          to: undefined,
        })

      } else {
        if (range?.to) {
          setArrivalDate(new Date(range.to.toDateString()));

          setRange({
            from: range.to,
            to: undefined,
          })
        }
      }
    } else {
      setNextClickType(ARRIVAL_DATE)
      if (range?.to) {
        setDepartDate(new Date(range.to.toDateString()));
        setArrivalDate(new Date(range.from.toDateString()));

        setRange({
          from: range.from,
          to: range.to
        })
      }
    }

  }, [setRange,
    setArrivalDate,
    setDepartDate,
    arrivalDate,
    nextClickType,
    setNextClickType]);

  const handleOnDayClick = useCallback((dayClicked) => {

    if (notAvailableDays && notAvailableDays.length > 0) {
      let dayBefore = notAvailableDays.toReversed().find(
        notAvailableDay => isBefore(notAvailableDay, dayClicked));
      const dayAfter = notAvailableDays.find(
        notAvailableDay => isAfter(notAvailableDay, dayClicked));

      if (dayBefore) {
        dayBefore = addDays(dayBefore, 1);
      }

      setDisabledDays(
        [{before: dayBefore ?? new Date()}, {after: dayAfter}])

      if (nextClickType === DEPART_DATE) {
        setDisabledDays([{before: new Date()}, ...notAvailableDays]);
      }
    }
  }, [notAvailableDays, nextClickType])

  useImperativeHandle(ref, () => ({
    show() {
      refs.reference.current.click();
    }
  }));

  const handleNext = (event) => {
    event.stopPropagation()
    handleOpen(false)
    if (showNext) {
      showNext()
    }
  }

  useEffect(() => {
    if (criteria) {
      setRange(
        {from: new Date(criteria.startDate), to: new Date(criteria.endDate)});

      setArrivalDate(parseDate(criteria.startDate))
      setDepartDate(parseDate(criteria.endDate))

      handleOnMonthChange(month, customer, criteria)
    }
  }, [criteria]);

  useEffect(() => {
    if (!pickingStay && closeOnScroll) {
      setOpen(false)
    }
  }, [pickingStay]);

  const {refs, floatingStyles, strategy, context} = useFloating({
    open,
    onOpenChange: handleOpen,
    placement: "bottom-start",
    middleware: [offset(15), flip(), shift({
      crossAxis: true,
    }),
      size({
        apply({availableHeight}) {
          flushSync(() => setMaxHeight(availableHeight));
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  useInteractions([
    useDismiss(context, {
      outsidePointerDown: true,
    }),
  ]);

  // const disabledDays = [{before: new Date()}];

  let footer = <p>Please pick the first day.</p>;
  if (range?.from) {
    if (!range.to) {
      footer = <p>{format(range.from, 'PPP')}</p>;
    } else if (range.to) {
      footer = (
        <p>
          {format(range.from, 'PPP')} – {format(range.to, 'PPP')}
        </p>
      );
    }
  }

  const handleOnMonthChange = (month, customer, criteria) => {
    if (criteria.properties.length === 1) { // specific property
      const propertyId = criteria.properties[0].id;

      // delay query to prevent querying months skipped past

      clearTimeout(queryTimer);

      const timeout = setTimeout(() => {

        fetchDensity(getDensityQuery(isDev() ? 'demo' : getInstanceName(customer.api_url),
            propertyId,
            undefined,
            formatDate_(month, 'yyyy-MM-dd'),
            formatDate_(addMonths(month, 2), 'yyyy-MM-dd')),
          (data) => {
            const notAvailableDays = calcNotAvailableDays(data);
            setNotAvailableDays(notAvailableDays);
            setDisabledDays([{before: new Date()}, ...notAvailableDays])
          })

      }, 500);

      setQueryTimer(timeout)
    } else {
      setNotAvailableDays([])
      setDisabledDays([{before: new Date()}])
    }

    setMonth(month)
  }

  const calcNotAvailableDays = (data) => {
    const groupBy = _.groupBy(data, 'date');
    const sum = Object.keys(groupBy).map(date => {
      return {
        [date]: _.sumBy(groupBy[date], 'roomsRemaining')
      }
    })
    const filter = sum.filter(s => (s[Object.keys(s)[0]] < 1));
    return filter.map(f => (parseDate(Object.keys(f)[0])));
  }

  return (
    <div onClick={() => handleOpen(true)}
         ref={refs.setReference}
         className={classNames('bn-date-picker', 'bn-search-widget-item',
           {
             'bn-selected': open,
             'bn-not-selected': (pickingStay && !open)
           })}>
      <div className={classNames('bn-date-picker-date')}>
        <strong className={"label"}>When</strong>
        <label className={"value"}>
          {(range?.from && range?.to) ? `${formatDate(
            range.from)} - ${formatDate(range.to)}` : 'Choose date'}
        </label>
      </div>
      <FloatingPortal id={portalId}>
        {(open) &&
          <FloatingFocusManager context={context}>
            <div ref={refs.setFloating}
                 style={{...floatingStyles, maxHeight}}
                 className={classNames('bn-date-picker-container',
                   'bn-searchwidget-portal-container')}>
              <div className={'bn-date-picker-months-scroll'}>
                <DayPicker
                  mode="range"
                  numberOfMonths={2}
                  selected={range}
                  onSelect={handleOnSelect}
                  onDayClick={handleOnDayClick}
                  fromMonth={startMonth}
                  disabled={disabledDays}
                  onMonthChange={(month) => handleOnMonthChange(month, customer,
                    criteria)}
                />
              </div>
              <div className={'bn-date-picker-footer'}>{footer}</div>
              <div className={'bn-button-container'}>
                <button className={'bn-booknow-button'}
                        onClick={(event) => handleNext(event)}>Add Guests
                </button>
              </div>
            </div>
          </FloatingFocusManager>
        }
      </FloatingPortal>
    </div>
  );
})
