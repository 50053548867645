import React, {useCallback, useState} from 'react';
import ToolTip from "../../../components/ToolTip";
import Dialog from "../../../components/Dialog";
import _ from "lodash";
import {getBestRateForInventory} from "../../results/resultsUtil";
import {useSelector} from "react-redux";
import {selectApp} from "../../app/appSlice";
import {HotelInventory} from "../../results/components/HotelDetail";
import {selectSession} from "../sessionSlice";
import {usePropertyResults} from "../../../hooks/usePropertyResults";
import {Spinner} from "../../../components/Spinner";
import {
  createSearchByPaxRoomBreakdown,
} from "../reservationUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export function ItineraryRoomTypeEdit({item, isCriteriaItem}) {

  const {site, reservation} = useSelector(state => ({
    site: selectApp(state).site,
    reservation: selectSession(state).reservation,
  }))

  const {getResults, property, busy, error} = usePropertyResults();

  const [open, setOpen] = useState(false)
  const [openSameCurrency, setOpenSameCurrency] = useState(false)

  const handleOpen = (open, reservation, item) => {
    setOpen(open);

    const {agentId, payerProfile, promoCode} = reservation;
    const {startDate, endDate, roomBreakdown, propertyId} = item;

    const criteria = {
      searchByPax: true,
      startDate,
      endDate,
      roomBreakdown: [createSearchByPaxRoomBreakdown(roomBreakdown)],
      properties: [{id:propertyId}],
      agentId: agentId,
      loyaltyNo: payerProfile?.loyaltyNo,
      loyaltyTierId: payerProfile?.loyaltyTierId,
      promotionCode: promoCode,
    };

    getResults(criteria);
  }

  return (
    <div>
      <div className={"bn-itinerary-roomtype-container"}>
        <h4>{item.inventoryName}</h4>
        {!isCriteriaItem &&
          <ToolTip content={'Edit your room type'}>
            <div className={"bn-itinerary-edit-button"}
                 onClick={() => handleOpen(!open, reservation, item)}>
              <FontAwesomeIcon icon="fa-solid fa-pencil" />
            </div>
          </ToolTip>}
      </div>

      <Dialog portalId={"bn-itinerary-roomtype-dialog"} open={open}
              onOpenChange={setOpen}>
        <>
          <div className={"bn-dialog-content"}>
            <h1>Edit Room</h1>

            {!busy && <div className={"bn-dialog-text"}>
              <p>This might affect your rate and/or room configuration.</p>
            </div>}

            {busy && <div className={"bn-dialog-busy-container"}> <Spinner size={'10em'} /> </div>}

            <div className={"bn-hotel-rooms-container"}>
              {_.orderBy(property?.inventory,
                [(inventory) => (inventory.rates.length > 0),
                  'inventorySeq',
                  (inventory => {
                    const bestRate = getBestRateForInventory(inventory);
                    return bestRate ? bestRate.bar : Number.MAX_SAFE_INTEGER;
                  })], ['desc', 'asc', 'asc']).map((inventory, idx) => {
                return (<HotelInventory site={site}
                                        inventory={inventory}
                                        property={property}
                                        key={idx}
                                        openSameCurrency={setOpenSameCurrency}
                                        item={item}
                                        reserveCallback={() => setOpen(!open)}
                />)
              })
              }
            </div>

            {(!busy && error) && <div className={"bn-error-message"}>{error}</div>}

          </div>
        </>
      </Dialog>

      <Dialog open={openSameCurrency} onOpenChange={setOpenSameCurrency}>
        <>
          <div className={"bn-dialog-content"}>
            <p>Please note your previous itinerary is in a different currency
              to this one. Please complete or remove that itinerary before
              attempting to book this one.</p>
          </div>
          <div className={"bn-dialog-button-container"}>
            <button
              className={'bn-booknow-button'}
              onClick={() => setOpenSameCurrency(!openSameCurrency)}>OK</button>
          </div>
        </>
      </Dialog>
    </div>
  )
}