import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../styles/main.css'
import {useDispatch, useSelector} from "react-redux";
import {
  getContent,
  getResults,
  selectResults, setAutoSearchDone, setCriteria,
  setPickingStay
} from "../booknow/results/resultsSlice";
import {format, addDays} from 'date-fns'
import {
  PropertySuggestion
} from "../booknow/propertySuggestion/components/PropertySuggestion";
import classNames from "classnames";
import DateRangePicker from "./DateRangePicker";
import GuestPicker from "./GuestPicker";
import Promo from "./Promo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {
  selectPropertySuggestions
} from "../booknow/propertySuggestion/propertySuggestionSlice";
import {getPropertyIds} from "../booknow/propertySuggestion/suggestionsUtil";
import {selectApp} from "../booknow/app/appSlice";
import {updateSessionError} from "../booknow/session/sessionSlice";
import {getResultItems, trackEvent} from "../api/googleTag";
import {getSinglePropertyId} from "../booknow/app/appUtil";
import {isResultsPage} from "../booknow/Util";

export function SearchWidget({autoSearch}) {

  const navigate = useNavigate();
  const location = useLocation();

  const {
    criteria,
    pickingStay,
    busy,
    autoSearchDone,
    suggestionsDone,
    suggestions,
    agent,
    loyaltyProfile,
    instance,
    site,
    singleProperty,
  } = useSelector(
    state => ({
      criteria: selectResults(state).criteria,
      pickingStay: selectResults(state).pickingStay,
      autoSearchDone: selectResults(state).autoSearchDone,
      busy: selectResults(state).busy,
      suggestionsDone: selectPropertySuggestions(state).suggestionsDone,
      suggestions: selectPropertySuggestions(state).suggestions,
      agent: selectApp(state).agent,
      loyaltyProfile: selectApp(state).loyaltyProfile,
      instance: selectApp(state).instance,
      site: selectApp(state).site,
      singleProperty: selectApp(state).singleProperty,
    }));

  const [propertyIds, setPropertyIds] = useState([]);
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departDate, setDepartDate] = useState(addDays(new Date(), site?.minDuration));
  const [pax, setPax] = useState({noOfRooms: 1, noOfAdults: 2, kids: []});
  const [promoCode, setPromoCode] = useState('')
  // const [style, setStyle] = useState({position: 'fixed', left: 0, height: '0', width: '100%'})

  const datePickerRef = useRef(undefined);
  const guestPickerRef = useRef(undefined);
  const searchWidgetRef = useRef(undefined);
  const overlayRef = useRef(undefined);

  const dispatch = useDispatch();
  const setCriteria_ = useCallback( criteria => dispatch(setCriteria({criteria})), [dispatch]);
  const getResults_ = useCallback((criteria) => {
      dispatch(getResults(criteria)).unwrap().then(result => {
        dispatch(getContent(
          {instance, languageId: 1, properties: result.properties}));

        if(result && !result.error) {
          trackEvent("view_results", "results", "view_results",
            getResultItems(result))

          if(singleProperty && isResultsPage(location)) {
            navigate(`/hotel/${result.properties[0].id}`)
          }

        }

      }).catch(error => {
        console.log('getResults error', error)
      })
    },
    [instance, singleProperty, dispatch]);
  const setPickingStay_ = useCallback((pickingStay) => dispatch(
    setPickingStay({pickingStay: pickingStay})), [dispatch]);
  const setAutoSearchDone_ = useCallback(() => dispatch(
    setAutoSearchDone({autoSearchDone: true})), [dispatch]);
  const updateSessionError_ = useCallback(() => dispatch(
    updateSessionError({})), [dispatch]);

  const search = React.useCallback(
    (propertyIds, arrivalDate, departDate, pax, promoCode) => {

      trackEvent("engagement", "clicked", "check_availability");

      const criteria = {
        searchByPax: true,
        startDate: format(arrivalDate, 'yyyy-MM-dd'),
        endDate: format(departDate, 'yyyy-MM-dd'),
        pax,
        properties: propertyIds,
        agentId: agent?.agentId,
        loyaltyNo: loyaltyProfile?.loyaltyNo,
        loyaltyTierId: loyaltyProfile?.loyaltyTierId,
        promotionCode: promoCode,
      };

      updateSessionError_();
      setPickingStay_(false);
      getResults_(criteria)

    }, [getResults_, setPickingStay_, agent, loyaltyProfile]);

  useEffect(() => {

    setPropertyIds(getPropertyIds(suggestions));

  }, [suggestions]);

  useEffect(() => {
    if (!autoSearchDone && !busy && suggestionsDone && site) {

      if(!singleProperty) {
        setPropertyIds(getPropertyIds(suggestions));
      }
      else {
        setPropertyIds([{id: getSinglePropertyId(site)}])
      }

      setAutoSearchDone_();

      const pax_ = {noOfRooms: 1, noOfAdults: site.defaultNoOfAdults, kids: []};
      setPax(pax_)

      if(autoSearch) {
        search(getPropertyIds(suggestions),
          new Date(),
          addDays(new Date(), site.minDuration),
        pax_);
      }
      else {
        setCriteria_({
          searchByPax: true,
          startDate: format(arrivalDate, 'yyyy-MM-dd'),
          endDate: format(departDate, 'yyyy-MM-dd'),
          pax_,
          properties: propertyIds,
          agentId: agent?.agentId,
          loyaltyNo: loyaltyProfile?.loyaltyNo,
          loyaltyTierId: loyaltyProfile?.loyaltyTierId,
        })
      }
    }

  }, [autoSearch, autoSearchDone, busy, site, search,
    setAutoSearchDone_, suggestionsDone,
    suggestions]);

  const handleShowDatePicker = () => {
    datePickerRef?.current?.show();
  }

  const handleShowGuestPicker = () => {
    guestPickerRef?.current?.show()
  }

  const handleSetPropertyIds = (propertyIds, criteria) => {
    setPropertyIds(propertyIds)

    setCriteria_({
      ...criteria,
      properties: propertyIds,
    })
  }

  useEffect(() => {

    if (pickingStay) {
      // const rect = searchWidgetRef.current.getBoundingClientRect();
      // setStyle({...style, top: rect.bottom, height: '100vh', outline: 'none'})
    }
    else {
      // setStyle({...style, top: 0, height: '0'})
    }

  }, [pickingStay]);

  return (
    <>
      <div
        ref={searchWidgetRef}
        className={classNames('bn-search-widget', {
          'selecting-stay': pickingStay
        })}>

        {!singleProperty && <PropertySuggestion
          setPropertyIds={(propertyIds) => handleSetPropertyIds(propertyIds,
            criteria)}
          showNext={handleShowDatePicker}
        />}

        {!singleProperty && <div className={"bn-divider"}/>}

        <DateRangePicker ref={datePickerRef}
                         arrivalDate={arrivalDate}
                         setArrivalDate={setArrivalDate}
                         setDepartDate={setDepartDate}
                         showNext={handleShowGuestPicker}
        />

        <div className={"bn-divider"}/>

        <GuestPicker
          ref={guestPickerRef}
          setPax={setPax}
          doSearch={() => search(propertyIds, arrivalDate, departDate,
            pax, promoCode)}
        />

        {site?.showPromo && <div className={"bn-divider"}/>}

        {site?.showPromo && <Promo setPromoCode={setPromoCode} pickingStay={pickingStay}/>}

        <div className={"bn-button-container"}>
          <Link to={"/"}>
            <button className={"bn-booknow-button"}
                    type={"button"}
                    name={"booknow-button"}
                    onClick={() => search(propertyIds, arrivalDate, departDate,
                      pax, promoCode)}
            ><FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/> SEARCH
            </button>
          </Link>
        </div>
      </div>
      <div
        ref={overlayRef}
        // style={style}
        onClick={() => setPickingStay_(false)}
        className={classNames('overlay', {
          'bn-overlayOn': pickingStay,
          'overlayOff': !pickingStay,
        })}
      />
    </>
  )
}