import {configureStore} from '@reduxjs/toolkit';
import resultReducer from '../booknow/results/resultsSlice'
import alternateResultsReducer from '../booknow/results/alternateResultsSlice'
import propertySuggestionReducer from '../booknow/propertySuggestion/propertySuggestionSlice'
import sessionReducer from '../booknow/session/sessionSlice'
import appReducer from '../booknow/app/appSlice'
import signupReducer from '../booknow/signup/signupSlice'

export const store = configureStore({
  reducer: {
    results: resultReducer,
    alternateResults: alternateResultsReducer,
    propertySuggestions: propertySuggestionReducer,
    session: sessionReducer,
    app: appReducer,
    signup: signupReducer,
  },
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({})
});
