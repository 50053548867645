import {
  calcGrandTotalExcl,
  calcTotals,
  getArrivalDate,
  getDepartDate,
  getItinerary
} from "../booknow/session/reservationUtil";
import {
  getBestRate,
  getBestRateForInventory,
  sortResults_
} from "../booknow/results/resultsUtil";
import _ from "lodash";
import {formatAmount} from "../booknow/Util";

const ANALYTICS_GROUP = "analytics";
const WIHP_GROUP = "wihp";

export const initializeAnalytics = (site) => {
  if(site && window.gtag) {

    const {googleAnalyticsId, name, whipGoogleAnalyticsId} = site;

    // hti tracking
    window.gtag('config', "UA-63955674-1", {
      'groups': ANALYTICS_GROUP,
      'send_page_view': false,
      'brand': name
    });

    // customer tracking
    if (googleAnalyticsId) {
      window.gtag('config', googleAnalyticsId, {
        'groups': ANALYTICS_GROUP,
        'send_page_view': false,
        'brand': name,
        'debug_mode': true,
      })
    }

    // wihp tracking
    if (whipGoogleAnalyticsId) {
      window.gtag('config', 'AW-' + whipGoogleAnalyticsId, {
        'groups': WIHP_GROUP,
        'send_page_view': false,
        'brand': name
      });

      window.gtag('config', 'HA-75', {
        'groups': WIHP_GROUP,
        'send_page_view': false,
        'conversion_cookie_prefix': '_ha',
        'brand': name
      });
    }
  }
}

export const trackPageView = (page_path, site, property) => {
  if(site && window.gtag) {

    const {name, whipGoogleAnalyticsId} = site;

    let sendto = [ANALYTICS_GROUP];

    if (whipGoogleAnalyticsId) {
      sendto.push(WIHP_GROUP);
    }

    if (property) {
      window.gtag('event', 'page_view',
        {
          'page_path': page_path,
          'send_to': sendto,
          'hotel_id': property.code,
          'brand_id': name,
        });
    } else {
      window.gtag('event', 'page_view',
        {
          'page_path': page_path,
          'send_to': sendto,
          'brand_id': name,
        });
    }
  }
}

export const trackEvent = (action, category, label, items) => {
  if (window.gtag) {
    window.gtag('event', action, {
      'send_to': ANALYTICS_GROUP,
      'event_category': category,
      'event_label': label,
      ...items,
    });
  }
}

function trackConversion_(site, reservation, grandTotal, taxesTotal, currencyCode,
  arrivalDate, departureDate, items, event) {

  window.gtag('event', event, {
    'send_to': ANALYTICS_GROUP,
    'transaction_id': reservation.reservationNo,
    'value': grandTotal,
    'tax': taxesTotal,
    'currency': currencyCode,
    'arrival_date': arrivalDate,
    'departure_date' : departureDate,
    'items': items
  });

}

export const trackConversion = (reservation, site, event = 'purchase') => {
  if(reservation && site && window.gtag) {
    try {

      const totals = calcTotals(reservation);
      const item = getItinerary(reservation).find(
        item => item.currencyCode !== undefined);

      const grandTotal = formatAmount(calcGrandTotalExcl(totals, item.currencyCode));
      const taxesTotal = formatAmount((totals.taxInclusive[item.currencyCode].taxesTotal
        + totals.taxExclusive[item.currencyCode].taxesTotal))

      const arrivalDate = getArrivalDate(getItinerary(reservation));
      const departDate = getDepartDate(reservation);

      const items = getItems(reservation, site);

      trackConversion_(site, reservation, grandTotal, taxesTotal,
        item.currencyCode, arrivalDate, departDate, items, event)

    } catch (error) {
      console.log('Could not track conversion: ', error)
    }
  }
}

function getItems(reservation, site) {
  return getItinerary(reservation).map((item, index) => ({
      item_id: `${item.inventory.code}_${item.rateCode}`,
      item_name: item.inventoryName,
      arrival_date: item.startDate,
      departure_date: item.endDate,
      item_brand: site.name,
      item_category: item.propertyName,
      price: item.amountInclTax,
      item_quantity: item.roomBreakdown.length,
      item_hotel_id: item.property.code,
      item_rate_code: item.rateCode,
      item_room_id: item.inventory.code,
  }));
}

export const getHotelItem = (property, index) => {
  return {
    id: property.code,
    name: property.name,
    best_rate: getBestRate(property) ? getBestRate(property).bar : 'Not available',
    currency: getBestRate(property) ? getBestRate(property).currency.code : 'N/A',
    result_position: index
  }
}

export const getResultItems = (results) => {
  const sortedResults = sortResults_(results.properties||[]);

  return {
    item_list_id: "hotel_results",
    item_list_name: "Hotel Results",
    items: (sortedResults).map((property, index) => (getHotelItem(property, index)))
  }
}

export const getRoomRateItem = (site, property, inventory, rate) => {
  const rateCode = (rate.rateBreakdown||[])[0].rateCode

  return ({
    item_id: `${inventory.code}_${rateCode?.code}`,
    item_name: inventory.name,
    item_room_id: inventory.code,
    item_category: property.name,
    item_brand: site.name,
    price: rate.total,
    item_rate_code: rateCode?.code,
    currency: rate.currency.code,
    item_hotel_id: property.code,
  })
}

export const getRoomRateItems = (site, property) => {
  try {
    const items = _.sortBy(property?.inventory, 'inventorySeq',
      (inventory => {
        const bestRate = getBestRateForInventory(inventory);
        return bestRate ? bestRate.bar : Number.MAX_SAFE_INTEGER;
      })).map(inventory => {
      return (inventory.rates || []).map(
        rate => (getRoomRateItem(site, property, inventory, rate)))
    })

    return {
      item_list_id: "room_rate_list",
      item_list_name: "Room Rates",
      items: items
    }
  } catch (error) {
    console.log(error)
  }
}

export const getRoomRateItemFromItem = (item, price) => {
  return ({
    room_id: item.inventory.code,
    name: item.inventory.name,
    category: item.property.name,
    price: price,
    rate_code: item.rateCode,
    currency: item.currencyCode,
    hotel_id: item.property.code,
  })
}