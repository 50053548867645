import {useEffect, useState} from "react";
import {getStorageRefs} from "../api/firebaseApi";

export default function useStorageRefs(path, refetch) {

  const [state, setState] = useState({
    busy: false,
    storageRefs: [],
    error: '',
  })

  useEffect(() => {

    setState({
      storageRefs: [],
      busy: true,
      error: '',
    })

    const fetch = async () => {
      try {
        const {storageRefs, error} = await getStorageRefs(path);

        setState({
          storageRefs,
          busy: false
        })

      } catch (error) {
        console.log('useImageData error', error)
        setState({
          storageRefs: [],
          busy: false,
          error
        })
      }
    }

    fetch();

  }, [path, refetch]);

  return state;

}