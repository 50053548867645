import Dialog from "../../../components/Dialog";
import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import TextInputItem from "../../../components/TextInputItem";
import {
  changePassword,
  clearError,
  convertAgent,
  login,
  selectApp
} from "../appSlice";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  getErrorTypeDescription,
  USER_CHANGE_PASSWORD
} from "../../../api/errorTypes";
import {Spinner} from "../../../components/Spinner";
import {useNavigate} from "react-router";
import {getResults, selectResults} from "../../results/resultsSlice";
import {storeAgentLogin} from "../../Util";
import {getValidationFn, passwordValid} from "../../signup/signupUtil";
import {getPasswordRestrictions, selectSignup} from "../../signup/signupSlice";
import ForgetPassword from "./ForgetPassword";
import {useLocation} from "react-router-dom";
import {trackEvent} from "../../../api/googleTag";
import {getSinglePropertyId} from "../appUtil";

export default React.memo(({open, autoSearch, onOpenChange}) => {

  const navigate = useNavigate();
  const location = useLocation()

  const {loginBusy,
    errorType,
    criteria,
    passwordRestrictions,
    site, singleProperty} = useSelector(
      state => ({
        loginBusy: selectApp(state).loginBusy,
        errorType: selectApp(state).errorType,
        criteria: selectResults(state).criteria,
        passwordRestrictions: selectSignup(state).passwordRestrictions,
        site: selectApp(state).site,
        singleProperty: selectApp(state).singleProperty,
      }));

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [pswdChangeSuccess, setPswdChangeSuccess] = useState(false);
  const [forgetPassword, setForgetPassword] = useState(false);

  const dispatch = useDispatch();
  const login_ = React.useCallback(
      (username, password) => dispatch(login({username, password})),
      [dispatch]);
  const changePassword_ = React.useCallback(
      (username, oldPassword, password) => dispatch(
          changePassword({username, oldPassword, password})), [dispatch]);
  const fetchPasswordRestrictions = React.useCallback(() => dispatch(getPasswordRestrictions()),
      [dispatch]);
  const clearLoginError = React.useCallback(() => dispatch(clearError()), [dispatch]);
  const getResults_ = useCallback((criteria) => dispatch(getResults(criteria)),
      [dispatch]);

  useEffect(() => {
    if(open) {
      setUsername('');
      setPassword('');
      setNewPassword('');

      fetchPasswordRestrictions();
    }
  }, [open]);

  const handleLogin = () => {
    setPswdChangeSuccess(false);

    trackEvent("engagement", "clicked", "agent_login");

    login_(username, password).then(r => {
      if (!r.error) {
        onOpenChange(false);

        const agent = convertAgent(r.payload);
        storeAgentLogin(agent);

        if(location.pathname.indexOf("/confirmation/") === -1) { // only redict if not on confirmation page
          navigate(!singleProperty ? '/' : `/hotel/${getSinglePropertyId(site)}`)

          if(autoSearch) {
            getResults_(Object.assign({}, criteria, {agentId: agent.agentId}));
          }
        }
      }
    })
  }

  const handleChangePassword = () => {
    changePassword_(username, password, newPassword).then(r => {
      if(!r.error) { // change successfull
        setPswdChangeSuccess(true)
        setPassword('')
      }
    })
  }

  const handleOpenChange = (open) => {
    onOpenChange(open)
    handlePswForgetEmailSent();
  };

  const handleCancel = () => {
    clearLoginError();
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  const handlePswForgetEmailSent = () => {
    setForgetPassword(false);
  }

  return (
      <Dialog open={open} onOpenChange={handleOpenChange}>
        <>
          <div className={"bn-dialog-content"}>
            {!forgetPassword && <div className={"bn-login-container"}>
              <h1>Agent Login</h1>
              <div className={"login-input-container"}>
                <div className={classNames("bn-error-message",
                    {
                      ['bn-show']: errorType !== undefined,
                      ['bn-hidden']: errorType === undefined,
                    })}>
                  <FontAwesomeIcon icon="fa-solid fa-circle-exclamation"/>
                  {getErrorTypeDescription(errorType)}
                </div>
                {pswdChangeSuccess && <div className={"bn-success-message"}>
                  <FontAwesomeIcon icon="fa-solid fa-check"/>
                  Password change successful. Please login.
                </div>}
              </div>
              <div className={classNames("bn-inputs-group")}>
                {errorType !== USER_CHANGE_PASSWORD && <div
                    className={"bn-inputs-left"}>
                  <TextInputItem className={"bn-group bn-input-item"}
                                 type={"text"}
                                 id={"username"}
                                 label={"Username"}
                                 placeholder={"Username"}
                                 mandatory={true}
                                 maxLength={50}
                                 value={username}
                                 onChange={(event) => setUsername(
                                 event.target.value)}
                  />
                  <TextInputItem className={"bn-group bn-input-item"}
                                 type={"password"}
                                 id={"password"}
                                 label={'Password'}
                                 placeholder={'Password'}
                                 mandatory={true}
                                 maxLength={50}
                                 value={password}
                                 onChange={(event) => setPassword(
                                 event.target.value)}
                                 onKeyPress={event => onKeyPress(event)}
                  />
                </div>}
                {errorType === USER_CHANGE_PASSWORD && <div
                    className={"bn-inputs-left"}>
                  <TextInputItem className={"bn-group bn-input-item"}
                                 type={"password"}
                                 id={"currentPassword"}
                                 label={"Current password"}
                                 placeholder={"Current password"}
                                 mandatory={true}
                                 maxLength={50}
                                 value={password}
                                 onChange={(event) => setPassword(
                                 event.target.value)}
                  />
                  <TextInputItem className={"bn-group bn-input-item"}
                                 type={"password"}
                                 id={"password"}
                                 label={'New password'}
                                 placeholder={'New password'}
                                 mandatory={true}
                                 maxLength={50}
                                 value={newPassword}
                                 onChange={(event) => setNewPassword(
                                 event.target.value)}
                                 onKeyPress={event => onKeyPress(event)}
                  />
                </div>}
              </div>
            </div>}
            {forgetPassword &&
                <div className={"bn-login-container"}>
                  <h1>Forget Password</h1>
                  <ForgetPassword onEmailSent={() => handlePswForgetEmailSent()}
                                  onCancelClick={() => setForgetPassword(false)} />
                </div>
            }
            {errorType === USER_CHANGE_PASSWORD && <div className={"bn-password-restrictions"}>
              <div className={"bn-password-progress"}>
                {passwordRestrictions.map((restriction, idx) => {
                  const validate = getValidationFn(restriction, newPassword);
                  return (
                      <div key={idx} className={classNames('bn-progress-item', {
                        'valid': validate(restriction, newPassword)
                      })}/>)
                })}
              </div>
              <ul>
                {passwordRestrictions.map((restriction, idx) => {
                  const validate = getValidationFn(restriction, newPassword);
                  return (
                      <li key={idx} className={classNames('bn-restriction-item', {
                        'valid': validate(restriction, newPassword)
                      })}><FontAwesomeIcon
                          icon="fa-solid fa-check"/> {restriction.name}</li>)
                })}
              </ul>
            </div>}
          </div>
          {!forgetPassword && (errorType !== USER_CHANGE_PASSWORD) && <div className={"bn-dialog-button-container"}>
            {!loginBusy && <button
                className={'bn-booknow-button'}
                onClick={() => handleLogin()}
                disabled={!password}>Login</button>}
            {loginBusy && <Spinner size={'45px'}/>}
          </div>}
          {errorType === USER_CHANGE_PASSWORD &&
            <div className={"bn-dialog-button-container"}>
              {!loginBusy && <button
                  className={'bn-booknow-button'}
                  onClick={() => handleChangePassword()}
                  disabled={
                      !password
                      || !newPassword
                      || !passwordValid(passwordRestrictions, newPassword)}>OK</button>}
              {loginBusy && <Spinner size={'45px'}/>}
              {!loginBusy && <button className={'bn-booknow-button'}
                                     onClick={() => handleCancel()}>Cancel</button>}
            </div>
          }
          {!forgetPassword && <div className={"bn-agent-login-signup-container"}>
            <span onClick={() => setForgetPassword(true)}>Forgot Password?</span>
            {/*<span>Signup</span>*/}
          </div>}
        </>
      </Dialog>
  )
})