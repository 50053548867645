import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
  buildBookRQ, buildCommitRQ, buildTempHoldReleaseRQ, calcPointsToRedeemForItem,
  convertReservation, getReservation, storeReservation
} from "./reservationUtil";
import {
  buildDataSourcePayload,
  client, DIRECT_RESERVATION,
  DIRECT_SEARCH,
  getApiUrl,
  NEBULA_REST
} from "../../api/client";
import {selectApp} from "../app/appSlice";
import _ from "lodash";

const sessionSlice = createSlice({
      name: 'session',
      initialState: {
        reservation: undefined,
        busy: false,
      },
      reducers: {
        updateReservation: (state, action) => {
          const {reservation} = action.payload;
          state.reservation = reservation;
          state.error = undefined;
        },
        restoreReservation: (state, action) => {
          state.reservation = getReservation();
        },
        updatePayerProfile: (state, action) => {
          const {payerProfile} = action.payload;

          if(state.reservation) {
            state.reservation = Object.assign({}, state.reservation,
              {payerProfile: payerProfile})
          }
        },
        updateRoomProfile: (state, action) => {
          const {itineraryIndex, roomIndex, roomProfile} = action.payload;
          state.reservation = Object.assign({}, state.reservation,
              {
                itinerary: state.reservation.itinerary.map((item, index) => {
                  if (index === itineraryIndex) {
                    return {
                      ...item,
                      roomBreakdown: item.roomBreakdown.map((room, roomIdx) => {
                        if (roomIdx === roomIndex) {
                          if (room.roomProfiles === undefined) {
                            return {
                              ...room,
                              roomProfiles: [roomProfile]
                            }
                          } else {
                            return {
                              ...room,
                                roomProfiles: room.roomProfiles.map(
                                  (roomProfile_, profileIndex) => {
                                    if (profileIndex === 0) {
                                      return {
                                        ...roomProfile_,
                                        profile: roomProfile.profile,
                                        specialRequests: roomProfile.specialRequests,
                                        additionalProfiles: roomProfile.additionalProfiles,
                                      }
                                    } else {
                                      return roomProfile_;
                                    }
                                  })
                            }
                          }
                        } else {
                          return room;
                        }
                      })
                    }
                  } else {
                    return item;
                  }
                })
              })
        },
        clearRoomProfiles: (state, action) => {
          if(state.reservation && state.reservation.itinerary) {
            state.reservation = Object.assign({}, state.reservation,
              {
                itinerary: state.reservation.itinerary.map((item, index) => {
                  return {
                    ...item,
                    roomBreakdown: item.roomBreakdown.map((room, roomIdx) => {
                      return {
                        ...room,
                        roomProfiles: room.roomProfiles?.map(
                          (roomProfile_, profileIndex) => {
                            return {
                              ...roomProfile_,
                              profile: {
                                title: 'Mr',
                                titleId: 1,
                                countryId: 197, // defaults
                              },
                              additionalProfiles: []
                            }
                          })
                      }
                    })
                  }
                })
              })
          }
        },
        updateRedeem: (state, action) => {
          const reservation = state.reservation;
          const {redeem} = action.payload;

          state.reservation = Object.assign({}, reservation,
            {itinerary: reservation.itinerary.map(item => {
              return Object.assign({}, item,
                {
                  pointsToRedeem: calcPointsToRedeemForItem(reservation, redeem.pointsToRedeem, item),
                  loyaltyActionId: redeem.loyaltyActionId,
                  redemptionTypeId: redeem.redemptionTypeId,
                })
            })})
        },
        updateSessionError: (state, action) => {
          state.error = action.payload.error;
        }
      },
      extraReducers(builder) {
        // Book
        builder.addCase(doBook.pending, (state, action) => {
          state.bookBusy = true;
          state.error = undefined;
        })
        .addCase(doBook.fulfilled, (state, action) => {
          const data = action.payload;
          state.bookBusy = false;
          state.reservation = convertReservation(state.reservation, data);

          storeReservation(state.reservation)
        })
        .addCase(doBook.rejected, (state, action) => {
          state.bookBusy = false;
          state.error = action.error.message
        })

        // Commit
        .addCase(doCommit.pending, (state) => {
          state.commitBusy = true;
          state.error = undefined;
        })
        .addCase(doCommit.fulfilled, (state, action) => {
          const data = action.payload;
          state.commitBusy = false;
          state.reservation = convertReservation(state.reservation, data);

          storeReservation(state.reservation)
        })
        .addCase(doCommit.rejected, (state, action) => {
          state.commitBusy = false;
          state.error = action.error.message
        })

        // fetch Reservation
        .addCase(doFetchReservation.pending, (state) => {
          state.fetchBusy = true;
          state.reservation = undefined;
          state.error = undefined;
        })
        .addCase(doFetchReservation.fulfilled, (state, action) => {
          state.fetchBusy = false;
          const reservation = _.first(action.payload.reservations);

          if(reservation) {
            state.reservation = convertReservation({}, reservation);

            storeReservation(state.reservation)
          }
          else {
            state.error = 'Reservation not found.'
          }
        })
        .addCase(doFetchReservation.rejected, (state, action) => {
          state.fetchBusy = false;
          state.error = action.error.message
        })

        // Cancel
        .addCase(doCancel.pending, (state, action) => {
          state.busy = true;
          state.error = undefined;
        })
        .addCase(doCancel.fulfilled, (state, action) => {
          const data = action.payload;
          state.busy = false;

          state.reservation = convertReservation(state.reservation, data);

          storeReservation(state.reservation)
        })
        .addCase(doCancel.rejected, (state, action) => {
          state.busy = false;
          state.error = action.error.message
        })

        // release temphold
        .addCase(releaseTemphold.fulfilled, (state, action) => {
        })
      }
    }
);

export const doBook = createAsyncThunk('session/book',
    async (params,{getState}) => {
      const {reservation, reserve} = params;
      const {customer, token, agent, connectionCode} = selectApp(getState())

      let itinerary = reservation.itinerary.slice();
      if(!reserve.id) { // add new only
        itinerary.push(reserve);
      }
      else {
        itinerary[itinerary.findIndex(i => i.id === reserve.id)] = reserve;
      }

      return await client.post(getApiUrl(customer, DIRECT_RESERVATION),
          buildBookRQ(reservation, itinerary, agent, connectionCode), token)
    });

export const doCommit = createAsyncThunk('session/commit',
    async (params, {getState}) => {
      const {reservation} = params;
      const {customer, token, connectionCode} = selectApp(getState())

      return await client.post(getApiUrl(customer, DIRECT_RESERVATION),
          buildCommitRQ(reservation, connectionCode), token);
    });

export const doFetchReservation = createAsyncThunk('session/fetchReservation',
    async (params, {getState}) => {
      const {reservationNo, email} = params;
      const {customer, token} = selectApp(getState())

      return await client.post(getApiUrl(customer, DIRECT_SEARCH),
          {
            criteria: {
              reservationId: reservationNo,
              email,
              includePast: true,
              includeCxl: true,
            },
          }, token);
    });

export const doCancel = createAsyncThunk('session/cancel', async (params, {getState}) => {
  const {reservation, indexToCancel} = params;
  const {customer, token} = selectApp(getState())
  const itinerary = reservation.itinerary.slice();
  const cancelledItinerary = itinerary[indexToCancel];
  itinerary.splice(indexToCancel, 1);

  return await client.post(getApiUrl(customer, DIRECT_RESERVATION),
      buildBookRQ(Object.assign({}, reservation, {
        _remove: [
          {itinerary: [{id: cancelledItinerary.id}]}
        ]
      }), itinerary), token)

});

export const releaseTemphold = createAsyncThunk('session/releaseTemphold',
    async (params, {getState}) => {
      const {sessionId} = params;
      const {customer, token} = selectApp(getState())

      return await client.post(getApiUrl(customer, NEBULA_REST), buildTempHoldReleaseRQ(sessionId), token)
    });

export const getPayNowUrl = createAsyncThunk('session/getPayNowUrl',
  async (params, {getState}) => {
    const {reservation, amount} = params;
    const {customer, token} = selectApp(getState())

    const request = buildDataSourcePayload( {
      amount,
      reservation: buildCommitRQ(reservation),
    }, 'AvailableRates', 'custom', 'getPayNowUrl')


    return await client.post(getApiUrl(customer, NEBULA_REST), request, token)
  });

export const selectSession = state => state.session;

export const {updateReservation,
  restoreReservation,
  updatePayerProfile,
  updateRoomProfile,
  clearRoomProfiles,
  updateRedeem,
  updateSessionError,
} = sessionSlice.actions;

export default sessionSlice.reducer