import React, {useCallback, useEffect, useRef, useState} from "react";
import {addDays, format} from "date-fns";
import {
  getContent,
  getResults,
  selectResults, setCriteria
} from "../booknow/results/resultsSlice";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
  selectPropertySuggestions
} from "../booknow/propertySuggestion/propertySuggestionSlice";
import {selectApp} from "../booknow/app/appSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {
  PropertySuggestion
} from "../booknow/propertySuggestion/components/PropertySuggestion";
import DateRangePicker from "./DateRangePicker";
import GuestPicker from "./GuestPicker";
import {FloatingOverlay} from "@floating-ui/react";
import {getPropertyIds} from "../booknow/propertySuggestion/suggestionsUtil";
import {formatDate, isResultsPage} from "../booknow/Util";
import Promo from "./Promo";
import {updateSessionError} from "../booknow/session/sessionSlice";
import {getResultItems, trackEvent} from "../api/googleTag";

export function SearchWidgetMobile() {

  const {id: propertyId} = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const {
    criteria,
    busy,
    suggestions,
    agent,
    loyaltyProfile,
    instance,
    site,
    singleProperty,
  } = useSelector(
    state => ({
      criteria: selectResults(state).criteria,
      autoSearchDone: selectResults(state).autoSearchDone,
      busy: selectResults(state).busy,
      suggestionsDone: selectPropertySuggestions(state).suggestionsDone,
      suggestions: selectPropertySuggestions(state).suggestions,
      agent: selectApp(state).agent,
      loyaltyProfile: selectApp(state).loyaltyProfile,
      instance: selectApp(state).instance,
      site: selectApp(state).site,
      singleProperty: selectApp(state).singleProperty,
    }));

  const [propertyIds, setPropertyIds] = useState([]);
  const [suggestion, setSuggestion] = useState(undefined);
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [departDate, setDepartDate] = useState(addDays(new Date(), 1));
  const [pax, setPax] = useState({noOfRooms: 1, noOfAdults: 2, kids: []});
  const [promoCode, setPromoCode] = useState('')
  const [showItems, setShowItems] = useState(false);
  const [searchTerm, setSearchTerm] = useState('Anywhere')

  const datePickerRef = useRef(undefined);
  const guestPickerRef = useRef(undefined);

  useEffect(() => {

    setPropertyIds(getPropertyIds(suggestions));

  }, [suggestions]);

  useEffect(() => {
    const pax_ = {noOfRooms: 1, noOfAdults: site.defaultNoOfAdults, kids: []};
    setPax(pax_)
  }, [site]);

  const dispatch = useDispatch();
  const setCriteria_ = useCallback( criteria => dispatch(setCriteria({criteria})), [dispatch]);
  const getResults_ = useCallback((criteria) => {
      dispatch(getResults(criteria)).unwrap().then(result => {
        dispatch(getContent(
          {instance, languageId: 1, properties: result.properties}));

        if(result && !result.error) {
          trackEvent("view_results", "results", "view_results",
            getResultItems(result))

          if(singleProperty && isResultsPage(location)) {
            navigate(`/hotel/${result.properties[0].id}`)
          }
        }

      }).catch(error => {
        console.log('getResults error', error)
      })
    },
    [instance, singleProperty, dispatch]);
  const updateSessionError_ = useCallback(() => dispatch(
    updateSessionError({})), [dispatch]);

  const search = React.useCallback(
    (propertyIds, arrivalDate, departDate, pax, promoCode) => {

      trackEvent("engagement", "clicked", "check_availability");

      setShowItems(false)

      const criteria = {
        searchByPax: true,
        startDate: format(arrivalDate, 'yyyy-MM-dd'),
        endDate: format(departDate, 'yyyy-MM-dd'),
        pax,
        properties: [{id: Number.parseInt(propertyId)}],
        ...(!propertyId && {properties: propertyIds}),
        agentId: agent?.agentId,
        loyaltyNo: loyaltyProfile?.loyaltyNo,
        loyaltyTierId: loyaltyProfile?.loyaltyTierId,
        promotionCode: promoCode,
      };

      updateSessionError_();
      getResults_(criteria);

    }, [getResults_, propertyId, agent, loyaltyProfile]);

  const handleShowDatePicker = () => {
    datePickerRef?.current?.show();
  }

  const handleShowGuestPicker = () => {
    guestPickerRef?.current?.show()
  }

  const handleSetPropertyIds = (propertyIds, criteria) => {
    setPropertyIds(propertyIds)

    setCriteria_({
      ...criteria,
      properties: propertyIds,
    })
  }

  useEffect(() => {
    const noOfGuests = (pax.noOfAdults + pax.kids.length);
    const guests = noOfGuests > 1 ? 'Guests' : 'Guest';

    if(!singleProperty) {
      setSearchTerm(
        `${suggestion?.name ?? 'Anywhere'},  ${formatDate(arrivalDate,
          'dd MMM')} - ${formatDate(departDate, 'dd MMM')}
       , ${noOfGuests} ${guests}`)
    } else {
      setSearchTerm(`${formatDate(arrivalDate,
        'dd MMM')} - ${formatDate(departDate, 'dd MMM')}
       , ${noOfGuests} ${guests}`)
    }
    
  }, [suggestion, arrivalDate, departDate, pax]);

  return (<div className={'bn-search-widget-mobile'}>
    <div className={'bn-search-widget-where'}
         onClick={() => setShowItems(!showItems)}>
      <FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/>
      <div>
        <strong className={'label'}>{!singleProperty ? 'Where' : 'When'}</strong>
        <div>{searchTerm}</div>
      </div>
    </div>

    <FloatingOverlay
      lockScroll={showItems}
      id={'bn-search-widget-overlay'}
      className={classNames('bn-search-widget-items', {
        'bn-show': showItems
      })}>

      <div className={"bn-search-widget-header"}>
        <FontAwesomeIcon
          className={"bn-svg-button"}
          onClick={() => setShowItems(false)}
          icon="fa-xmark"/>
      </div>

      {!singleProperty && <PropertySuggestion setPropertyIds={(propertyIds) => handleSetPropertyIds(propertyIds, criteria)}
                          setSuggestion={setSuggestion}
                          closeOnScroll={false}
                          portalId={'bn-suggestion-portal-mobile'}
                          closeOnSelect={true}
                          showNext={handleShowDatePicker}
      />}

      <DateRangePicker ref={datePickerRef}
                       arrivalDate={arrivalDate}
                       setArrivalDate={setArrivalDate}
                       setDepartDate={setDepartDate}
                       closeOnScroll={false}
                       portalId={'bn-date-picker-portal-mobile'}
                       showNext={handleShowGuestPicker}
      />

      <GuestPicker ref={guestPickerRef}
                   setPax={setPax}
                   closeOnScroll={false}
                   doSearch={() => search(propertyIds, arrivalDate, departDate,
                     pax, promoCode)}
      />

      {site?.showPromo && <Promo setPromoCode={setPromoCode} closeOnScroll={false} />}

      <div className={"bn-button-container"}>
        <Link to={"/"}>
          <button className={"bn-booknow-button"}
                  type={"button"}
                  name={"bn-booknow-button"}
                  onClick={() => search(propertyIds, arrivalDate, departDate,
                    pax, promoCode)}
          ><FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/> SEARCH
          </button>
        </Link>
      </div>
    </FloatingOverlay>
  </div>)

}