import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {doBook, selectSession} from "../sessionSlice";
import Dialog from "../../../components/Dialog";
import Select from "react-select";
import ToolTip from "../../../components/ToolTip";
import useRestFetch, {CUSTOM} from "../../../hooks/useRestFetch";
import {selectApp} from "../../app/appSlice";
import {buildCalcAmountRQ} from "../reservationUtil";
import {formatAmount, getInstanceName, isDev} from "../../Util";
import {Spinner} from "../../../components/Spinner";
import {getRoomRateItemFromItem, trackEvent} from "../../../api/googleTag";
import useFirestoreGetDocs from "../../../hooks/useFirestoreGetDocs";
import {getInventoryQuery} from "../../../api/firebaseApi";
import _ from "lodash";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const SelectRoom = ({count, className, noOfRooms, onChange}) => {
  let options = [];

  for(let i = 1; i < (count+1); i++) {
    options.push({ value: i, label:i})
  }
  return (
    <div className={"bn-group bn-input-item"}>
      <label htmlFor={"bn-rb-rooms"}>Rooms</label>
      <Select id={"bn-rb-rooms"} value={{value: noOfRooms, label: noOfRooms}}
              isSearchable={false}
              className={className}
              onChange={(option) => onChange(option)}
              options={options}/>
    </div>)
}

const SelectAdults = ({room, inventory, className, onChange}) => {

  const calcMaxAdults = (room, inventory) => {
    const {maxPax, maxAdults} = inventory;
    let maxPax_ = maxPax !== 0 ? maxPax : 99;
    let maxAdults_ = maxAdults !== 0 ? maxAdults : 99;

    if(maxPax_ < maxAdults_) {
      maxAdults_ = maxPax_;
    }

    return maxAdults_;
  }

  const maxAdults = calcMaxAdults(room, inventory);

  let options = [];
  options.push({value: 0, label: 'None'})

  for(let i = 1; i < (maxAdults+1); i++) {
    options.push({ value: i, label: i})
  }
  return (
    <div className={"bn-group bn-input-item"}>
      <label htmlFor={"bn-rb-adults"}>Adults</label>
      <Select id={"bn-rb-adults"}
              value={{value: room.noOfAdults, label: room.noOfAdults}}
              className={className}
              isSearchable={false}
              onChange={option => onChange(option)}
              options={options}
      />
    </div>
  )
}

const SelectKids = ({room, inventory, className, onChange}) => {

  const calcMaxKids = (room, inventory) => {
    const {maxPax, maxKids} = inventory;
    const {noOfAdults} = room;

    let maxPax_ = maxPax !== 0 ? maxPax : 99;
    let maxKids_ = maxKids !== 0 ? maxKids : 99;

    if((maxPax_-noOfAdults) < maxKids_) {
      maxKids_ = (maxPax_-noOfAdults);
    }

    return maxKids_;
  }

  const maxKids = calcMaxKids(room, inventory);

  let options = [];
  options.push({value: 0, label: 'None'})

  for(let i = 1; i < (maxKids+1); i++) {
    options.push({ value: i, label: i})
  }

  return (
    <div className={"bn-group bn-input-item"}>
      <label htmlFor={"bn-rb-kids"}>Children</label>
      <Select id={"bn-rb-kids"}
              value={{value: room.kids.length, label: room.kids.length}}
              className={className}
              isSearchable={false}
              onChange={option => onChange(option)}
              options={options}
      />
    </div>
  )
}

const SelectKidAges = ({count, className, value, onChange}) => {
  let options = [];

  for(let i = 0; i < (count+1); i++) {
    options.push({ value: i, label: i})
  }

  return (
    <div className={"bn-group bn-input-item"}>
      <label htmlFor={"bn-rb-kidAges"}>Age</label>
      <Select id={"bn-rb-kidAges"}
              value={{value: value, label: value}}
              className={className}
              isSearchable={false}
              onChange={option => onChange(option)}
              options={options}
      />
    </div>
  )
}

export function RoomBreakdown({item}) {

  const {reservation, customer, token, site, sessionError} = useSelector(
    state => ({
      reservation: selectSession(state).reservation,
      customer: selectApp(state).customer,
      token: selectApp(state).token,
      site: selectApp(state).site,
      sessionError: selectSession(state).error,
    }));

  const [open, setOpen] = useState(false)
  const [roomBreakdown, setRoomBreakdown] = useState([])
  const [amountInclTax, setAmountInclTax] = useState(0.00)
  const [maxRooms, setMaxRooms] = useState(99)
  const [bookBusy, setBookBusy] = useState(false)

  const {fetch: fetchData, busy, error: fetchError} = useRestFetch();
  const {
    fetchData: fetchAvailability,
    data,
    busy: fireStorebusy,
    error: firestoreError
  } = useFirestoreGetDocs();

  const dispatch = useDispatch();
  const book = useCallback((params) => dispatch(doBook(params)),
    [dispatch]);

  useEffect(() => {
    setRoomBreakdown(item?.roomBreakdown ?? [])
    setAmountInclTax(item?.amountInclTax ?? 0.00)

    fetchAvailability(
      getInventoryQuery(isDev() ? 'demo' : getInstanceName(customer.api_url),
        item.propertyId, item.inventoryId, item.startDate, item.endDate),
      (docs) => calcNoOfRoomsAvailable(item, docs, site))

  }, [item, open]);

  const {inventory} = (item||{});

  const calcNoOfRoomsAvailable = (item, docs, site) => {
    const minAvailability = _.minBy(docs, 'roomsRemaining');
    const roomsRemaining = _.max([(minAvailability?.roomsRemaining ?? 99), 0]);
    const noOfRoomsBooked = item.roomBreakdown.length;

    const siteMaxRooms = site?.maxRooms ?? 99;

    setMaxRooms(_.min([(roomsRemaining + noOfRoomsBooked), siteMaxRooms]));
  }
  const onRoomChange = (roomBreakdown, noOfRooms) => {
    if(roomBreakdown.length < noOfRooms) { // add

      const breakdown =
        roomBreakdown.concat(
          Array(noOfRooms - roomBreakdown.length)
          .fill({
            noOfAdults: 1,
            kids: [],
          }));

      setRoomBreakdown(breakdown)
      calcAmounts(item, breakdown);
    }
    else { // remove
      const breakdown = roomBreakdown.slice(0, noOfRooms);
      setRoomBreakdown(breakdown)
      calcAmounts(item, breakdown)
    }
  }

  const onAdultsChange = (index, noOfAdults) => {
    let breakdown = roomBreakdown.slice();
    breakdown[index] = Object.assign({}, breakdown[index], {noOfAdults});

    setRoomBreakdown(breakdown);

    calcAmounts(item, breakdown)
  }

  const onKidsChange = (index, noOfKids) => {
    let kids = (roomBreakdown[index].kids||[]).slice()
    let currentLength = (roomBreakdown[index].kids||[]).length;
    if (currentLength < noOfKids) {
      for (let i = currentLength; i < noOfKids; i++) {
        kids.push(0);
      }
    } else {
      kids = kids.slice(0, noOfKids);
    }

    let breakdown = roomBreakdown.slice();
    breakdown[index] = Object.assign({}, breakdown[index], {kids});
    setRoomBreakdown(breakdown);

    calcAmounts(item, breakdown)
  }

  const onKidAgeChange = (roomIndex, kidIndex, age) => {
    let kids = (roomBreakdown[roomIndex].kids||[]).slice();

    kids[kidIndex] = Number.parseInt(age);

    let breakdown = roomBreakdown.slice();
    breakdown[roomIndex] = Object.assign({}, breakdown[roomIndex], {kids});
    setRoomBreakdown(breakdown)

    calcAmounts(item, breakdown)
  }

  const calcAmounts = (item, roomBreakdown) => {

    const itemWithUpdatedRoomBreakdown = Object.assign({}, item, {roomBreakdown: roomBreakdown})

    fetchData(buildCalcAmountRQ(itemWithUpdatedRoomBreakdown),
      'PendingRateBreakdown', 'calcAmounts', CUSTOM, customer,
      token).then((response) => {

      if (response.length > 0) {
        const responseItem = response[0];

        setAmountInclTax(responseItem.amountInclTax);
      }

    }).catch(error => {
      console.log(error)
    })

  }

  const handleUpdate = (reservation, amountInclTax, open) => {
    setBookBusy(true)

    trackEvent("edit_cart", "clicked", "edit_itinerary", {items: [getRoomRateItemFromItem(item, amountInclTax)]});

    book({reservation, reserve: {...item, roomBreakdown} }).unwrap().then((r) => {

      setOpen(!open)
    }).catch(error => {
      console.log('update roombreakdown error', error)
    }).finally(() => {
      setBookBusy(false)
    })
  }

  const sessionError_ = (sessionError||'').toLowerCase().indexOf('not enough units') !== -1 ? 'Not enough rooms available.' : sessionError;

  return (
    <div>
      <ToolTip content={'Configure your rooms'}>
        <button className={"bn-booknow-button bn-secondary-button"}
                onClick={() => setOpen(!open)}>Configure Rooms
        </button>
      </ToolTip>

      <Dialog portalId={"bn-breakdown-dialog"} open={open} onOpenChange={setOpen}>
        <>
          <div className={"bn-dialog-content"}>
            <h1>Room Configuration</h1>

            <div className={"bn-dialog-text"}>
              <p>Changing your room configuration might affect your rate.</p>
              {inventory.maxPax !== 0 && <div className={"roombreakdown-bed-config"}>
                <FontAwesomeIcon icon="fa-solid fa-bed"/>
                {(inventory.maxAdults !== 0 && inventory.maxKids !== 0) &&
                  <p>
                    {`Sleeps ${inventory.maxPax}`} {`(${inventory.maxAdults} adults and ${inventory.maxKids} children)`}
                  </p>
                }

                {(inventory.maxAdults === 0 || inventory.maxKids === 0) &&
                  <p>{`Sleeps ${inventory.maxPax}`}</p>
                }
              </div>}
            </div>

            <div className={"bn-dialog-roombreakdown-container"}>
              <div className={"bn-roombreakdown-room-select-container"}>
                <SelectRoom
                  noOfRooms={roomBreakdown.length}
                  count={maxRooms}
                  onChange={(option) => onRoomChange(roomBreakdown,
                    option.value)}
                  className={"bn-roombreakdown-select"}
                />
              </div>

              <div className={"bn-roombreakdown-room-container"}>
                {roomBreakdown.map((room, index) => (
                  <div className={"bn-roombreakdown-room"}>

                    <div className={"bn-roombreakdown-roomNo"}>
                      {`#${(index+1)}`}
                    </div>

                    <SelectAdults
                      room={room}
                      inventory={inventory}
                      className={"bn-roombreakdown-select"}
                      onChange={(option) => onAdultsChange(index,
                        option.value)}
                    />

                    <SelectKids
                      room={room}
                      inventory={inventory}
                      className={"bn-roombreakdown-select"}
                      onChange={(option) => onKidsChange(index, option.value)}
                    />

                    {(room.kids || []).map((age, kidIndex) => (
                      <SelectKidAges count={site?.maxKidsAge ?? 19}
                                     className={"bn-roombreakdown-select"}
                                     value={age}
                                     onChange={(option) => onKidAgeChange(index,
                                       kidIndex, option.value)}/>
                    ))}

                  </div>)
                )}
              </div>
            </div>

            <div className={"bn-roombreakdown-total-container"}>
              <h3>Total</h3>

              {busy && <Spinner size={'16px'} /> }

              {(!busy && amountInclTax)
                && <h3>{formatAmount(amountInclTax, item.currencyCode)}</h3>}
            </div>

            {sessionError_ && <div className={"bn-error-message"}>{sessionError_}</div>}

            <div className={"bn-dialog-button-container"}>
              <button className={"bn-booknow-button"}
                      type={"button"}
                      name={"booknow-update-button"}
                      onClick={() => handleUpdate(reservation, amountInclTax, open)}
                      disabled={busy || bookBusy}
              >
                Save Configuration
              </button>
              <button className={"bn-booknow-button bn-secondary-button"}
                      type={"button"}
                      name={"booknow-close-button"}
                      onClick={() => setOpen(!open)}
                      disabled={bookBusy}
              >
                Close
              </button>
            </div>
          </div>
        </>
      </Dialog>
    </div>
  )
}