import {useState} from "react";
import {
  client,
  getApiUrl
} from "../api/client";

export default function useDirectRestFetch() {

  const [state, setState] = useState({
    busy: false,
    data: [],
    error: undefined,
  })

  const fetch = async (payload, api, customer, token, callback) => {
    try {

      setState({
        busy: true,
        response: {},
        error: undefined,
      })

      const response = await client.post(
        getApiUrl(customer, api),
        payload, token);

      setState({
        busy: false,
        response,
      })

      if(callback) {
        callback(response)
      }

      return response;

    } catch (error) {
      setState({
        response: {},
        busy: false,
        error
      })

      console.log('useDirectRestFetch error', error)
    }
  }

  return {fetch, ...state};
}