import BookingsList from "./BookingsList";
import {useSelector} from "react-redux";
import {selectApp} from "../app/appSlice";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import SelectLookup from "../../components/SelectLookup";
import {trackPageView} from "../../api/googleTag";

export default function AgentProfilePage() {

  const navigate = useNavigate();

  const {agent, site} = useSelector(state => ({
    site: selectApp(state).site,
    agent: selectApp(state).agent,
  }));

  const [contactId, setContactId] = useState(undefined)

  useEffect(() => {

    if(!agent) {
      navigate('/')
    }
    else {
      setContactId(agent?.id ?? undefined)
    }

  }, [agent]);

  useEffect(() => {
    trackPageView('/agentprofile', site)
  }, [site]);

  return (
    <div className={"bn-agent-profile-container"}>
      <h1>Bookings</h1>
      <div className={"bn-agent-profile-contact-container"}>
        <p>Welcome</p>
        {agent && <SelectLookup className={"bn-select-item"}
                       id={"contact"}
                       name={"Contact"}
                       value={contactId}
                       placeholder={"Contact"}
                       onChange={(value) => setContactId(value.ID)}
                       defaultToFirstOption={true}
                       isClearable={false}
                       defaultCriteria={{AgentID: agent.agentId}}
        />}
        <p>
          Here you can view
          currently <strong>active</strong> and <strong>past</strong> bookings.
        </p>
      </div>
      {agent && <BookingsList contactId={contactId}/>}
    </div>
  )
}