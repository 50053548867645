import React, {useRef} from "react";

export default function HomePageUrl({homePageUrl}) {

  const linkRef = useRef(undefined);
  const handleOnClick = () => {
    if(linkRef) {
      linkRef.current.click()
    }
  }

  return (
    <div className={"bn-login-button"}
         onClick={() => handleOnClick()}>
      Home
      <a ref={linkRef} style={{display: 'none'}} href={homePageUrl}>hidden link</a>
    </div>
  )
}