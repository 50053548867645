import React, {useEffect, useState} from 'react';
import classNames from "classnames";
import {setPickingStay} from "../booknow/results/resultsSlice";
import {useDispatch} from "react-redux";

export default function Promo({setPromoCode, pickingStay, closeOnScroll = true}) {

  const dispatch = useDispatch();

  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if(!pickingStay && closeOnScroll) {
      setClicked(false)
    }
  }, [pickingStay]);

  const handleOnCLick = React.useCallback((clicked) => {
    setClicked(clicked)
    dispatch(setPickingStay({pickingStay: clicked}))
  }, [dispatch]);

  return (
      <div className={classNames('bn-promo-input', 'bn-search-widget-item',
          {'bn-selected': clicked})}
           onClick={() => handleOnCLick(true)}
      >
        <strong className={"label"}>Promo</strong>
        <input
          type={"text"}
          name={"promo"}
          onChange={event => setPromoCode(event.target.value)}
          onBlur={() => handleOnCLick(false)}/>
      </div>
  )
}
