import React, {useState} from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Dialog from "./Dialog";
import {scrollIntoView_} from "../booknow/Util";
import {trackEvent} from "../api/googleTag";

export default function ImageGallery({imgUrls, busy}) {

  const [open, setOpen] = useState(false);

  const handleGalleryOpen = React.useCallback((open, scrollToId) => {
    setOpen(open)

    trackEvent("engagement", "clicked", "view_hotel_gallery");

    setTimeout(() => scrollIntoView_('imageGalleryId' + scrollToId,
      {behavior: 'smooth', block: 'center'}), 500);
  }, []);

  return (
    <>
      {(!busy && imgUrls.length > 0) && <div className={"bn-image-gallery"}>
        <div className={classNames('bn-image-gallery-primary-image', 'bn-image')}
             style={{backgroundImage: `url(${imgUrls[0]})`}}
             onClick={() => handleGalleryOpen(true, 0)}
        />
        <div className={"bn-image-gallery-secondary-images"}>
          <div className={"bn-image"}
               onClick={() => handleGalleryOpen(true, 1)}
               style={{backgroundImage: `url(${imgUrls[1]})`}}/>
          <div className={"bn-image"}
               onClick={() => handleGalleryOpen(true, 2)}
               style={{backgroundImage: `url(${imgUrls[2]})`}}/>
        </div>
      </div>}
      {(busy || imgUrls.length === 0) && <div className={"bn-image-gallery"}>
        <div className={'bn-image-gallery-primary-image bn-image'}
             style={{backgroundColor: '#c0c0c052'}}></div>
        <div className={"bn-image-gallery-secondary-images"}>
          <div className={"bn-image"}
               style={{backgroundColor: '#c0c0c052'}}/>
          <div className={"bn-image"}
               style={{backgroundColor: '#c0c0c052'}}/>
        </div>
      </div>}
      <Dialog open={open} onOpenChange={handleGalleryOpen}
              className={"bn-image-gallery-container"}>
        <>
          <div className={"bn-image-gallery-header"}>
            <FontAwesomeIcon
              className={"bn-svg-button"}
              onClick={() => handleGalleryOpen(false)}
              icon="fa-xmark"/>
          </div>
          {/*gap:10p + height: 500px*/}
          <div className={"bn-image-gallery-images"}
               style={{height: (510 * imgUrls?.length)}}>
            {imgUrls?.map((i, idx) => (
              <div id={`imageGalleryId${idx}`} key={idx} className={"bn-image"}
                   style={{backgroundImage: `url(${i})`}}/>))}
          </div>
        </>
      </Dialog>
    </>
  )
};