export const buildValidatePayload = (loyaltyNo, surname) => {
  return {
    messageType: 'Validate',
    loyaltyNo, surname
  }
};

export const processValidateRS = (data) => {
  return data.profile;
};

export const getSinglePropertyId = (site) => {
  if(site) {
    const {propertyIds} = site;
    return propertyIds[0]
  }
}
