import React from 'react'

export const Spinner = ({ text = '', size = '5em' }) => {
  const header = text ? <h4>{text}</h4> : null
  return (
      <div className="bn-spinner">
        {header}
        <div className="bn-loader" style={{ height: size, width: size }} />
      </div>
  )
}