/* 
 * Created by Paul Engelke on 16 May 2019.
 */

/**
 * A defined set of possible reservation statuses.
 */
const ReservationStatus = Object.freeze({

  PROVISIONAL: {
    id: 'P',
    label: 'Provisional',
  },

  GUARANTEED: {
    id: 'G',
    label: 'Guaranteed',
  },

  CANCELLED: {
    id: 'C',
    label: 'Cancelled',
  },

  HOLD: {
    id: 'H',
    label: 'Hold',
  },

  WAIT_LIST: {
    id: 'W',
    label: 'Wait-listed',
  },

});

export default ReservationStatus;