import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
  client,
  CONNECTION_CODE,
  DIRECT_AVAIL,
  getApiUrl
} from "../../api/client";
import {selectApp} from "../app/appSlice";
import {getPropertyContent} from "../../api/firebaseApi";

const resultsSlice = createSlice({
  name: 'results',
  initialState: {
    criteria: undefined,
    properties: [],
    hasResults: false,
    busy: false,
    pickingStay: false,
    autoSearchDone: false,
    imgUrls: {},
    searchForAlternateHotels: false,
  },
  reducers: {
    setCriteria: (state, action) => {
      const {criteria} = action.payload;

      state.criteria = Object.assign({}, criteria);
    },
    setPickingStay: (state, action) => {
      const {pickingStay} = action.payload;

      state.pickingStay = pickingStay;
    },
    setAutoSearchDone: (state, action) => {
      const {autoSearchDone} = action.payload;

      state.autoSearchDone = autoSearchDone;
    },
    setImgUrls: (state, action) => {
      const {propertyId, inventoryId, data} = action.payload;

      if (inventoryId) {

         state.imgUrls = {
          ...state.imgUrls,
          [propertyId]: {
            ...state.imgUrls[propertyId],
            inventory: {
              ...state.imgUrls[propertyId]?.inventory,
              [inventoryId]: data
            }
          }
        }

      } else {

        state.imgUrls = {
          ...state.imgUrls,
          [propertyId]: {
            ...state.imgUrls[propertyId],
            urls: data,
          }
        };

      }
    },
    setSearchForAlternateHotels: (state, action) => {
      state.searchForAlternateHotels = action.payload.searchForAlternateHotels;
    },
    addAlternateHotels: (state, action) => {
      let properties = state.properties.slice();
      const {alternateProperties} = action.payload;
      const updated = alternateProperties.map(p => ({...p, alternateHotel: true}));

      state.properties = properties.concat(updated);
    }
  },
  extraReducers(builder) {
    builder.addCase(getResults.pending, (state, action) => {
      state.busy = true;
      state.error = '';
      state.criteria = Object.assign({}, action.meta.arg);
      state.searchForAlternateHotels = false;
    })
    .addCase(getResults.fulfilled, (state, action) => {
      const data = action.payload;
      state.busy = false;

      if (data.properties && data.properties.length > 0) {
        state.properties = data.properties.slice();
        state.hasResults = true;
        state.searchForAlternateHotels = true;
      } else {
        state.properties = [];
        state.hasResults = false;
      }
    }).addCase(getResults.rejected, (state, action) => {
      state.busy = false;
      state.searchForAlternateHotels = false;
      state.error = action.error.message
    }).addCase(getContent.fulfilled, (state, action) => {
      state.properties = action.payload;
    })
  },
});

export const selectResults = state => state.results;

export const getResults = createAsyncThunk('results/fetch',
  async (criteria, thunkAPI) => {
    const {getState} = thunkAPI;
    const {customer, token, connectionCode} = selectApp(getState())

    return await client.post(getApiUrl(customer, DIRECT_AVAIL),
      buildAvailRQ(criteria, connectionCode), token)
  });

export const getContent = createAsyncThunk('results/fetchContent',
  async ({instance, properties, languageId}, thunkAPI) => {

    const docs = (await Promise.all(properties.map((property) => {
      return getPropertyContent(instance, property.id, languageId);
    }))).flat()

    return properties.map(property => {
      const propertyContent = docs.find(
        doc => doc.propertyId === property.id && !doc.inventoryId);

      const inventory = property.inventory.map(inventory => {
        const inventoryContent = docs.find(
          doc => doc.propertyId === property.id && doc.inventoryId
            === inventory.id)

        return {
          ...inventory,
          ...inventoryContent
        }
      })

      return {...property, ...propertyContent, inventory};
    });
  })

export const {
  setCriteria,
  setPickingStay,
  setAutoSearchDone,
  setImgUrls,
  setSearchForAlternateHotels,
  addAlternateHotels
} = resultsSlice.actions;

export const buildAvailRQ = (criteria, connectionCode) => {
  return Object.assign({}, criteria,
    {
      connectionCode: connectionCode,
      roomBreakdown: [criteria.pax],
      pax: undefined
    })
}

export default resultsSlice.reducer