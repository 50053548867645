import * as React from "react";
import {forwardRef, useImperativeHandle, useRef, useState} from "react";
import classNames from "classnames";

export default React.memo(forwardRef( ({title,children}, ref) => {

  const [open, setOpen] = useState(false);

  const contentRef = useRef(undefined)

  const handleOpen = React.useCallback((open) => {
    if (contentRef) {
      contentRef.current.style.maxHeight = (open
          ? contentRef.current.scrollHeight + "px" : 0);
    }

    setOpen(open)
  }, [open, setOpen]);


  useImperativeHandle(ref, () => ({

    resize() {
      if (contentRef) {
        contentRef.current.style.maxHeight =
          (contentRef.current.scrollHeight+40) + "px";
      }
    }

  }));

  return (
      <div>
        <button type={"button"}
                className={classNames('bn-accordion', {'bn-accordion-active': open,})}
                onClick={()=> handleOpen(!open)}
        >
          {title}
        </button>
        <div className={classNames(
            'bn-accordion-content')}
             ref={contentRef}
        >
          {children}
        </div>
      </div>
  )
}));