import ToolTip from "./ToolTip";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    // return distance between the marker and mouse pointer
    return Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
    );
  }
};

export default React.memo(({name, size, $hover, ...props}) => {

  const padding = 10;
  const fontSize = size - padding;

  const style = {
    position: 'absolute',
    left: -((size + (padding * 2)) / 2), // 20 = 10px padding left and right
    top: -((size + (padding * 2)) / 2),  // 20 = 10px padding top and bottom
    fontSize: `${fontSize}px`,
    lineHeight: `${fontSize}px`,
    padding: `${padding}px`,
    borderRadius: '15px',
    border: 'none',
  };

  const style_hover = {
    position: 'absolute',
    left: -((size + (padding * 2)) / 2), // 20 = 10px padding left and right
    top: -((size + (padding * 2)) / 2),  // 20 = 10px padding top and bottom
    fontSize: `${fontSize}px`,
    lineHeight: `${fontSize}px`,
    padding: `${(padding)}px`,
    borderRadius: '15px',
    border: '1px solid #7f7f7f',
  }

  const HotelName = ({name}) => (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        alignItems: 'center'
      }}>
        <div>{name}</div>
        <div style={{
          paddingTop: 6,
          fontSize: 'smaller'
        }}>Click for directions.
        </div>
      </div>
  )

  const handleClick = (props) => {
    const popUpHtml5 = window
    .open('https://maps.google.com/maps?'
        + '&daddr='
        + props.lat + " "
        + props.lng)

    if (popUpHtml5 == null
        || typeof (popUpHtml5) == 'undefined') {
      popUpHtml5.close();
      alert(
          'Please disable your pop-up blocker and click the "Click for Directions" button again.');
    } else {
      popUpHtml5.focus();
    }
  };

  return (
      <ToolTip content={<HotelName name={name}/>}>
        <div style={!$hover ? style : style_hover}
             onClick={() => handleClick(props)}
             className={classNames('bn-googlemaps-hotel-marker',
                 {'bn-googlemaps-hotel-marker-hover': $hover})}><FontAwesomeIcon
            icon="fa-solid fa-hotel"/>
        </div>
      </ToolTip>)
});