import React, {useState} from "react";
import {
  useFloating,
  useDismiss,
  useRole,
  useInteractions,
  useClick, useId,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal, useTransitionStyles
} from "@floating-ui/react";

export default function Dialog({portalId, open, onOpenChange, className = 'bn-dialog', children, initialFocus = -1, ...otherProps}) {

  const {refs, context} = useFloating({
    open,
    onOpenChange,
  });

  const {isMounted, styles} = useTransitionStyles(context, {
    duration: {
      open: 200,
      close: 100,
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });
  const role = useRole(context);

  const labelId = useId();
  const descriptionId = useId();

  const {getReferenceProps, getFloatingProps} = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const ref = refs.setReference;

  return (
      <>
        <FloatingPortal id={portalId}>
          {open && (
              <FloatingOverlay
                  className={"bn-dialog-overlay"}
                  lockScroll
                  style={{background: 'rgba(0, 0, 0, 0.8)', ...styles}}
              >
                <FloatingFocusManager context={context} initialFocus={initialFocus}>
                  <div
                      className={className}
                      ref={refs.setFloating}
                      aria-labelledby={labelId}
                      aria-describedby={descriptionId}
                      {...getFloatingProps()}
                      style={{...styles}}
                  >
                    {React.cloneElement(children, {
                      ref,
                      ...children?.props,
                      ...getReferenceProps()
                    })}
                  </div>
                </FloatingFocusManager>
              </FloatingOverlay>
          )}
        </FloatingPortal>
      </>
  )
}