import React, {useCallback, useState} from 'react';
import {SearchWidget} from "./SearchWidget";
import classNames from "classnames";
import {useDispatch, useSelector} from "react-redux";
import {logoutAgent, selectApp} from "../booknow/app/appSlice";
import {Spinner} from "./Spinner";
import AgentLogin from "../booknow/app/components/AgentLogin";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Menu from "./Menu";
import {getResults, selectResults} from "../booknow/results/resultsSlice";
import {copyObjectExcept} from "../booknow/Util";
import LoyaltyWidget from "../booknow/app/components/LoyaltyWidget";
import FindReservation from "../booknow/session/components/FindReservation";
import {SearchWidgetMobile} from "./SearchWidgetMobile";
import {useNavigate} from "react-router";
import {removeReservation} from "../booknow/session/reservationUtil";
import {
  releaseTemphold, selectSession,
  updateReservation
} from "../booknow/session/sessionSlice";
import HomePageUrl from "./HomePageUrl";
import {getSinglePropertyId} from "../booknow/app/appUtil";

export default React.memo(function PageHeader({overContent, siteId, autoSearch}) {

  const navigate = useNavigate();

  const {busy, site, agent, criteria,
    reservation, singleProperty} = useSelector(state => ({
    busy: selectApp(state).busy,
    agent: selectApp(state).agent,
    site: selectApp(state).site,
    criteria: selectResults(state).criteria,
    reservation: selectSession(state).reservation,
    singleProperty: selectApp(state).singleProperty
  }));

  const dispatch = useDispatch();
  const logout = React.useCallback(() => dispatch(logoutAgent()), [dispatch]);
  const updateReservation_ = useCallback((reservation) =>
    dispatch(updateReservation({reservation})), [dispatch]);
  const releaseTemphold_ = useCallback(
    (params) => dispatch(releaseTemphold(params)), [dispatch]);
  const getResults_ = useCallback((criteria) => dispatch(getResults(criteria)),
    [dispatch]);

  const [openLogin, setOpenLogin] = useState(false);
  const [openAgentMenu, setOpenAgentMenu] = useState(false);

  const handleLogOut = () => {
    logout();

    removeReservation().then(() => {
      if(reservation) {
        releaseTemphold_({sessionId: reservation.sessionId});
        updateReservation_(undefined);
      }

      navigate(!singleProperty ? '/' : `/hotel/${getSinglePropertyId(site)}`)

      if(autoSearch) {
        getResults_(copyObjectExcept(criteria, ['agentId']))
      }
    });

    setOpenAgentMenu(false)
  }

  const handleNavigateAgentProfile = () => {
    setOpenAgentMenu(false)
    navigate("/agentprofile")
  }

  const handleNavigateUpdateGuest = () => {
    setOpenAgentMenu(false)
    navigate("/guestprofile")
  }

  return (
    <div className={classNames('bn-page-header', {
      'lift': overContent
    })}>
      {!busy && <div className={"bn-login-header"}>

        {site.homePageUrl && <HomePageUrl homePageUrl={site.homePageUrl} />}

        <FindReservation />

        {site.showLoyaltyLogin && <LoyaltyWidget/>}

        {(site.showAgentLogin && !agent) && <div className={"bn-login-button"}
                        onClick={() => setOpenLogin(!openLogin)}>Agent Login
        </div>}
        {agent &&
          <Menu open={openAgentMenu}
                onOpenChange={setOpenAgentMenu}
                anchorElement={
                  <div className={"bn-login-name"}
                       onClick={() => setOpenAgentMenu(!openAgentMenu)}>
                    <FontAwesomeIcon icon="fa-solid fa-user"/>
                    {agent.agentName}
                  </div>}>
            <div className={"bn-menu-item"}
                 onClick={handleNavigateAgentProfile}>Bookings
            </div>
            <div className={"bn-menu-item"}
                 onClick={handleNavigateUpdateGuest}>Guest Profiles
            </div>
            <div className={"bn-menu-item"} onClick={handleLogOut}>Log out</div>
          </Menu>
        }
      </div>
      }
      {!busy && <SearchWidget siteId={siteId} autoSearch={autoSearch}/>}
      {!busy && <SearchWidgetMobile siteId={siteId} />}
      {busy && <Spinner size={'75px'}/>}
      <AgentLogin open={openLogin}
                  onOpenChange={setOpenLogin}
                  autoSearch={autoSearch}/>
    </div>
  )
})
