import {useState} from "react";
import {
  client,
  DIRECT_PAYMENT_RECEIVED,
  getApiUrl
} from "../api/client";

export default function usePaymentReceivedFetch() {

  const [state, setState] = useState({
    busy: false,
    paymentsReceived: [],
    error: undefined,
  })

  const fetchData = async (reservsationNo, customer, token) => {
    try {

      setState({
        busy: true,
        paymentsReceived: [],
      })

      const response = await client.post(
        getApiUrl(customer, DIRECT_PAYMENT_RECEIVED),
        {
          reservationNumber: reservsationNo,
          paymentType: 'RECEIVED'
        }, token);

      setState({
        busy: false,
        paymentsReceived: response.response,
      })

      return response.response;

    } catch (error) {
      setState({
        paymentsReceived: [],
        busy: false,
        error
      })

      console.log('usePaymentReceived error', error)
    }
  }

  return {fetchData, ...state};
}