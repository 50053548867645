import React, {useCallback, useEffect, useState} from "react";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import Dialog from "../../../../components/Dialog";
import TextInputItem from "../../../../components/TextInputItem";
import {doFetchReservation, selectSession} from "../../sessionSlice";
import {Spinner} from "../../../../components/Spinner";
import {useNavigate} from "react-router-dom";
import {trackEvent} from "../../../../api/googleTag";

export default React.memo(({open, onOpenChange}) => {

  const navigate = useNavigate();

  const {fetchBusy, fetchError} = useSelector(state => ({
    fetchBusy: selectSession(state).fetchBusy,
    fetchError: selectSession(state).error,
  }));

  const dispatch = useDispatch();
  const fetchReservation = useCallback((email, reservationNo) => dispatch(doFetchReservation({email, reservationNo})), [dispatch]);

  const [email, setEmail] = useState('');
  const [reservationNo, setReservationNo] = useState('');
  const [error, setError] = useState(undefined);

  useEffect(() => {
    setError(fetchError)
  }, [fetchError]);

  const handleFind = () => {
    setError(undefined);

    trackEvent("engagement", "clicked", "find_reservation");

    fetchReservation(email, reservationNo).unwrap().then(results => {

      if(results.reservations.length) {
        onOpenChange(false);
        navigate(`/confirmation/${reservationNo}/${email}`)
      }

    }).catch(error => {
      setError(error);
      console.log('error', error)
    })

  }
  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFind();
    }
  };

  useEffect(() => {
    setEmail('');
    setReservationNo('')

  }, [open]);

  const handleOnOpenChange = (open) => {
    setError(undefined);
    onOpenChange(open);
  }

  const disabled = !email || !reservationNo;

  return (
    <Dialog open={open} onOpenChange={handleOnOpenChange}>
      <>
        <div className={"bn-dialog-content"}>
          <div className={"bn-login-container"}>
            <h1>Find Reservation</h1>
            <div className={"login-input-container"}>
              <div className={classNames("bn-error-message",
                {
                  ['bn-show']: error,
                  ['bn-hidden']: !error,
                })}>
                <FontAwesomeIcon icon="fa-solid fa-circle-exclamation"/>
                {error}
              </div>
            </div>
            <div className={classNames("bn-inputs-group")}>
              <div
                className={"bn-inputs-left"}>
                <TextInputItem className={"bn-group bn-input-item"}
                               type={"text"}
                               id={"email"}
                               label={"E-mail"}
                               placeholder={"E-mail"}
                               mandatory={true}
                               maxLength={100}
                               value={email}
                               onChange={(event) => setEmail(
                                 event.target.value)}
                />
                <TextInputItem className={"bn-group bn-input-item"}
                               type={"text"}
                               id={"reservationNo"}
                               label={'Reservation Number'}
                               placeholder={'Reservation Number'}
                               mandatory={true}
                               maxLength={50}
                               value={reservationNo}
                               onChange={(event) => setReservationNo(
                                 event.target.value)}
                               onKeyPress={event => onKeyPress(event)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={"bn-dialog-button-container"}>
          {!fetchBusy && <button
            className={'bn-booknow-button'}
            onClick={() => handleFind()}
            disabled={disabled}>OK</button>}
          {fetchBusy && <Spinner size={'45px'}/>}
        </div>
      </>
    </Dialog>)
})