import React, {memo, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams, useNavigate} from "react-router-dom";
import {selectResults, setImgUrls} from "../resultsSlice";
import ImageSlider from "../../../components/ImageSlider";
import Itinerary, {ItineraryActions} from "../../session/components/Itinerary";
import {
  doBook,
  updateReservation,
  selectSession
} from "../../session/sessionSlice";
import {
  allowCurrency,
  calcCancelPolicyDescription,
  calcDepositPolicyDescription,
  createReservation,
  dateAdd
} from "../../session/reservationUtil";
import {Spinner} from "../../../components/Spinner";
import {
  formatAmount,
  formatAmountTax,
  getInnerHTML,
  getPath,
  getPropertyFeatureFontName,
  scrollToBookNow,
} from "../../Util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  getAlternateResults,
  selectAlternateResults
} from "../alternateResultsSlice";
import ImageGallery from "../../../components/ImageGallery";
import {selectApp} from "../../app/appSlice";
import GoogleMapReact from "google-map-react";
import HotelMarker, {distanceToMouse} from "../../../components/HotelMarker";
import useImageUrls from "../../../hooks/useImageUrls";
import _ from "lodash";
import {parse} from "date-fns";
import Dialog from "../../../components/Dialog";
import {getBestRateForInventory} from "../resultsUtil";
import {getInventoryDocId, getPropertyDocId} from "../../../api/firebaseApi";
import {
  getRoomRateItem,
  getRoomRateItems,
  trackEvent,
  trackPageView
} from "../../../api/googleTag";


const RateTerms = React.memo(({rate, criteria}) => {
  return (
    <div className={"bn-rate-terms-container"}>
      {rate.depositPolicy && <div className={"bn-rate-deposit-policy-container"}>
        <h4>Deposit Required</h4>
        {rate.depositPolicy && calcDepositPolicyDescription(rate.depositPolicy,
          criteria).map(ruleDescription => (
          <p>
            {ruleDescription}
          </p>
        ))}
      </div>}

      {rate.cancellationPolicy && <div className={"bn-rate-cancel-policy-container"}>
        <h4>Cancellation Policy</h4>
        {rate.cancellationPolicy && calcCancelPolicyDescription(
          rate.cancellationPolicy, criteria).map(ruleDescription => (
          <p>
            {ruleDescription}
          </p>
        ))}
      </div>}
    </div>
  )
});

const HotelRateButton = memo(({property, rate, rateCode, mealPlan, handleReserve}) => {
  const [busy, setBusy] = useState(false);

  return (
    <div className={"bn-rate-button-container"}>
          <span className={"bn-rate-total-stay"}>Total Stay</span>

          <span className={"bn-rate-total-stay-rate"}>
                      {formatAmountTax(
                        rate.total, rate.currency.code,
                        property.ratesIncludeTaxes)}
                    </span>

          {!busy && <button
            className={'bn-booknow-button'}
            onClick={() => handleReserve(rate, rateCode,
              mealPlan, setBusy)}>BOOK NOW
          </button>}

          {busy && <button
            className={'bn-booknow-button bn-button-busy'}>
              <Spinner size={"24px"}/>
          </button>}
    </div>
  )
})

const KeepItemButton  = memo(({onKeepItemClicked}) => {
  return (
    <div className={"bn-rate-button-container"}>
      <button
        className={'bn-booknow-button bn-secondary-button bn-keep-room-button'}
        onClick={() => onKeepItemClicked()}>KEEP ROOM
      </button>
    </div>
  )
});

const HotelRate = React.memo(
  ({property, inventory, openSameCurrency, item, reserveCallback = () => {}}) => {

    const propertyId = property.id;

    const {
    reservation,
    criteria,
    bookBusy,
    alternateRatesBusy,
    properties,
    rateCodes,
    site,
  } = useSelector(
    state => ({
      reservation: selectSession(state).reservation,
      criteria: selectResults(state).criteria,
      bookBusy: selectSession(state).bookBusy,
      alternateRatesBusy: selectAlternateResults(state).busy,
      properties: selectAlternateResults(state).properties,
      rateCodes: selectApp(state).rateCodes,
      site: selectApp(state).site,
    }));

  const dispatch = useDispatch();
  const book = useCallback((params) => dispatch(doBook(params)),
    [dispatch]);

  const [busy, setBusy] = useState(false);
  const [open, setOpen] = useState(false);

  const handleReserve = (rate, rateCode, mealPlan, setBusy) => {
    if (allowCurrency(reservation, rate.currency.id)) {

      trackEvent("add_to_cart", "clicked", "add_to_itinerary",
        {'items': [getRoomRateItem(site, property, inventory, rate)]});

      setBusy(true);
      setOpen(false);

      const breakdownSorted = _.sortBy(rate.rateBreakdown, (breakdown) => {
        return parse(breakdown.effectiveDate, 'yyyy-MM-dd', new Date());
      });

      const startDate = breakdownSorted[0].effectiveDate; //criteria.startDate,
      const endDate = dateAdd(
        breakdownSorted[breakdownSorted.length - 1].effectiveDate, 1);

      const reserve =
        {
          id: item?.id,
          inventoryId: inventory.id,
          propertyId: propertyId,
          startDate, //criteria.startDate,
          endDate, //criteria.endDate,
          roomBreakdown: inventory.roomBreakdown.map(room => {
            return {
              noOfRooms: room.noOfRooms,
              noOfAdults: room.noOfAdults,
              kids: (room.kids || []).map(kid => {
                return kid.age;
              })
            }
          }),
          currencyId: rate.currency.id,
          rateCodeId: rateCode.id,
          mealPlanId: mealPlan.id,
          rateOverridden: true,
          rateBreakdown: rate.rateBreakdown,
        }

      book({reservation, reserve}).unwrap().then((r) => {
        setBusy(false)
        // navigate('/guestdetails')
        reserveCallback()
      }).catch(error => {
        setBusy(false)
        console.log('book error', error)
      });
    } else {
      openSameCurrency(true)
    }
  };

  const arProperty = properties[propertyId];
  const arInventory = arProperty?.inventory?.find(i => i.id === inventory.id);
  const alternateRates = arInventory?.rates;

  const inventoryBooked = (item && item.inventoryId === inventory.id);

  return (
    <div className={"bn-room-rates-container"}>
      {inventory.rates.map((rate, idx) => {

        const breakdown = rate.rateBreakdown[0]
        const {rateCode, mealPlan} = breakdown;

        const rateCodeContent = rateCodes[rateCode.id];
        const rateCodePropertyContent = (rateCodeContent?.properties||{})[propertyId];

        const shortDescription  = rateCodePropertyContent?.shortDescription ?? rateCodeContent?.shortDescription;

        const showKeepButton = (inventoryBooked && item.rateCodeId === rateCode.id);

        return (
          <div className={"bn-rate-detail-container"} key={idx}>
            <div className={"bn-rate-info-container"}>
              <div className={"bn-rate-description-container"}>
                <div className={"bn-rate-mealplan "}>
                  <FontAwesomeIcon icon="fa-solid fa-mug-saucer"/>
                  {mealPlan.name}
                </div>
                <div className={"bn-rate-info"}
                     dangerouslySetInnerHTML={getInnerHTML(shortDescription,
                       rateCode.name)}/>
              </div>

              {!showKeepButton && <HotelRateButton
                property={property}
                rate={rate}
                rateCode={rateCode}
                mealPlan={mealPlan}
                handleReserve={handleReserve}/>}

              {showKeepButton && <KeepItemButton onKeepItemClicked={reserveCallback} />}
            </div>

            <RateTerms rate={rate} criteria={criteria} />

          </div>
        )
      })}

      {
        (!inventory.rates || inventory.rates.length === 0) &&
        <div className={"bn-rate-detail-container bn-rate-not-available"}>
          {!inventoryBooked && <div>Not Available</div>}
          {inventoryBooked &&
            <div className={"bn-rate-info-container"}>
              <div className={"bn-rate-description-container"}>
                Looks like you booked the last room.
              </div>
              <KeepItemButton onKeepItemClicked={reserveCallback}/>
            </div>
          }
        </div>
      }

      {alternateRates?.length > 0 && <h2>Other offers</h2>}
      {alternateRates?.length > 0 && alternateRates?.map((rate, idx) => {
        const requirement = (rate.requirements || [])[0];
        const breakdown = rate.rateBreakdown[0]
        const {rateCode, mealPlan} = breakdown;
        const {totalOriginalRate, total} = rate;

        const rateCodeContent = rateCodes[rateCode.id];
        const rateCodePropertyContent = (rateCodeContent?.properties||{})[propertyId];

        const shortDescription  = rateCodePropertyContent?.shortDescription ?? rateCodeContent?.shortDescription;

        return (
          <>
            <div className={"bn-rate-detail-container"} key={idx}>
              <div className={"bn-rate-info-container"}>
                <div className={"bn-rate-description-container"}>
                  <div className={"bn-rate-mealplan "}>
                    <FontAwesomeIcon icon="fa-solid fa-mug-saucer"/>
                    {mealPlan.name}
                  </div>
                  <div className={"bn-rate-info"}
                       dangerouslySetInnerHTML={getInnerHTML(shortDescription
                         + `<p class="bn-rate-requirement">Stay for ${requirement.duration} nights!</p>`,
                         rateCode.name)}/>
                </div>
                {alternateRatesBusy && <Spinner size={"42.8px"}/>}
                <div className={"bn-rate-button-container"}>
                  {(!alternateRatesBusy && totalOriginalRate) && <span
                    className={"bn-rate-original-total"}>
                    {formatAmount(totalOriginalRate,
                      rate.currency.code)}</span>}
                  {totalOriginalRate &&
                    <span className={"bn-rate-saving-total"}>Save {formatAmount(
                      totalOriginalRate - total,
                      rate.currency.code)}</span>
                  }

                  <span className={"bn-rate-total-stay-rate"}>
                      {formatAmount(
                        rate.total, rate.currency.code)}
                  </span>

                  {!alternateRatesBusy && <button
                    className={'bn-booknow-button'}
                    onClick={() => setOpen(true)}>BOOK NOW</button>}
                </div>
              </div>

              <RateTerms rate={rate} criteria={criteria} />
            </div>
            <Dialog open={open} onOpenChange={setOpen}>
              <>
                <div className={"bn-dialog-content bn-dialog-special-offer"}>
                  <p>To get the <strong>special offer</strong>, your stay will
                    be
                    extended
                    to <strong>{rate.rateBreakdown.length} Nights</strong>.
                    Do you want to continue?</p>
                </div>
                <div className={"bn-dialog-button-container"}>
                  <button
                    className={'bn-booknow-button'}
                    onClick={() => handleReserve(rate, rateCode, mealPlan, setBusy)}>Yes
                  </button>
                  <button
                    className={'bn-booknow-button'}
                    onClick={() => setOpen(false)}>No</button>
                </div>
              </>
            </Dialog>
          </>
        )
      })}
    </div>
  )
});

export const HotelInventory = React.memo(
  ({property, inventory, openSameCurrency, item, reserveCallback}) => {

    const propertyId = property.id;

    const {cached, instance} = useSelector(state => ({
      cached: (selectResults(state).imgUrls[propertyId]?.inventory),
      instance: selectApp(state).instance,
    }));

    const dispatch = useDispatch();
    const setImgUrls_ = useCallback((imgUrls) => dispatch(setImgUrls(imgUrls)),
      [dispatch]);

    return (<div key={inventory.id} className={"bn-hotel-room"}>
      <div className={"bn-room-detail-container"}>
        <div className={"bn-room-slide-container"}>
          <ImageSlider path={getPath(instance, propertyId, inventory.id)}
                       cachedUrls={(cached ? cached[inventory.id] : undefined)}
                       setImgUrls={setImgUrls_}
                       docId={getInventoryDocId({instance, propertyId, inventoryId: inventory.id, languageId: 1})}
          />
        </div>
        <div className={"bn-room-info"}>
          <h3>{inventory.name}</h3>

          {inventory.maxPax !== 0 && <div className={"bn-hotel-bed-config"}>
            <FontAwesomeIcon icon="fa-solid fa-bed"/>
            {(inventory.maxAdults !== 0 && inventory.maxKids !== 0) &&
              <p>
                {`Sleeps ${inventory.maxPax}`} {`(${inventory.maxAdults} adults and ${inventory.maxKids} children)`}
              </p>
            }

            {(inventory.maxAdults === 0 || inventory.maxKids === 0) &&
              <p>
                {`Sleeps ${inventory.maxPax}`}
              </p>
            }
          </div>}

          <p dangerouslySetInnerHTML={getInnerHTML(inventory.shortDescription,
            'Short description not available.')}
             className={"bn-room-description"}>
          </p>
        </div>
      </div>
      <HotelRate
        inventory={inventory}
        property={property}
        openSameCurrency={openSameCurrency}
        item={item}
        reserveCallback={reserveCallback}
      />
      {(!property?.ratesIncludeTaxes) && <div className={"bn-room-rate-tax-info"}>*Quoted rate is tax
        exclusive.</div>}
    </div>)
  });

export default React.memo(function HotelDetail() {

  const navigate = useNavigate();
  const {id} = useParams();

  const {
    reservation,
    property,
    criteria,
    busy,
    hasResults,
    site,
    cached,
    instance,
    loyaltyProfile,
    agent,
    singleProperty,
    error
  } = useSelector(
    state => ({
      reservation: selectSession(state).reservation,
      property: selectResults(state).properties.find(
        p => Number.parseInt(p.id) === Number.parseInt(id)),
      criteria: selectResults(state).criteria,
      busy: selectResults(state).busy,
      hasResults: selectResults(state).hasResults,
      site: selectApp(state).site,
      cached: selectResults(state).imgUrls[`${id}`], //propertyId
      instance: selectApp(state).instance,
      loyaltyProfile: selectApp(state).loyaltyProfile,
      agent: selectApp(state).agent,
      singleProperty: selectApp(state).singleProperty,
      error: selectSession(state).error,
    }));

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const updateReservation_ = useCallback((reservation) =>
    dispatch(updateReservation({reservation})), [dispatch]);
  const getAlternateRates = useCallback(
    criteria => dispatch(getAlternateResults(criteria)), [dispatch]);
  const setImgUrls_ = useCallback((imgUrls) => dispatch(setImgUrls(imgUrls)),
    [dispatch]);

  const {data, busy: imgBusy} = useImageUrls(
    getPath(instance, property?.id), cached?.urls, setImgUrls_,
    getPropertyDocId({instance, propertyId: property?.id, languageId: 1}));

  useEffect(() => {

    if (hasResults && !reservation) {
      updateReservation_(createReservation(loyaltyProfile, agent, site))
    }

    if (hasResults) {
      getAlternateRates({
        ...criteria,
        properties: [{id: Number.parseInt(id)}], alternateRates: true
      }).then(r => {
        scrollToBookNow();
      });
    }

  }, [criteria, hasResults, updateReservation_]);

  // React.useEffect(() => {
  //   scrollToBookNow();
  // }, []);

  useEffect(() => {
      if (property) {
        trackPageView(`/hotel/${property.id}`, site, property)

        trackEvent("view_item_list", "room_rates", "view_room_rates", getRoomRateItems(site, property))
      }
  }, [site, property]);

  const handleBack = () => {
    trackEvent("engagement", "clicked", "back_to_results");

    navigate(-1)
  };

  return (
    <React.Fragment>
      {busy && <Spinner size={"10em"}/>}
      {!busy && property && <div className={"bn-hotel-detail-container"}>
        {error && <div className={'bn-error-message'}>{error}</div>}
        <div className={"bn-hotel-banner"}>
          <div className={"bn-hotel-description"}>
            <h1>
              {!singleProperty && <FontAwesomeIcon
              className={"bn-svg-button"}
              onClick={handleBack}
              icon="fa-solid fa-arrow-left"/>}
              {property?.name}</h1>
            <div className={"bn-hotel-area"}>
              <label>{property?.area?.name}, {property?.location?.name}</label>
            </div>
            <p dangerouslySetInnerHTML={getInnerHTML(property.shortDescription,
              'Short description not available.')}>
            </p>
          </div>
          <div className={"bn-hotel-slide-container"}>
            <ImageGallery imgUrls={data} busy={imgBusy}/>
          </div>
        </div>

        <div className={"bn-hotel-feature-container"}>
          <h2>We offer</h2>
          <div className={"bn-feature-container"}>
            {_.sortBy(property?.features, 'name').map((feature, idx) => (
              <div key={idx} className={"bn-hotel-feature"}>
                <FontAwesomeIcon
                  icon={`fa-solid ${getPropertyFeatureFontName(feature.id)}`}/>
                {feature.name}
              </div>
            ))}
          </div>
        </div>

        <div id={"bn-hotel-room-detail"} className={"bn-hotel-room-detail"}>
          <div className={"bn-hotel-rooms-container"}>
            {_.sortBy(property?.inventory, 'inventorySeq',
              (inventory => {
                const bestRate = getBestRateForInventory(inventory);
                return bestRate ? bestRate.bar : Number.MAX_SAFE_INTEGER;
              })).map((inventory, idx) => {
              return (<HotelInventory inventory={inventory}
                                      property={property}
                                      key={idx}
                                      openSameCurrency={setOpen}
              />)
            })
            }
          </div>
          {reservation && <Itinerary
            action={ItineraryActions.GUESTDETAIL}/>}
        </div>

        {(property?.gpsLat && property?.gpsLong) &&
          <div className={"bn-hotel-map-container"}>
            <h2>Where you will be going</h2>
            <div className={"bn-hotel-map"}>
              <GoogleMapReact
                bootstrapURLKeys={{key: 'AIzaSyDm1RoOG3sf20jj1A6fXy-ivtSv2LagFR4'}}
                defaultCenter={{
                  lat: Number.parseFloat(property.gpsLat),
                  lng: Number.parseFloat(property.gpsLong)
                }}
                defaultZoom={11}
                options={{streetViewControl: true}}
                resetBoundsOnResize
                yesIWantToUseGoogleMapApiInternals
                hoverDistance={50
                  / 2} // hovers 25px on x-axis and 25px on y-axis form centre of marker
                distanceToMouse={distanceToMouse}
              >
                <HotelMarker
                  lat={property.gpsLat}
                  lng={property.gpsLong}
                  name={property.name}
                  size={40}
                />
              </GoogleMapReact>
            </div>
          </div>}

        <Dialog open={open} onOpenChange={setOpen}>
          <>
            <div className={"bn-dialog-content"}>
              <p>Please note your previous itinerary is in a different currency
                to this one. Please complete or remove that itinerary before
                attempting to book this one.</p>
            </div>
            <div className={"bn-dialog-button-container"}>
              <button
                className={'bn-booknow-button'}
                onClick={() => setOpen(!open)}>OK</button>
            </div>
          </>
        </Dialog>
      </div>}
    </React.Fragment>
  )
})