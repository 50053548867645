import React, {
  forwardRef,
  useEffect,
  useImperativeHandle, useRef,
  useState
} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectResults, setPickingStay} from "../booknow/results/resultsSlice";
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal, size,
  useDismiss,
  useFloating,
  useInteractions
} from "@floating-ui/react";
import {flip, offset, shift} from "@floating-ui/react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classNames from "classnames";
import {Link} from "react-router-dom";
import {flushSync} from "react-dom";
import {selectApp} from "../booknow/app/appSlice";

const decrementValue = (value) => {
  if (value > 1) {
    return (value - 1)
  } else {
    return 1;
  }
};

export default forwardRef(
  function GuestPicker({setPax, doSearch, closeOnScroll = true}, ref) {

    const {criteria, pickingStay, site} = useSelector(state => ({
      pickingStay: selectResults(state).pickingStay,
      criteria: selectResults(state).criteria,
      site: selectApp(state).site,
    }));

    const [guests, setGuests] = useState(0);
    const [open, setOpen] = useState(false);
    const [noOfAdults, setNoOfAdults] = useState(0);
    const [kids, setKids] = useState([]);
    const [maxWidth, setMaxWidth] = useState(300)
    const [maxHeight, setMaxHeight] = useState(600)

    const dispatch = useDispatch();

    const handleOpen = React.useCallback((open) => {
      setOpen(open);

      dispatch(setPickingStay({pickingStay: open}))
    }, [setOpen, dispatch]);

    useEffect(() => {
      if (criteria && criteria.pax) {

        const noOfAdults_ = criteria.pax.noOfAdults;
        const kids_ =  criteria.pax.kids ?? [];

        setNoOfAdults(noOfAdults_);
        setKids(kids_);
        setGuests(noOfAdults_ + kids_.length)
      }
    }, [criteria]);

    useEffect(() => {
      if (!pickingStay && closeOnScroll) {
        setOpen(false)
      }
    }, [pickingStay]);

    useImperativeHandle(ref, () => ({
      show() {
        refs.reference.current.click();
      }
    }));

    const updateAdultPax = React.useCallback((value) => {
      setPax({noOfAdults: value, kids})
    }, [kids, setPax]);

    const updateKidsPax = React.useCallback((kidsSlice) => {
      setPax({noOfAdults, kids: kidsSlice})
    }, [noOfAdults, kids, setPax]);

    const addAdults = React.useCallback(() => {
      const value = (noOfAdults + 1);
      setNoOfAdults(value);
      setGuests((value + kids.length));
      updateAdultPax(value);
    }, [noOfAdults, kids, updateAdultPax]);

    const reduceAdults = React.useCallback(() => {
      const value = decrementValue(noOfAdults);
      setNoOfAdults(value);
      setGuests((value + kids.length));
      updateAdultPax(value)
    }, [noOfAdults, kids, updateAdultPax]);

    const increaseKids = React.useCallback(() => {
      const kidsSlice = kids.slice();
      kidsSlice.push(1);
      setKids(kidsSlice);
      setGuests((noOfAdults + kidsSlice.length));
      updateKidsPax(kidsSlice)

    }, [noOfAdults, kids, updateKidsPax]);

    const decreaseKids = React.useCallback(() => {
      const kidsSlice = kids.slice(0, kids.length - 1);
      setKids(kidsSlice);
      setGuests((noOfAdults + kidsSlice.length));
      updateKidsPax(kidsSlice);
    }, [noOfAdults, kids, updateKidsPax]);

    const increaseKidAge = (value, idx, site) => {
      if(value < (site?.maxKidsAge ?? 18)) {
        const kids_ = kids.toSpliced(idx, 1, (value + 1))

        setKids(kids_)
        updateKidsPax(kids_)
      }
    }

    const decreaseKidAge = (value, idx) => {
      if(value > 1) {
        let kids_ = kids.toSpliced(idx, 1, (value - 1))

        setKids(kids_)
        updateKidsPax(kids_)
      }
    }

    const {refs, floatingStyles, context} = useFloating({
      open,
      onOpenChange: handleOpen,
      placement: "bottom-start",
      middleware: [offset(15), flip(), shift({
        crossAxis: true,
      }),
        size({
          apply({availableWidth, availableHeight}) {
            flushSync(() => {
              setMaxWidth(availableWidth)
              setMaxHeight(availableHeight)
            });
          },
        }),
      ],
      whileElementsMounted: autoUpdate,
    });

    useInteractions([
      useDismiss(context, {
        outsidePointerDown: true,
      }),
    ]);

    const handleSearch = (event) => {
      event.stopPropagation()
      handleOpen(false)
      if(doSearch) {
        doSearch()
      }
    }

    const guestDescritpion = guests > 1 ? 'Guests' : 'Guest';

    const adultsDivRef = useRef(undefined);
    const kidsDivRef = useRef(undefined);

    const getInputsHeight = () => {
      let height = 144;

      if(adultsDivRef.current) {
        height = adultsDivRef.current.getBoundingClientRect().height;
      }

      if(kidsDivRef.current) {
        height = height + kidsDivRef.current.getBoundingClientRect().height;
      }

      return height;

    }

    return (
      <div ref={refs.setReference}
           onClick={() => handleOpen(true)}
           className={classNames('bn-pax-picker', 'bn-search-widget-item',
             {'bn-selected': open})}>
        <strong className={"label"}>Guests</strong>
        <label className={"value"}>
          {guests === 0 ? 'Add guests' : (`${guests} ${guestDescritpion}`)}
        </label>
        <FloatingPortal id={"bn-pax-picker-portal"}>
          {(open) &&
            <FloatingFocusManager context={context}>
              <div ref={refs.setFloating}
                   style={{...floatingStyles, maxWidth}} //,maxHeight
                   className={classNames('bn-pax-picker-container',
                     'bn-searchwidget-portal-container')}>
                <div className={"bn-pax-inputs"}>
                {/*<div className={"bn-pax-inputs"} style={{maxHeight: (maxHeight-getInputsHeight())}}>*/}
                  <div ref={adultsDivRef} className={"bn-pax-input"}>
                    <h3>Adults</h3>
                    <div className={"bn-pax-input-button-container"}>
                      <button className={"minus-button"}
                              type={"button"}
                              onClick={() => reduceAdults()}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-circle-minus"/>
                      </button>
                      <div className={'bn-pax-input-value'}>{noOfAdults}</div>
                      <button className={"add-button"}
                              type={"button"}
                              onClick={() => addAdults()}
                      >
                        <FontAwesomeIcon icon="fa-solid fa-circle-plus"/>
                      </button>
                    </div>
                  </div>
                  <div ref={kidsDivRef} className={"bn-pax-input"}>
                    <div className={"bn-pax-kids-input-container"}>
                      <h3>Children</h3>
                      <div className={"bn-pax-input-button-container"}>
                        <button className={"minus-button"}
                                type={"button"}
                                onClick={() => decreaseKids()}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-circle-minus"/>
                        </button>
                        <div
                          className={'bn-pax-input-value'}>{kids.length}</div>
                        <button className={"add-button"}
                                type={"button"}
                                onClick={() => increaseKids()}
                        >
                          <FontAwesomeIcon icon="fa-solid fa-circle-plus"/>
                        </button>
                      </div>
                    </div>

                    <div className={"bn-kids-inputs"}>
                      {kids.map((kidAge, idx) => (
                        <div key={idx} className={"bn-pax-input bn-kids-input"}>
                          <h3>Age</h3>
                          <div className={"bn-pax-input-button-container"}>
                            <button className={"minus-button"}
                                    type={"button"}
                                    onClick={() => decreaseKidAge(kids[idx], idx)}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-circle-minus"/>
                            </button>
                            <div className={'bn-pax-input-value'}>{kids[idx]}</div>
                            <button className={"add-button"}
                                    type={"button"}
                                    onClick={() => increaseKidAge(kids[idx], idx, site)}
                            >
                              <FontAwesomeIcon icon="fa-solid fa-circle-plus"/>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>

                  </div>

                  {/*<div className={"bn-kids-inputs"}>*/}
                  {/*  {kids.map((kidAge, idx) => (*/}
                  {/*    <div key={idx} className={"bn-pax-input bn-kids-input"}>*/}
                  {/*      <h3>Age</h3>*/}
                  {/*      <div className={"bn-pax-input-button-container"}>*/}
                  {/*        <button className={"minus-button"}*/}
                  {/*                type={"button"}*/}
                  {/*                onClick={() => decreaseKidAge(kids[idx], idx)}*/}
                  {/*        >*/}
                  {/*          <FontAwesomeIcon icon="fa-solid fa-circle-minus"/>*/}
                  {/*        </button>*/}
                  {/*        <div className={'bn-pax-input-value'}>{kids[idx]}</div>*/}
                  {/*        <button className={"add-button"}*/}
                  {/*                type={"button"}*/}
                  {/*                onClick={() => increaseKidAge(kids[idx], idx)}*/}
                  {/*        >*/}
                  {/*          <FontAwesomeIcon icon="fa-solid fa-circle-plus"/>*/}
                  {/*        </button>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  ))}*/}
                  {/*</div>*/}

                  {/*<div className={"bn-pax-input"}>*/}
                  {/*  <h3>Children</h3>*/}
                  {/*  <Select*/}
                  {/*    placeholder={'Select child age'}*/}
                  {/*    closeMenuOnSelect={false}*/}
                  {/*    isMulti*/}
                  {/*    value={kids}*/}
                  {/*    options={ageOptions}*/}
                  {/*    className="age-multi-select"*/}
                  {/*    classNamePrefix="age-select"*/}
                  {/*    onChange={handleAgeSelect}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
                <div className={"bn-button-container"}>
                  <Link to={"/"}>
                    <button className={"bn-booknow-button"}
                            type={"button"}
                            name={"bn-booknow-button"}
                            onClick={event => handleSearch(event)}
                    ><FontAwesomeIcon
                      icon="fa-solid fa-magnifying-glass"/> SEARCH
                    </button>
                  </Link>
                </div>
              </div>
            </FloatingFocusManager>
          }
        </FloatingPortal>
      </div>
    )
  })
