import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Spinner} from "../../components/Spinner";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";
import TextInputItem from "../../components/TextInputItem";
import {
  getContact, getPasswordRestrictions,
  selectSignup
} from "./signupSlice";
import {changePassword, login, selectApp} from "../app/appSlice";
import {
  getErrorTypeDescription,
  USER_CHANGE_PASSWORD
} from "../../api/errorTypes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AgentLogin from "../app/components/AgentLogin";
import {getValidationFn, passwordValid} from "./signupUtil";

export default React.memo(function () {

  const {contactId} = useParams();

  const {contact, customer, busy, loginBusy, passwordRestrictions, errorType} = useSelector(
      state => ({
        busy: selectSignup(state).busyGetContact,
        contact: selectSignup(state).contact,
        customer: selectApp(state).customer,
        loginBusy: selectApp(state).loginBusy,
        errorType: selectApp(state).errorType,
        passwordRestrictions: selectSignup(state).passwordRestrictions
      }));

  const dispatch = useDispatch();
  const fetchContact = React.useCallback((id) => dispatch(getContact(id)),
      [dispatch]);
  const fetchPasswordRestrictions = React.useCallback(() => dispatch(getPasswordRestrictions()),
      [dispatch]);
  const changePassword_ = React.useCallback(
      (username, oldPassword, password) => dispatch(
          changePassword({username, oldPassword, password})),
      [dispatch]);
  const login_ = React.useCallback(
      (username, password) => dispatch(login({username, password})),
      [dispatch]);

  useEffect(() => {

    if (customer) {
      Promise.all([
        fetchContact(contactId),
        fetchPasswordRestrictions(),
      ]).catch(e => {
        console.error(e); // error is handled elsewhere
      });
    }

  }, [customer, contactId, fetchContact]);

  const [tempPassword, setTempPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [pswdChangeSuccess, setPswdChangeSuccess] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const handleChangePassword = () => {
    changePassword_(contact.username, tempPassword, newPassword).then(r => {
      if (!r.error) { // change successfull
        setPswdChangeSuccess(true)
      }
    })
  }

  const handleLogin = () => {
    login_(contact.username, tempPassword)
  }

  const handleSignIn = () => {
    setOpenLogin(true)
  }

  return (
      <>
        {busy && <Spinner size={"10em"}/>}
        {!busy && contact && <div className={"bn-agent-signup-container"}>
          <h1>Agent Sign Up</h1>
          {!pswdChangeSuccess && <div
              className={"agent-signup-input-container"}>
            <div className={classNames("bn-agent-signup-errors",
                {
                  ['show']: errorType !== undefined,
                  ['bn-hidden']: errorType === undefined,
                })}>
              <FontAwesomeIcon icon="fa-solid fa-circle-exclamation"/>
              {getErrorTypeDescription(errorType)}
            </div>
            <p>Username: <span>{contact.username}</span></p>
            <div className={classNames("bn-inputs-group")}>
              <div className={"bn-inputs-left"}>
                <TextInputItem className={"bn-group bn-input-item"}
                               type={"password"}
                               id={"tempPassword"}
                               label={"Temporary password"}
                               placeholder={"Temporary password"}
                               mandatory={true}
                               maxLength={50}
                               value={tempPassword}
                               onChange={(event) => setTempPassword(
                               event.target.value)}
                />
                {errorType === USER_CHANGE_PASSWORD &&
                    <TextInputItem className={"bn-group bn-input-item"}
                                   type={"password"}
                                   id={"password"}
                                   label={'New password'}
                                   placeholder={'New password'}
                                   mandatory={true}
                                   maxLength={50}
                                   value={newPassword}
                                   onChange={(event) => setNewPassword(
                                   event.target.value)}
                    />}
                {errorType === USER_CHANGE_PASSWORD &&
                    <TextInputItem className={"bn-group bn-input-item"}
                                   type={"password"}
                                   id={"password"}
                                   label={"Confirm new password"}
                                   placeholder={"Confirm new password"}
                                   mandatory={true}
                                   maxLength={50}
                                   value={confirmNewPassword}
                                   onChange={(event) => setConfirmNewPassword(
                                   event.target.value)}
                    />}
              </div>
            </div>
            {!loginBusy && errorType === USER_CHANGE_PASSWORD && <div className={"bn-password-restrictions"}>
              <div className={"bn-password-progress"}>
                {passwordRestrictions.map((restriction, idx) => {
                  const validate = getValidationFn(restriction, newPassword);
                  return (
                      <div key={idx} className={classNames('bn-progress-item', {
                        'valid': validate(restriction, newPassword)
                      })}/>)
                })}
              </div>
              <ul>
                {passwordRestrictions.map((restriction, idx) => {
                  const validate = getValidationFn(restriction, newPassword);
                  return (
                      <li key={idx} className={classNames('bn-restriction-item', {
                        'valid': validate(restriction, newPassword)
                      })}><FontAwesomeIcon
                          icon="fa-solid fa-check"/> {restriction.name}</li>)
                })}
              </ul>
            </div>}
            <div className={"bn-button-container"}>
              {!loginBusy && errorType !== USER_CHANGE_PASSWORD && <button
                  className={'bn-booknow-button'}
                  onClick={handleLogin}
                  disabled={!tempPassword}>Sign In</button>}
              {!loginBusy && errorType === USER_CHANGE_PASSWORD && <button
                  className={'bn-booknow-button'}
                  onClick={handleChangePassword}
                  disabled={!newPassword
                      || !confirmNewPassword
                      || !tempPassword
                      || (newPassword !== confirmNewPassword)
                      || !passwordValid(passwordRestrictions, newPassword)
              }>OK</button>}
              {loginBusy && <Spinner size={'45px'}/>}
            </div>
          </div>}
          {pswdChangeSuccess && <div className={"bn-agent-pswd-change-success"}>
            <FontAwesomeIcon icon="fa-solid fa-check"/>
            <p>Password change successful. Please click <span
                onClick={handleSignIn}>here</span> to
              log in.</p>
          </div>}
        </div>}
        <AgentLogin open={openLogin} onOpenChange={setOpenLogin}/>
      </>
  )
});