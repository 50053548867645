import {useState} from "react";
import {loadLookupItemsPage} from "../booknow/Util";

const useGuestLookupSearch = () => {

  const [state, setState] = useState({
    busy: false,
    profiles: [],
    error: undefined,
  })

  const search = async (criteria, customer, token, callback) => {
    try {

      setState({
        busy: true,
        profiles: [],
      })

      let page = 0;
      let pageSize = 50;

      let criteriaWithPaging = {
        dataSource: 'GuestAgent',
        operationType: 'fetch',
        data: {
          ...criteria,
        },
        startRow: 0,
        endRow: pageSize,
        textMatchStyle: 'substring',
        sortBy: [
          "Name"
        ]
      };

      let hasMoreRows = true;
      let data = [];

      while (hasMoreRows) {
        page++;
        const response = await loadLookupItemsPage(criteriaWithPaging, page,
          pageSize,
          customer, token);

        const moreData = response.data;
        data = data.concat(moreData)

        hasMoreRows = (page * pageSize) < response.totalRows;

        setState({
          ...state,
          profiles: data,
        })
      }

      setState({
        busy: false,
        profiles: data,
        error: undefined,
      })

      if (callback) {
        callback(data)
      }

    } catch (error) {
      setState({
        profiles: [],
        busy: false,
        error
      })

      console.log('Guest Lookup error: ', error)
    }
  }

  const reset = () => {
    setState({
      busy: false,
      profiles: [],
      error: undefined
    })
  }

  return {search, reset, ...state};
}

export default useGuestLookupSearch