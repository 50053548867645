import {onAuthStateChanged} from "firebase/auth";
import {getFunctions, httpsCallable} from 'firebase/functions';
import {
  collection,
  query as firestoreQuery,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  getDatabase,
  ref,
  query,
  orderByKey,
  equalTo,
  onValue
} from "firebase/database";
import {
  getStorage,
  ref as storageRef,
  listAll,
  getDownloadURL,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import {isDev} from "../booknow/Util";
import {db, dbCrs} from "../App";

export const getCustomerData = (customer_key, firebaseApp, auth, callback) => {

  onAuthStateChanged(auth, user => {

    const currentUser = auth.currentUser;

    if (currentUser) {

      currentUser.getIdToken().then(token => {

        if (user) { // signed in successfully

          const functions = getFunctions(firebaseApp);
          let customerData = httpsCallable(functions, 'retrieveCustomer');
          customerData(
            {customer_key: customer_key, token: token, dev: isDev()}).then(
            function (result) {
              // Read result of the Cloud Function.
              const database = getDatabase(firebaseApp);
              let customerForKey = query(ref(database, 'customers'),
                orderByKey(), equalTo(customer_key));

              onValue(customerForKey, snapshot => {
                snapshot.forEach(childSnapshot => {
                  let childKey = childSnapshot.key;
                  let customerData = childSnapshot.val();

                  callback(customerData, result.data.token)
                });
              }, {
                onlyOnce: true
              });
            }, (reason) => {
              console.log('Cannot retrieve CutsomerData, reason: ' + reason)
            });
        } else {
          // user is signed out
        }
      });
    }
  });
}

export const getStorageRefs = async (path) => {

  const storage = getStorage();
  const listRef = storageRef(storage, path);

  try {
    const res = await listAll(listRef)

    // res.prefixes.forEach((folderRef) => {
    //   // All the prefixes under listRef.
    //   // You may call listAll() recursively on them.
    //   // console.log('folderRef', folderRef);
    // });

    const fileUrls  = await Promise.all(
      res.items.map(itemRef => {
        return getDownloadURL(itemRef);
      }))

    return { storageRefs : res.items.map((itemRef, idx) => {
        return {
          filename: itemRef.name,
          downloadURL: fileUrls[idx],
        }
      }) }
  } catch (error) {
    console.log('getStorageRefs error', error)
    return { error }
  }
}

export const getImageUrls = async (path) => {
  const storage = getStorage();
  const listRef = storageRef(storage, path);

  try {
    const res = await listAll(listRef)

    // res.prefixes.forEach((folderRef) => {
      // All the prefixes under listRef.
      // You may call listAll() recursively on them.
      // console.log('folderRef', folderRef);
    // });

    const imageUrls = await Promise.all(
      res.items.map(itemRef => {
        return getDownloadURL(itemRef);
      }))

    return {imageUrls}
  } catch (error) {
    console.log(error)
    return {error}
  }
}

export const getPropertyContent = async (instance, propertyId, languageId) => {

  const q = firestoreQuery(collection(db, "content"),
    where("instance", "==", instance),
    where("propertyId", "==", propertyId),
    where("languageId", "==", languageId));

  let docs = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    docs.push(doc.data());
  });

  return docs;
}

export const getData = async (query) => {
  let docs = [];

  const querySnapshot = await getDocs(query);
  querySnapshot.forEach((doc) => {
    docs.push(doc.data());
  });

  return docs;
}

export const getRateCodesQuery = (instance, languageId) => {
  return firestoreQuery(collection(db, "content"),
    where("instance", "==", instance),
    where("id", ">", 0),
    where("languageId", "==", languageId));
}

export const getRateCodeContent = async (instance, languageId) => {

  const q = firestoreQuery(collection(db, "content"),
    where("instance", "==", instance),
    where("id", ">", 0),
    where("languageId", "==", languageId));

  let docs = [];

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    docs.push(doc.data());
  });

  return docs;
}

export const getSingleDoc = async (docId) => {
  const docRef = doc(db, "content", docId);
  const docSnap = await getDoc(docRef);
  return docSnap.data();
}

export const getSiteDocId = ({instance, siteId, languageId}) => {
  return `${instance}#s${siteId}${languageId}`
}
export const getPropertyDocId = (data) => {
  return `${data.instance}${data.propertyId}${data.languageId}`
}

export const getInventoryDocId = (data) => {
  return `${data.instance}${data.propertyId}${data.inventoryId}${data.languageId}`
}


export const uploadFile = (file, path) => {
  const storage = getStorage();
  const uploadRef = storageRef(storage, path);
  return uploadBytes(uploadRef, file);
}

export const deleteFile = (path, filename) => {
  const storage = getStorage();
  const deleteRef = storageRef(storage, `${path}/${filename}`)
  return deleteObject(deleteRef);
}

export const getDensityQuery = (instance, propertyId, inventoryId, startDate,
  endDate) => {

  let queryContraints = [where("instance", "==", instance),
    where("propertyId", "==", propertyId),
    where("date", ">=", startDate),
    where("date", "<", endDate)]

  if(inventoryId) {
    queryContraints.push(where("inventoryId", "==", inventoryId))
  }

  return firestoreQuery(collection(dbCrs, "availability"), ...queryContraints);
}

export const getInventoryQuery = (instance, propertyId, inventoryId, startDate,
  endDate) => {
  return firestoreQuery(collection(dbCrs, "availability"),
    where("instance", "==", instance),
    where("propertyId", "==", propertyId),
    where("inventoryId", "==", inventoryId),
    where("date", ">=", startDate),
    where("date", "<", endDate));
}
