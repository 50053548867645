import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
  selectPropertySuggestions,
} from "../propertySuggestionSlice";
import {
  autoUpdate,
  FloatingFocusManager,
  FloatingPortal, size,
  useDismiss,
  useFloating,
  useInteractions
} from '@floating-ui/react';
import {flip, offset, shift} from "@floating-ui/react";
import classNames from "classnames";
import {selectResults, setPickingStay} from "../../results/resultsSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getPropertyIds} from "../suggestionsUtil";
import {selectApp} from "../../app/appSlice";
import {flushSync} from "react-dom";

const getDisplayValue = (area, location, property) => {
  let text = 'Anywhere';

  if (property) {
    return property.name;
  } else if (location) {
    return location.name;
  } else if (area) {
    return area.name;
  }

  return text;
};

const noOfProperties = (suggestion) => {
  if (suggestion.properties) {
    return '(' + suggestion.properties.length + ')'
  }

  return '';
};

const isSelected = (item, suggestion) => {
  return item.name === suggestion?.name
};

const suggestionIncludesTerm = (suggestion, searchTerm) => {
  return String(suggestion.name).toLowerCase().includes(
    String(searchTerm).toLowerCase())
};

const areaHasSearchTermFound = (suggestions) => {
  const map = suggestions.filter(country => {
    return hasSearchTermFound(country.areas)
  })

  return map.length > 0
};

const locationHasSearchTermFound = (suggestions) => {
  const map = suggestions.map(country => {
    return country.areas.filter(area => {
      return hasSearchTermFound(area.locations)
    })
  }).filter(s => (s.length > 0));

  return map.length > 0
};

const propertyHasSearchTermFound = (suggestions) => {
  const map = suggestions.map(country => {
    return country.areas.map(area => {
      return area.locations.filter(location => {
        return hasSearchTermFound(location.properties);
      })
    }).filter(s => (s.length > 0));
  }).filter(s => (s.length > 0));

  return map.length > 0;
};

const hasSearchTermFound = (suggestions) => {
  return suggestions.find(sug => (sug.searchTermFound === true))
};

const filterSuggestions = (suggestions, searchTerm) => {
  const copy = suggestions.slice();

  return copy.map(country => {
    return Object.assign({}, country, {
      searchTermFound: suggestionIncludesTerm(country, searchTerm),
      areas: country.areas.map(area => {
        return Object.assign({}, area, {
          searchTermFound: suggestionIncludesTerm(area, searchTerm),
          locations: area.locations.map(location => {
            return Object.assign({}, location, {
              searchTermFound: suggestionIncludesTerm(location, searchTerm),
              properties: location.properties.map(property => {
                return Object.assign({}, property, {
                  searchTermFound: suggestionIncludesTerm(property, searchTerm)
                });
              })
            })
          })
        })
      })
    })
  });
};

const SuggestionLabel = (key, suggestion, selectedSuggestion, setterFn) => {
  return <label key={key}
                onClick={(event) => setterFn(suggestion, event)}
                className={classNames(
                  'bn-suggestion',
                  {
                    'bn-selected': isSelected(suggestion, selectedSuggestion)
                  },)}>
    {suggestion.name} {noOfProperties(suggestion)}
  </label>;
};

export function PropertySuggestion({
  setPropertyIds,
  setSuggestion = (suggestion) => {
  },
  closeOnScroll = true,
  portalId = 'bn-suggestion-portal',
  closeOnSelect = false,
  showNext,
}) {

  const {suggestions, pickingStay, site, busy} = useSelector((state => ({
    pickingStay: selectResults(state).pickingStay,
    suggestions: selectPropertySuggestions(state).suggestions,
    busy: selectPropertySuggestions(state).busy,
    site: selectApp(state).site,
  })));

  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState(undefined);
  const [area, setArea] = useState(undefined);
  const [location, setLocation] = useState(undefined);
  const [property, setProperty] = useState(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [localSuggestions, setLocalSuggestions] = useState([]);

  const [maxHeight, setMaxHeight] = useState(1000)

  const dispatch = useDispatch();

  React.useEffect(() => {
      setLocalSuggestions((suggestions || []).slice())
    },
    [suggestions]);

  useEffect(() => {
    if (!pickingStay && closeOnScroll) {
      setOpen(false)
    }
  }, [pickingStay]);

  const reset = React.useCallback(() => {
    setCountry(undefined);
    setArea(undefined);
    setLocation(undefined);
    setProperty(undefined);
  }, []);

  const handleReset = React.useCallback(() => {
    reset();
    setSearchTerm('');

    setPropertyIds(getPropertyIds(suggestions))
  }, [reset, setSearchTerm, setPropertyIds, suggestions]);

  const handleChange = React.useCallback((e) => {
    const value = e.target.value;
    setSearchTerm(value);
    reset();

    setLocalSuggestions(filterSuggestions(suggestions, value))

    if(!value) {
      handleReset()
    }

  }, [setSearchTerm, suggestions, reset, handleReset]);

  const handleKeyDown = (event) => {
    setOpen(true);
    reset();
  }

  const setCountry_ = (country, event) => {
    setCountry(country);
    setSearchTerm(country.name);
    setLocalSuggestions(filterSuggestions(suggestions, country.name));

    setArea(undefined)
    setLocation(undefined);
    setProperty(undefined);

    if (closeOnSelect) {
      event.stopPropagation();
      handleOpen(false)
      handleNext(event)
    }

    setPropertyIds(country.properties.map(
      property => ({id: property.id, name: property.name})))
    setSuggestion(country);
  };

  const setArea_ = (area, event) => {
    setArea(area);
    setSearchTerm(area.name);
    setLocalSuggestions(filterSuggestions(suggestions, area.name));

    setLocation(undefined);
    setProperty(undefined);

    if (closeOnSelect) {
      event.stopPropagation();
      handleOpen(false)
      handleNext(event)
    }

    setPropertyIds(
      area.properties.map(property => ({id: property.id, name: property.name})))
    setSuggestion(area);
  };

  const setLocation_ = (location, event) => {
    setLocation(location);
    setSearchTerm(location.name);

    setProperty(undefined);

    setPropertyIds(location.properties.map(
      property => ({id: property.id, name: property.name})))
    setSuggestion(location);

    if (closeOnSelect) {
      event.stopPropagation();
      handleOpen(false)
      handleNext(event)
    }
  };

  const setProperty_ = (property, event) => {
    setProperty(property);
    setSearchTerm(property.name);

    setPropertyIds([{id: property.id, name: property.name}])

    setSuggestion({
      name: property.name,
      properties: [{id: property.id, name: property.name}]
    })

    if (closeOnSelect) {
      event.stopPropagation();
      handleOpen(false)
      handleNext(event)
    }

  };

  const handleOpen = React.useCallback((open) => {
    setOpen(open);
    dispatch(setPickingStay({pickingStay: open}))
  }, [setOpen, dispatch]);

  const handleNext = (event) => {
    event.stopPropagation()
    handleOpen(false)
    if (showNext) {
      showNext()
    }
  }

  const {refs, floatingStyles, context} = useFloating({
    open,
    onOpenChange: handleOpen,
    placement: "bottom-start",
    middleware: [offset(15), flip(), shift({
      crossAxis: true
    }),
      size({
        apply({availableHeight}) {
          flushSync(() => setMaxHeight(availableHeight));
        },
      })],
    whileElementsMounted: autoUpdate,
  });

  useInteractions([
    useDismiss(context, {
      outsidePointerDown: true,
    }),
  ]);

  return (
    <div className={classNames('bn-hotel-suggestion', 'bn-search-widget-item',
      {'bn-selected': open})}
         onClick={() => handleOpen(true)}
         ref={refs.setReference}
    >
      <strong className={"label"}>Where</strong>
      <input value={searchTerm}
             onChange={event => handleChange(event)}
             onKeyDown={event => handleKeyDown(event)}
             type={"text"}
             placeholder={getDisplayValue(area, location, property)}/>
      <FloatingPortal id={portalId}>
        {(!busy && open) &&
          (<FloatingFocusManager context={context} order={['reference', 'content']}>
            <div ref={refs.setFloating}
                 style={{...floatingStyles, maxHeight}}
                 className={classNames('bn-hotel-suggestion-container',
                   'bn-searchwidget-portal-container')}>
              <div className={"bn-suggestions-back"}>
                <FontAwesomeIcon
                  className={classNames({
                    'bn-hidden': ((!country && site.showCountrySuggestion)
                      || (!area && !site.showCountrySuggestion))
                  })}
                  onClick={() => handleReset()}
                  icon="fa-solid fa-circle-arrow-left"/>
              </div>

              {(!searchTerm && site.showCountrySuggestion)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Country</h3>
                  {
                    localSuggestions.map((a, idx) => {
                      return (
                        SuggestionLabel(idx, a, country, setCountry_)
                      )
                    })
                  }
                </div>}
              {(searchTerm && site.showCountrySuggestion)
                && localSuggestions.find(
                  sug => sug.searchTermFound === true)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Country</h3>
                  {
                    localSuggestions.filter(sug => (sug.searchTermFound)).map(
                      (a, idx) => {
                        return (
                          SuggestionLabel(idx, a, country, setCountry_)
                        )
                      })
                  }
                </div>}

              {(country && site.showCountrySuggestion)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Area</h3>
                  {
                    (country.areas || []).map((a, idx) => {
                      return (
                        SuggestionLabel(idx, a, area, setArea_)
                      )
                    })
                  }
                </div>}

              {(!searchTerm && !site.showCountrySuggestion)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Area</h3>
                  {
                    localSuggestions.map(country => {
                      return country.areas.map(
                        (a, idx) => {
                          return (
                            SuggestionLabel(idx, a, area, setArea_)
                          )
                        })
                    })
                  }
                </div>}

              {(searchTerm && (!country || !site.showCountrySuggestion))
                && areaHasSearchTermFound(localSuggestions)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Area</h3>
                  {
                    localSuggestions.map(country => {
                      return country.areas.filter(
                        sug => (sug.searchTermFound)).map(
                        (a, idx) => {
                          return (
                            SuggestionLabel(idx, a, area, setArea_)
                          )
                        })
                    })
                  }
                </div>}

              {(area) && <div className={"bn-suggestions"} style={{maxHeight}}>
                <h3>Location</h3>
                {
                  (area?.locations || []).map((l, idx) => {
                    return (
                      SuggestionLabel(idx, l, location, setLocation_)
                    )
                  })
                }
              </div>}
              {(searchTerm && !area)
                && locationHasSearchTermFound(localSuggestions)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Location</h3>
                  {
                    localSuggestions.map(country => {
                      return country.areas.map(area => {
                        return area.locations.filter(
                          sug => (sug.searchTermFound)).map((l, idx) => {
                          return (
                            SuggestionLabel(idx, l, location, setLocation_)
                          )
                        })
                      })
                    })
                  }
                </div>}
              {location && <div className={"bn-suggestions"}
                                style={{maxHeight}}>
                <h3>Hotel</h3>
                {
                  (location?.properties || []).map((p, idx) => {
                    return (
                      SuggestionLabel(idx, p, property, setProperty_)
                    )
                  })
                }
              </div>}
              {searchTerm && !location && propertyHasSearchTermFound(
                  localSuggestions)
                && <div className={"bn-suggestions"} style={{maxHeight}}>
                  <h3>Hotel</h3>
                  {
                    localSuggestions.map(country => {
                      return country.areas.map(area => {
                        return area.locations.map(location => {
                          return location.properties.filter(
                            sug => (sug.searchTermFound)).map((p, idx) => {
                            return (
                              SuggestionLabel(idx, p, property, setProperty_)
                            )
                          })
                        })
                      })
                    })
                  }
                </div>}
              <div className={'bn-button-container'}>
                <button
                  className={'bn-booknow-button'}
                  onClick={event => handleNext(event)}>Pick Dates
                </button>
              </div>
            </div>
          </FloatingFocusManager>)}
      </FloatingPortal>
    </div>
  )
}