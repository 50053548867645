import {useRef, useState} from "react";
import useFileUpload from "../hooks/useFileUpload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Spinner} from "./Spinner";

const validFileTypes = ['application/pdf'];
export default function FileUpload({path, onSuccess, disabled}) {

  const {upload, busy, error: uploadError} = useFileUpload();

  const [error, setError] = useState('')

  const inputRef = useRef();

  const handleUpload = async event => {
    setError('');

    const file = event.target.files[0];

    if (!validFileTypes.find(type => type === file.type)) {
      setError('File must be in PDF format')
      return;
    }

    upload(file, `${path}/${file.name}`, onSuccess)
  }

  const handleClick = () => {
    inputRef.current.click();
    inputRef.current.value = null;
  }

  return (
    <div className={'bn-file-upload'}>
      <input style={{display: 'none'}} ref={inputRef}
             type={"file"}
             onChange={handleUpload}
             accept="application/pdf"
      />
      {!busy &&
        <button
          className={'bn-booknow-button'}
          disabled={disabled}
          onClick={handleClick}>
          <FontAwesomeIcon icon="fa-solid fa-cloud-arrow-up"/>
          Upload file
        </button>}
      {busy && <div style={{margin: '0 50px'}}><Spinner size={"32px"}/></div>}
      {error && !busy && <div className={'bn-error'}>{error}</div>}
    </div>
  )
}