import React, {
  useCallback,
  useEffect,
  useState
} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GuestLookup from "../session/components/GuestLookup";
import {useSelector} from "react-redux";
import {selectApp} from "../app/appSlice";
import classNames from "classnames";
import SelectLookup from "../../components/SelectLookup";
import TextInputItem from "../../components/TextInputItem";
import {
  convertRestProfile,
  convertToRestProfile
} from "../session/reservationUtil";
import useRestUpdate, {ADD, UPDATE} from "../../hooks/useRestUpdate";
import {validEmail} from "../Util";
import {Spinner} from "../../components/Spinner";
import {trackPageView} from "../../api/googleTag";
import Checkbox from "../../components/Checkbox";

export default function GuestProfilePage() {

  const {site, agent} = useSelector(state => ({
    site: selectApp(state).site,
    agent: selectApp(state).agent,
  }));

  const [openGuestLookup, setOpenGuestLookup] = useState(false);
  const [showProfileEdit, setShowProfileEdit] = useState(false);
  const [loyaltyNo, setLoyaltyNo] = useState('');
  const [titleId, setTitleId] = useState(0);
  const [firstname, setFirstname] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [cellNo, setCellNo] = useState('');
  const [idNo, setIdNo] = useState('');

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [countryId, setCountryId] = useState(0);
  const [show, setShow] = useState(true)
  const [existingProfile, setExistingProfile] = useState(undefined);
  const [validateFields, setValidateFields] = useState({})

  useEffect(() => {

    setLoyaltyNo(existingProfile?.loyaltyNo)
    setTitleId(existingProfile?.titleId)
    setFirstname(existingProfile?.firstname || '');
    setSurname(existingProfile?.surname || '');
    setCellNo(existingProfile?.cellNo || '');
    setEmail(existingProfile?.email || '');
    setIdNo(existingProfile?.idNo || '')

    setAddress1(existingProfile?.address1 || '')
    setAddress2(existingProfile?.address2 || '')
    setPostalCode(existingProfile?.postalCode || '')
    setCity(existingProfile?.city || '')
    setCountryId(existingProfile?.countryId)
    setShow(existingProfile?.show || true)

  }, [existingProfile]);

  useEffect(() => {
    trackPageView('/agentprofile', site)
  }, [site]);

  const {restUpdate, busy, error} = useRestUpdate();

  const validate = useCallback(() => {

    const validateFields = {
      titleId: (titleId) => {
        return !titleId ? 'Required' : undefined;
      },
      firstname: (firstname) => {
        return !firstname ? 'Required' : undefined
      },
      surname: (surname) => {
        return !surname ? 'Required' : undefined
      },
      cellNo: (cellNo) => {
        return (!cellNo && site.cellNoMandatory) ? 'Required' : undefined
      },
      email: (email) => {
        return !validEmail(email) ? 'Valid e-mail required.' : undefined;
      },
      idNo: (idNo) => {
        return (!idNo && site.idNoMandatory) ? 'Required' : undefined;
      },
      address1: (address1) => {
        return (!address1 && site.addressMandatory) ? 'Required' : undefined;
      },
      address2: (address2) => {
        return (!address2 && site.addressMandatory) ? 'Required' : undefined;
      },
      city: (city) => {
        return (!city && site.addressMandatory) ? 'Required' : undefined;
      },
      postalCode: (postalCode) => {
        return (!postalCode && site.addressMandatory) ? 'Required'
          : undefined;
      },
      countryId: (countryId) => {
        return (!countryId && site.countryMandatory) ? 'Required' : undefined;
      },
    }

    setValidateFields(validateFields);

    const titleValid = validateFields['titleId']
    const firstNameValid = validateFields['firstname'];
    const surnameValid = validateFields['surname'];
    const cellNoValid = validateFields['cellNo'];
    const emailValid = validateFields['email'];
    const idNoValid = validateFields['idNo'];
    const address1Valid = validateFields['address1'];
    const address2Valid = validateFields['address2'];
    const cityValid = validateFields['city'];
    const postalCodeValid = validateFields['postalCode'];
    const countryIdValid = validateFields['countryId'];

    return !titleValid(titleId)
      && !firstNameValid(firstname)
      && !surnameValid(surname)
      && !cellNoValid(cellNo)
      && !emailValid(email)
      && !idNoValid(idNo)
      && !address1Valid(address1)
      && !address2Valid(address2)
      && !cityValid(city)
      && !postalCodeValid(postalCode)
      && !countryIdValid(countryId)
  }, [site,
    loyaltyNo,
    titleId,
    firstname,
    surname,
    cellNo,
    email,
    idNo,
    address1,
    address2,
    postalCode,
    city,
    countryId,
    agent,
    show,
  ]);

  const saveLabel = existingProfile ? 'Update' : "Save New";

  const handleSelectExistingProfile = (profile) => {
    setExistingProfile(Object.assign({}, convertRestProfile(profile), {id: profile.ID}));
    setShowProfileEdit(true)
  }

  const handleUpdate = useCallback(() => {

    if(validate()) {
      const profile = {
        id: existingProfile?.id,
        loyaltyNo,
        titleId,
        firstname,
        surname,
        cellNo,
        email,
        idNo,
        address1,
        address2,
        postalCode,
        city,
        countryId,
        agentId: agent.agentId,
        show: show,
      }

      const operationType = profile.id ? UPDATE : ADD;

      restUpdate(
        Object.assign({}, convertToRestProfile(profile), {ID: profile.id}),
        "Guest", operationType,
        undefined).then(() => {
        handleCancel()
      })
    }

  }, [
    existingProfile,
    loyaltyNo,
    titleId,
    firstname,
    surname,
    cellNo,
    email,
    idNo,
    address1,
    address2,
    postalCode,
    city,
    countryId,
    agent,
    show,
    site
  ]);

  const handleCancel = () => {
    setShowProfileEdit(false)
    setExistingProfile(undefined)
    setValidateFields({}) // reset
  }

  return (
    <div className={classNames("bn-update-guest-profile-container",
      {'bn-show-profile-edit': showProfileEdit})}>
      <h1>Guest Profile</h1>

      {!showProfileEdit && <div className={"bn-update-guest-action-container"}>
        <p>
          Welcome <strong>{agent.name}</strong>. Here you can
        </p>

        <button className={"bn-booknow-button"}
                type={"button"}
                name={"booknow-button"}
                onClick={() => setShowProfileEdit(true)}
                disabled={false}
        >
          Create
        </button>

        <p> or </p>

        <button className={"bn-booknow-button"}
                type={"button"}
                name={"booknow-button"}
                onClick={() => setOpenGuestLookup(true)}
                disabled={false}
        >
          <FontAwesomeIcon icon="fa-solid fa-magnifying-glass"/>
          Update
        </button>

        <p> your guest profiles.</p>

      </div>}

      {(showProfileEdit||true) &&
        <>
          <div className={classNames("bn-inputs-group")}>
            <div className={"bn-inputs-left"}>

              <SelectLookup className={"bn-group bn-select-item"}
                            id={"title"}
                            label={"Title"}
                            name={"Title"}
                            value={titleId}
                            mandatory={true}
                            placeholder={"Title"}
                            onChange={(value) => setTitleId(value.ID)}
                            defaultToFirstOption={true}
                            isClearable={false}
                            validate={validateFields['titleId']}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"firstname"}
                             label={"Firstname"}
                             placeholder={"Firstname"}
                             mandatory={true}
                             maxLength={50}
                             value={firstname}
                             onChange={(event) => setFirstname(
                               event.target.value)}
                             validate={validateFields['firstname']}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"surname"}
                             label={"Surname"}
                             value={surname}
                             placeholder={"Surname"}
                             mandatory={true}
                             maxLength={50}
                             onChange={(event) => setSurname(
                               event.target.value)}
                             validate={validateFields['surname']}
              />

            </div>
            <div className={"bn-inputs-right"}>

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"mobile"}
                             label={"Mobile"}
                             placeholder={"Mobile"}
                             mandatory={site?.cellNoMandatory ?? false}
                             maxLength={20}
                             value={cellNo}
                             onChange={(event) => setCellNo(
                               event.target.value)}
                             validate={validateFields['cellNo']}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"email"}
                             label={"E-mail"}
                             value={email}
                             placeholder={"E-mail"}
                             mandatory={true}
                             maxLength={100}
                             onChange={(event) => setEmail(
                               event.target.value)}
                             validate={validateFields['email']}
              />

              {(!site?.hideIdNo) && <TextInputItem
                className={"bn-group bn-input-item"}
                id={"idNo"}
                label={"ID Number"}
                placeholder={"ID Number or Passport"}
                mandatory={site?.idNoMandatory ?? false}
                maxLength={25}
                value={idNo}
                onChange={(event) => setIdNo(
                  event.target.value)}
                validate={validateFields['idNo']}
              />}

            </div>
          </div>

          <h2>Address Details</h2>

          <div className={"bn-inputs-group"}>
            <div className={"bn-inputs-left"}>
              <TextInputItem className={"bn-group bn-input-item"}
                             id={"address"}
                             label={"Address"}
                             value={address1}
                             placeholder={"Address"}
                             mandatory={site?.addressMandatory ?? false}
                             maxLength={50}
                             onChange={(event) => setAddress1(
                               event.target.value)}
                             validate={validateFields['address1']}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"address2"}
                             label={"Address"}
                             value={address2}
                             placeholder={"Address"}
                             mandatory={site?.addressMandatory ?? false}
                             maxLength={50}
                             onChange={(event) => setAddress2(
                               event.target.value)}
                             validate={validateFields['address2']}
              />

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"city"}
                             label={"City"}
                             value={city}
                             placeholder={"City"}
                             mandatory={site?.addressMandatory ?? false}
                             maxLength={50}
                             onChange={(event) => setCity(
                               event.target.value)}
                             validate={validateFields['city']}
              />

            </div>
            <div className={"bn-inputs-right"}>

              <TextInputItem className={"bn-group bn-input-item"}
                             id={"postalCode"}
                             label={"Postal Code"}
                             value={postalCode}
                             placeholder={"Postal Code"}
                             mandatory={site?.addressMandatory ?? false}
                             maxLength={10}
                             onChange={(event) => setPostalCode(
                               event.target.value)}
                             validate={validateFields['postalCode']}
              />

              <SelectLookup className={"bn-group bn-select-item"}
                            id={"country"}
                            label={"Country"}
                            name={"Country"}
                            value={countryId}
                            mandatory={site?.countryMandatory}
                            placeholder={"Country"}
                            onChange={(value) => setCountryId(value?.ID)}
                            defaultToFirstOption={true}
                            isClearable={!site?.countryMandatory}
                            validate={validateFields['countryId']}
              />

              <Checkbox id={'showProfile'}
                        value={show}
                        onChange={(event) => setShow(
                          event.target.checked)}
                        className={'bn-checkbox-item'}
                        label={"Show"}
              />

            </div>
          </div>

          {error && <div className={'bn-error-message'}>Error. Please try again later or contact support.</div>}

          {busy && <Spinner size={"24px"}/>}
          {!busy && <div style={{height: 24}} />}

          <div className={"bn-button-container"}>
            <button className={"bn-booknow-button"}
                    onClick={handleUpdate}
                    disabled={busy}>
              {saveLabel}
            </button>

            <button className={"bn-booknow-button"}
                    disabled={busy}
                    onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </>
      }

      {agent && <GuestLookup open={openGuestLookup}
                             onOpenChange={setOpenGuestLookup}
                             onProfileSelect={handleSelectExistingProfile}
                             agentId={agent.agentId}
                             useProfileButtonLabel={'Select Profile'}
      />}

    </div>
  )
}