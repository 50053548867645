import {useState} from "react";
import {
  buildDataSourcePayload,
  client,
  getApiUrl,
  NEBULA_REST
} from "../api/client";
import {useSelector} from "react-redux";
import {selectApp} from "../booknow/app/appSlice";

export const UPDATE = "update";
export const ADD = "add";

const useRestUpdate = () => {

  const {customer, token} = useSelector(state => ({
    customer: selectApp(state).customer,
    token: selectApp(state).token,
  }));


  const [state, setState] = useState({
    busy: false,
  })

  const restUpdate = async (data, dataSource, operationType, operationId) => {

    setState({
      busy: true,
      error: undefined,
    })

    try {

      const response = await client.post(getApiUrl(customer, NEBULA_REST),
        buildDataSourcePayload(data, dataSource, operationType, operationId),
        token, undefined, false)

      setState({
        busy: false,
        response: response.data
      })

    } catch (e) {
      setState({
        busy: false,
        error: e,
      })
      console.log('Rest Update error: ', e)
    }
  }

  return {
    restUpdate,
    ...state
  }
}

export default useRestUpdate;