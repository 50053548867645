import useReservationSearch from "../../hooks/useReservationSearch";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectApp} from "../app/appSlice";
import {format, addYears} from 'date-fns'
import {Spinner} from "../../components/Spinner";
import ResponsiveTable from "../../components/ResponsiveTable";
import {useNavigate} from "react-router";
import ScrollableTabs from "../../components/ScrollableTabs";
import * as React from "react";
import {parseDate} from "../session/reservationUtil";

const DATE_FORMAT = "yyyy-MM-dd";

export default function BookingsList({contactId}) {

  const {customer, token} = useSelector(state => ({
    customer: selectApp(state).customer,
    token: selectApp(state).token,
  }));

  const [tabIndex, setTabIndex] = useState(0)

  const navigate = useNavigate();
  const {search, reservations, busy, error} = useReservationSearch();

  useEffect(() => {
    if (customer) {

      if(tabIndex === 0) { //active
        searchActive();
      }
      else {
        searchPast();
      }

    }
  }, [customer, contactId]);

  const columns = [
    {
      id: 'ReservationNo',
      label: 'Reservation#',
    },

    {
      id: 'ReservationName',
      label: 'Name',
    },

    {
      id: 'ArrivalDate',
      label: 'Arrival Date',
      renderValue: (value) => {
        return parseDate(value, DATE_FORMAT).toLocaleDateString()
      }
    },

    {
      id: 'Status',
      label: 'Status',
      renderValue: (value) => {
        switch (value) {
          case 'G' : return 'Guaranteed';
          case 'P': return 'Provisional';
          case 'W': return 'Quote';
          case 'H': return 'Hold';
        }
      },
    },

    {
      id: 'ContactName',
      label: 'Contact',
    },
  ]

  const actions = [
    {
      Component: 'button',
      className: 'bn-booknow-button',
      onClick: (row) => {
        const {ReservationNo: reservationNo, Email: email } = row;
        navigate(`/confirmation/${reservationNo}/${email}`)
      },
      children: 'Open'
    },
  ]

  const tabs = [
    {
      id: 'activeBookings',
      label: 'Active',
    },
    {
      id: 'pastBookings',
      label: 'Past',
    }
  ]

  const searchActive = () => {
    if(contactId) {
      const criteria = {
        DateType: "arrival",
        StartDate: format(new Date(), DATE_FORMAT),
        EndDate: format(addYears(new Date(), 2), DATE_FORMAT),
        ContactID: contactId,
      }

      search(criteria, customer, token)
    }
  }

  const searchPast = () => {
    if (contactId) {
      const criteria = {
        DateType: "arrival",
        StartDate: format(addYears(new Date(), -2), DATE_FORMAT),
        EndDate: format(new Date(), DATE_FORMAT),
        ContactID: contactId,
      }

      search(criteria, customer, token)
    }
  }

  const handleTabSelect = (tabIndex) => {
    setTabIndex(tabIndex)

    if(tabIndex === 0) { //active
      searchActive();
    }
    else {
      searchPast();
    }
  }

  return (
    <div className={"bn-agent-bookings-list"}>
      <ScrollableTabs tabs={tabs}
                      onTabSelected={(index) => handleTabSelect(index)}
                      selectedIndex={tabIndex}/>

      {(busy && reservations.length === 0) &&
        <div className={"bn-bookings-list-spinner-container"}>
          <Spinner size={45}/>
        </div>
      }

      {(!busy || reservations.length > 0) &&
          <ResponsiveTable columns={columns}
                           data={reservations}
                           actions={actions}
                           emptyListMessage={"No bookings to display."}
          />
      }

      {error && <div className={"bn-error-message"}>{error}</div>}
    </div>
  )
}