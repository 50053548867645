import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  doCancel,
  releaseTemphold,
  selectSession,
  updateReservation
} from "../sessionSlice";
import {format, isToday, parse} from 'date-fns';
import {
  formatAmount,
  formatDateStr,
  scrollToContinue, usePrevious
} from "../../Util";
import {
  calcDepositSummary,
  calcTotals, dateDiff, getArrivingItem, getDepartDate, getItinerary,
  getNoOfAdults,
  getNoOfChildren, hasDepositDue, isOnAccount, removeReservation,
} from "../reservationUtil";
import {useNavigate} from "react-router-dom";
import {selectResults} from "../../results/resultsSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ToolTip from "../../../components/ToolTip";
import Dialog from "../../../components/Dialog";
import {selectApp} from "../../app/appSlice";
import classNames from "classnames";
import {trackEvent} from "../../../api/googleTag";
import {RoomBreakdown} from "./RoomBreakdown";
import {ItineraryDateRangePicker} from "./ItineraryDateRangePicker";
import {ItineraryRoomTypeEdit} from "./ItineraryRoomTypeEdit";
import _ from "lodash";

export const ItineraryActions = {
  PAY: 'PAY',
  GUESTDETAIL: 'GUESTDETAIL'
};

const ItineryItem = forwardRef(({
  reservation,
  item,
  index,
  showCancel,
  singleProperty,
  isCriteriaItem = false
}, ref) => {

  const {property} = item;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();
  const doCancel_ = React.useCallback((params) => dispatch(doCancel(params)),
    [dispatch]);
  const updateReservation_ = useCallback((reservation) =>
    dispatch(updateReservation({reservation})), [dispatch]);
  const releaseTemphold_ = useCallback(
    (params) => dispatch(releaseTemphold(params)), [dispatch]);

  const handleClickRemove = () => {
    setOpen(true);
  };

  const handleNewReservation = () => {
    removeReservation().then(() => {
      releaseTemphold_({sessionId: reservation.sessionId});
      updateReservation_(undefined);

      navigate(!singleProperty ? '/' : `/hotel/${property.id}`)
    });
  };

  const handleCancel = (reservation, index) => {
    trackEvent("remove_from_cart", "clicked", "remove_from_itinerary");

    if (getItinerary(reservation).length > 1) {
      doCancel_({reservation, indexToCancel: index})
    } else {
      trackEvent("engagement", "clicked", "start_over");

      handleNewReservation()
    }

    setOpen(false);
  };

  const noOfRooms = item?.roomBreakdown ? item.roomBreakdown.length : 0;
  const noOfAdults = item?.roomBreakdown ? getNoOfAdults(item.roomBreakdown)
    : 0;
  const noOfChildren = item?.roomBreakdown ? getNoOfChildren(item.roomBreakdown)
    : 0;

  const duration = dateDiff(item?.startDate, item?.endDate) ?? 0

  return (
    <div ref={ref} className={"bn-itinerary-item"}>
      {item?.propertyName && <div className={"bn-itinerary-remove-container"}>
        <div className={"bn-itinerary-property-container"}>
          <h3>{item?.propertyName}</h3>
        </div>
        {!showCancel &&
          <ToolTip content={"Remove"}>
            <FontAwesomeIcon
              className={"bn-svg-button"}
              onClick={handleClickRemove}
              icon="fa-solid fa-rectangle-xmark"/>
          </ToolTip>
        }
      </div>}

      <span>{`${duration} ${duration > 1 ? 'Nights' : 'Night'}`}</span>

      <ItineraryDateRangePicker item={item} isCriteriaItem={isCriteriaItem}/>

      <ItineraryRoomTypeEdit item={item} isCriteriaItem={isCriteriaItem}/>

      {item.roomBreakdown && <div className={"bn-itinerary-pax-container"}>
        <div className={"bn-itinerary-roombreakdown-container"}>
          {!isCriteriaItem && <span>{`${noOfRooms} ${noOfRooms > 1 ? 'Rooms'
            : 'Room'}`}</span>}
          <span>{`${noOfAdults} ${noOfAdults > 1 ? 'Adults' : 'Adult'}`}</span>
          {noOfChildren > 0 && <span>{`${noOfChildren} ${noOfChildren > 1
            ? 'Children' : 'Child'}`}</span>}
        </div>

        {!isCriteriaItem && <RoomBreakdown item={item}/>}
      </div>}

      <div className={"bn-itinerary-amount-container"}>
        {item?.amountInclTax
          && <h4>{formatAmount(item.amountInclTax, item.currencyCode)}</h4>}
      </div>

      {index < (reservation?.itinerary.length - 1) && <div
        className={"bn-itinerary-item-separator"}/>}
      <Dialog open={open} onOpenChange={setOpen}>
        <>
          <div className={"bn-dialog-content"}>
            <p>Are you sure you want to remove the itinerary?</p>
          </div>
          <div className={"bn-dialog-button-container"}>
            <button className={'bn-booknow-button'}
                    onClick={() => handleCancel(reservation, index)}>Yes
            </button>
            <button className={'bn-booknow-button'}
                    onClick={() => setOpen(false)}>No
            </button>
          </div>
        </>
      </Dialog>
    </div>
  )
});

const ItineryMobileStay = ({
  reservation,
  criteria,
  totals,
}) => {

  const departDate = getDepartDate(reservation);

  const startDate = getArrivingItem(getItinerary(reservation))?.startDate
    ?? criteria.startDate;
  const endDate = (departDate ? format(departDate, 'yyy-MM-dd') : undefined)
    ?? criteria.endDate;

  return (<div className={'bn-itinerary-mobile-stay'}>
    {Object.keys(totals.taxInclusive || {}).length > 0 && <div
      className={"bn-itinerary-stay-total"}>
      {Object.keys(totals.taxInclusive || {}).map(
        (currencyCode, i) => (
          <h3 key={i}>{formatAmount(
            totals.taxInclusive[currencyCode].grandTotal,
            currencyCode)}</h3>))}
    </div>
    }
    <div className={'bn-itinerary-stay-range'}>
      {`${formatDateStr(startDate, 'yyyy-MM-dd', 'dd MMM')} - ${formatDateStr(
        endDate,
        'yyyy-MM-dd', 'dd MMM')}`}
    </div>
  </div>)
}

export const ItineraryMobile = ({
  reservation,
  totals,
  criteria,
  action,
  depositSummary,
  handleContinue,
  singleProperty,
}) => {

  const [expand, setExpand] = useState(false)

  const contentRef = useRef(undefined)
  const itemRef = useRef(undefined)

  const criteriaItem = {
    startDate: criteria?.startDate,
    endDate: criteria?.endDate,
    roomBreakdown: [criteria?.pax]
  };

  useEffect(() => {
    handleExpand(expand, getItinerary(reservation).length)
  }, [reservation]);

  const handleExpand = React.useCallback((expand, length) => {
    if (contentRef && (itemRef && itemRef.current)) {
      const height = _.min([((itemRef.current.scrollHeight * length) + 25),
        (window.innerHeight - 80)]);

      contentRef.current.style.height = (expand
        ? height + "px" : 0);
    }

    setExpand(expand)
  }, [expand, setExpand]);

  return (
    <div className={'bn-itinerary-mobile'}>

      <div ref={contentRef}
           className={classNames('bn-itinerary-mobile-detail', {
             'expand': expand
           })}>
        {reservation.itinerary?.length === 0 && <ItineryItem
          reservation={reservation}
          item={criteriaItem}
          showCancel={false}
          isCriteriaItem={reservation.itinerary?.length === 0}
        />}

        {reservation.itinerary?.map((item, idx) => {
          return (<ItineryItem ref={idx === 0 ? itemRef : undefined}
                               reservation={reservation}
                               key={idx}
                               item={item}
                               index={idx}
                               singleProperty={singleProperty}
          />)
        })}
      </div>

      <div className={'bn-itinerary-mobile-summary'}>

        <div className={'bn-itinerary-mobile-summary-expand'}>
          <div className={classNames('bn-itinerary-mobile-expand-up',
            {'expand': expand})}
               onClick={() => handleExpand(!expand,
                 getItinerary(reservation).length)}>
            <FontAwesomeIcon icon="fa-solid fa-angle-up"/>
          </div>

          <ItineryMobileStay
            reservation={reservation}
            totals={totals}
            criteria={criteria}
            showCancel={false}/>
        </div>

        {hasDepositDue(depositSummary) && <div
          className={'bn-desposit-container'}>
          <h3>Deposit Required</h3>
          {Object.keys(depositSummary).map((currencyCode, i) => (Object.keys(
            depositSummary[currencyCode].depositsPayable).map(
            (depositDueDate, d) => (<div
              className={'deposit-item'}
              key={`${i}-${currencyCode}-${d}`}>
              {formatAmount(
                depositSummary[currencyCode].depositsPayable[depositDueDate].amount,
                currencyCode)}
              {isToday(parse(depositDueDate, 'yyyy-MM-dd', new Date()))
                ? ' payable immediately' : ' due by ' + parse(depositDueDate,
                'yyyy-MM-dd', new Date()).toLocaleDateString()}
            </div>))))}
        </div>}

        {reservation.itinerary[0]?.propertyId && action
          === ItineraryActions.GUESTDETAIL && <div
            className={"bn-itinerary-continue-button"}>
            <button
              className={'bn-booknow-button'}
              onClick={handleContinue}>Continue
            </button>
          </div>
        }

      </div>
    </div>
  )
}

export default React.memo(function ({action}) {

  const navigate = useNavigate();

  const {
    reservation,
    criteria,
    agent,
    singleProperty
  } = useSelector(
    state => ({
      reservation: selectSession(state).reservation,
      criteria: selectResults(state).criteria,
      agent: selectApp(state).agent,
      singleProperty: selectApp(state).singleProperty,
    }));

  const [totals, setTotals] = useState({});
  const [depositSummary, setDepositSummary] = useState({});
  const [expand, setExpand] = useState(false)

  const itemContainerRef = useRef(undefined)
  const itemRef = useRef(undefined)

  useEffect(() => {
    if (reservation) {
      setTotals(calcTotals(reservation));
      setDepositSummary(calcDepositSummary(reservation))
    }
  }, [reservation]);

  const prevItinerary = usePrevious({itinerary: reservation?.itinerary})

  useEffect(() => {

    if(prevItinerary?.itinerary.length !== reservation?.itinerary.length) {
      handleItineraryExpand(reservation.itinerary?.length === 1,
        reservation.itinerary?.length)
    }

  }, [reservation?.itinerary]);

  const handleContinue = () => {

    trackEvent("engagement", "clicked", "continue_to_guestdetail");

    navigate('/guestdetails')
  };

  const criteriaItem = {
    startDate: criteria?.startDate,
    endDate: criteria?.endDate,
    roomBreakdown: [criteria?.pax]
  };

  const getGrandTotal = useCallback((totals, currencyCode) => {
    return totals.taxInclusive[currencyCode].grandTotal
      + totals.taxExclusive[currencyCode].grandTotal //totelNettTaxes
  }, [totals])

  const handleItineraryExpand = (expand, length) => {

    if (itemContainerRef && (itemRef && itemRef.current)) {
      itemContainerRef.current.style.height = (expand
        ? ((itemRef.current.scrollHeight * length) + (12 * _.max(
        [0, (length - 2)]))) + "px" : 0);
    }

    setExpand(expand)
  };

  return (
    <>
      {reservation && <div className={"bn-itinerary"}>
        <div className={"bn-itinerary-heading-container"}>
          <div className={"bn-itinerary-heading"}>
            <h2>My Itinerary</h2>
            {reservation.itinerary?.length > 1 &&
              <div className={"bn-itinerary-count"}>
                {reservation.itinerary?.length}
              </div>
            }
          </div>

          {reservation.itinerary?.length > 1 &&
            <div onClick={() => handleItineraryExpand(!expand,
              reservation.itinerary?.length)}
                 className={classNames("bn-itinerary-more-details",
                   {'expand': expand})}>
              <span>{!expand ? 'More Details' : 'Less Details'}</span>
              <FontAwesomeIcon icon="fa-solid fa-angle-down"/>
            </div>
          }
        </div>

        {reservation.itinerary?.length === 0 && <ItineryItem
          reservation={reservation}
          item={criteriaItem}
          isCriteriaItem={reservation.itinerary?.length === 0}
        />
        }

        <div ref={itemContainerRef}
             className={classNames("bn-itinerary-item-container", {
               'expand': expand,
             })}>
          {reservation.itinerary?.map((item, idx) => {
            return (<ItineryItem ref={idx === 0 ? itemRef : undefined}
                                 reservation={reservation}
                                 key={idx}
                                 item={item}
                                 index={idx}
                                 singleProperty={singleProperty}
            />)
          })}
        </div>

        {Object.keys(totals.taxInclusive || {}).length > 0 && <div
          className={"bn-itinerary-total-container"}>
          <h3>Total</h3>
          {Object.keys(totals.taxInclusive || {}).map(
            (currencyCode, i) => (
              <h3 key={i}>{formatAmount(getGrandTotal(totals, currencyCode),
                currencyCode)}</h3>))
          }
        </div>
        }

        {(hasDepositDue(depositSummary) && !isOnAccount(agent)) && <div
          className={'bn-desposit-container'}>
          <h3>Deposit Required</h3>
          {Object.keys(depositSummary).map((currencyCode, i) => (Object.keys(
            depositSummary[currencyCode].depositsPayable).map(
            (depositDueDate, d) => (<div
              className={'deposit-item'}
              key={`${i}-${currencyCode}-${d}`}>
              {formatAmount(
                depositSummary[currencyCode].depositsPayable[depositDueDate].amount,
                currencyCode)}
              {isToday(parse(depositDueDate, 'yyyy-MM-dd', new Date()))
                ? ' payable immediately' : ' due by ' + parse(depositDueDate,
                'yyyy-MM-dd', new Date()).toLocaleDateString()}
            </div>))))}
        </div>}

        {reservation.itinerary[0]?.propertyId && action
          === ItineraryActions.GUESTDETAIL && <div
            className={"bn-itinerary-continue-button"}>
            <button
              className={'bn-booknow-button'}
              onClick={handleContinue}>Continue
            </button>
          </div>
        }

      </div>}

      {reservation && <ItineraryMobile reservation={reservation}
                                       totals={totals}
                                       criteria={criteria}
                                       action={action}
                                       depositSummary={depositSummary}
                                       handleContinue={handleContinue}
                                       singleProperty={singleProperty}
      />}

    </>
  )
});
